import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { LineSVGData } from '../../../types/PDFObjects';
import { LineCaps } from '../../../enums/LineCaps';
import usePDFStore from '../../../stores/usePDFStore';
import useDraggableLine from '../../../hooks/pdf/useDraggableLine';
import { Line } from '../../../types/Shapes';

interface LineSVGDataProps {
  lineSVGData: LineSVGData;
}

const LineSVG = memo(({ lineSVGData }: LineSVGDataProps) => {
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const [hovered, setHovered] = useState(false);
  const [resizing, setResizing] = useState(false);
  const scale = usePDFStore(state => state.settings.scale);
  const [line, setLine] = useState<Line>(lineSVGData.line);
  const setIsDragging = usePDFStore(state => state.setIsDragging);
  const lineRef = useRef(line);

  useEffect(() => {
    setLine(lineSVGData.line);
    lineRef.current = lineSVGData.line;
  }, [lineSVGData.line]);

  useEffect(() => {
    lineRef.current = line;
  }, [line]);

  // Function to handle the start of resize (drag start) for either end of the line
  const handleResizeStart = (e: React.MouseEvent, end: string) => {
    e.stopPropagation(); // Prevent event bubbling
    setIsDragging(true);
    setCurrentSelections([lineSVGData.id]);
    setResizing(true);

    const originalX = end === 'start' ? line.x1 : line.x2;
    const originalY = end === 'start' ? line.y1 : line.y2;
    const startX = e.clientX / scale;
    const startY = e.clientY / scale;

    // Function to handle dragging (resizing)
    const handleMouseMove = (moveEvent: MouseEvent) => {
      moveEvent.preventDefault();
      const diffX = moveEvent.clientX / scale - startX;
      const diffY = moveEvent.clientY / scale - startY;

      // Calculate new position based on drag
      const newX = originalX + diffX;
      const newY = originalY + diffY;

      // Update line endpoint based on which end is being resized
      if (end === 'start') {
        setLine({ ...line, x1: newX, y1: newY });
      } else {
        // 'end'
        setLine({ ...line, x2: newX, y2: newY });
      }
    };

    // Cleanup function to remove event listeners after resizing
    const handleMouseUp = () => {
      setResizing(false);
      setIsDragging(false);
      updatePDFObject(lineSVGData.id, { line: lineRef.current });
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    // Add mouse move and mouse up event listeners to document for drag functionality
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const createPathDAttribute = () => {
    const x1 = line.x1;
    const y1 = line.y1;
    const x2 = line.x2;
    const y2 = line.y2;
    const padding = 10; // This controls how much wider the path is than the actual line
    // Calculate the angle perpendicular to the line
    const angle = Math.atan2(y2 - y1, x2 - x1) + Math.PI / 2;
    // Calculate offset points
    const offsetX = Math.cos(angle) * padding;
    const offsetY = Math.sin(angle) * padding;
    // Create points that outline the path
    const points = [
      { x: x1 - offsetX, y: y1 - offsetY },
      { x: x2 - offsetX, y: y2 - offsetY },
      { x: x2 + offsetX, y: y2 + offsetY },
      { x: x1 + offsetX, y: y1 + offsetY },
    ];
    // Construct the 'd' attribute for the path
    return `M ${points[0].x},${points[0].y} L ${points[1].x},${points[1].y} L ${points[2].x},${points[2].y} L ${points[3].x},${points[3].y} Z`;
  };

  const handleDragEnd = useCallback(
    (id: string, line: Line) => {
      updatePDFObject(id, { line });
    },
    [updatePDFObject]
  );

  const { dragging, startDrag } = useDraggableLine({
    line: line,
    updateLine: line => {
      setLine(line);
    },
    onDragEnd: line => {
      handleDragEnd(lineSVGData.id, line);
    },
  });

  const renderCapShapes = useCallback(
    (baseX: number, baseY: number, capType: string, where: string) => {
      const capSize = 10; // Adjust the size of the cap shapes if necessary

      // Calculate direction vector components and length
      const dx = line.x2 - line.x1;
      const dy = line.y2 - line.y1;
      const length = Math.sqrt(dx * dx + dy * dy);
      const Dx = dx / length; // Normalized direction vector component in X
      const Dy = dy / length; // Normalized direction vector component in Y

      // Adjust cap positions
      const capAdjustmentFactor = -5; // Adjust as needed for the desired cap distance
      const adjustedX = baseX + (where === 'start' ? 1 : -1) * Dx * capAdjustmentFactor;
      const adjustedY = baseY + (where === 'start' ? 1 : -1) * Dy * capAdjustmentFactor;

      const angle = Math.atan2(dy, dx) * (180 / Math.PI);

      switch (capType) {
        case LineCaps.SQUARE:
        case LineCaps.SQUARE_OUTLINE:
          // Include rotation around the cap's center
          const transform = `rotate(${angle}, ${adjustedX}, ${adjustedY})`;
          return (
            <rect
              x={adjustedX - capSize / 2}
              y={adjustedY - capSize / 2}
              width={capSize}
              height={capSize}
              fill={capType === LineCaps.SQUARE ? lineSVGData.stroke.color : 'none'}
              stroke={capType === LineCaps.SQUARE_OUTLINE ? lineSVGData.stroke.color : 'none'}
              strokeWidth={lineSVGData.stroke.width}
              transform={transform}
            />
          );
        case LineCaps.CIRCLE:
          return (
            <circle cx={adjustedX} cy={adjustedY} r={capSize / 2} fill={lineSVGData.stroke.color} />
          );
        case LineCaps.CIRCLE_OUTLINE:
          return (
            <circle
              cx={adjustedX}
              cy={adjustedY}
              r={capSize / 2}
              fill='none'
              stroke={lineSVGData.stroke.color}
              strokeWidth={lineSVGData.stroke.width}
            />
          );
        default:
          return null; // No cap shape
      }
    },
    [lineSVGData.stroke.color, lineSVGData.stroke.width, line.x1, line.y1, line.x2, line.y2]
  );

  return (
    <>
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'visible',
          zIndex: lineSVGData.zIndex,
        }}
      >
        <svg
          id={lineSVGData.id}
          xmlns='http://www.w3.org/2000/svg'
          style={{ overflow: 'visible', pointerEvents: 'none', position: 'absolute' }}
        >
          {lineSVGData.isSelected && !resizing && (
            <path
              d={createPathDAttribute()}
              fill='none'
              stroke='blue' // Outline color
              strokeWidth={2} // Increase stroke width for the outline effect
              style={{
                pointerEvents: 'auto',
              }}
            />
          )}
          <path
            d={createPathDAttribute()}
            fill='transparent'
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
            onMouseDown={e => {
              e.stopPropagation();
              setCurrentSelections([lineSVGData.id]);
              startDrag(e);
            }}
            stroke={hovered && !lineSVGData.isSelected ? 'blue' : 'transparent'}
            strokeWidth={2} // Increase stroke width for easier selection
            style={{
              cursor: dragging ? 'grabbing' : 'grab',
              pointerEvents: 'auto',
            }}
          />
          <>
            {renderCapShapes(line.x1, line.y1, lineSVGData.caps, 'start')}
            {renderCapShapes(line.x2, line.y2, lineSVGData.caps, 'end')}
          </>
          {lineSVGData.isSelected && !resizing && (
            <>
              <circle
                cx={line.x1}
                cy={line.y1}
                r='8'
                fill='#f0f0f0' // Very light fill color
                stroke='#b0b0b0' // Light stroke for a subtle border
                strokeOpacity='0.5'
                onMouseDown={e => handleResizeStart(e, 'start')}
                style={{ cursor: 'pointer', pointerEvents: 'auto' }}
              />
              <circle
                cx={line.x2}
                cy={line.y2}
                r='8'
                fill='#f0f0f0' // Very light fill color
                stroke='#b0b0b0' // Light stroke for a subtle border
                strokeOpacity='0.5'
                onMouseDown={e => handleResizeStart(e, 'end')}
                style={{ cursor: 'pointer', pointerEvents: 'auto' }}
              />
            </>
          )}
        </svg>
      </div>
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'clip',
          zIndex: lineSVGData.zIndex,
        }}
      >
        <div
          style={{
            position: 'absolute',
            overflow: 'visible',
            transform: `translate(0px, 0px)`,
            pointerEvents: 'none',
          }}
        >
          <svg
            id={lineSVGData.id}
            xmlns='http://www.w3.org/2000/svg'
            style={{ overflow: 'visible', pointerEvents: 'none', position: 'absolute' }}
          >
            <line
              x1={line.x1}
              y1={line.y1}
              x2={line.x2}
              y2={line.y2}
              stroke={lineSVGData.stroke.color}
              strokeWidth={lineSVGData.stroke.width}
            />
          </svg>
        </div>
      </div>
    </>
  );
});

LineSVG.displayName = 'LineSVG';

export default LineSVG;
