import { MdOutlineSearch } from 'react-icons/md';

import { useUploadFiles } from '../../../hooks/api/uploads/useUploadFiles';
import { useUserContext } from '../../../contexts/hooks/useUserContext';

import { useGetUploads } from '../../../hooks/api/uploads/useGetUploads';
import { memo, useEffect, useState } from 'react';
import { File } from '../../../api/uploads/types';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import Spinner from '../../ui/Spinner';
import { GoTrash, GoX } from 'react-icons/go';
import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';

interface CollectionGalleryProps {
  toggleCollectionGallery: () => void;
}

interface FileItem extends File {
  selected: boolean;
}

const Uploads = memo(({ toggleCollectionGallery }: CollectionGalleryProps) => {
  const { addImage } = usePDFObjectsManager();
  const { uploadFiles, response } = useUploadFiles();
  const { getUploads, response: getUploadsResponse, isLoading } = useGetUploads();
  const [dialogOpen, setOpen] = useState(false);
  const { jwt } = useUserContext();

  const [uploads, setUploads] = useState<FileItem[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUploads, setFilteredUploads] = useState<FileItem[]>([]);

  useEffect(() => {
    console.log('isLoading in useEffect:', isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (searchTerm) {
      console.log('filters');
      setFilteredUploads(
        uploads.filter(upload => upload.fileName.toLowerCase().includes(searchTerm.toLowerCase()))
      );
    } else {
      console.log('not filters');
      setFilteredUploads(uploads);
    }
  }, [searchTerm, uploads]);

  useEffect(() => {
    getUploads(jwt!);
  }, []);

  useEffect(() => {
    if (getUploadsResponse) {
      const getUploadsResponseWithSelected: FileItem[] = getUploadsResponse.map(upload => ({
        ...upload,
        selected: false,
      }));
      setUploads(getUploadsResponseWithSelected);
    }
  }, [getUploadsResponse]);

  useEffect(() => {
    if (response) {
      getUploads(jwt!);
    }
  }, [response, jwt]);

  console.log('isLoading:', isLoading);

  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const uploadFilesRequest: Blob[] = [];
    if (files) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        if (/^image\/(png|jpg|jpeg|svg\+xml)$/.test(file.type)) {
          uploadFilesRequest.push(file);
          console.log('file:', file);
        } else {
          console.log('Unsupported file type:', file.type);
        }
      }
      uploadFiles(jwt!, uploadFilesRequest);
    }
  };

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleClearSelections = () => {
    setUploads(
      uploads.map(upload => {
        return { ...upload, selected: false };
      })
    );
  };

  const setSelected = (fileId: string, selected: boolean) => {
    setUploads(
      uploads.map(upload => {
        if (upload.fileId === fileId) {
          return { ...upload, selected };
        }
        return upload;
      })
    );
  };

  const handleOpen = () => setOpen(!dialogOpen);

  return (
    <>
      <Dialog open={dialogOpen} handler={handleOpen} size='sm'>
        <DialogBody>
          <div className='flex flex-row justify-center'>
            <div className='text-center mt-2'>
              <h1 className='text-2xl font-bold text-black'>
                Delete {filteredUploads.filter(file => file.selected).length} Files ?
              </h1>
              <p className='text-l text-gray-800'>Items cannot be recovered.</p>
            </div>
          </div>
        </DialogBody>
        <DialogFooter>
          <button
            className='bg-blue-500 text-white py-2 px-4  mx-5 rounded font-bold hover:bg-blue-700'
            onClick={handleOpen}
          >
            <span>Cancel</span>
          </button>
          <button
            className='bg-red-500 text-white py-2 px-4 rounded font-bold hover:bg-red-700'
            onClick={handleOpen}
          >
            <span>Delete</span>
          </button>
        </DialogFooter>
      </Dialog>
      <div
        className='bg-gray-800 border-r border-gray-300 flex flex-col h-full'
        style={{ minWidth: '19rem', maxWidth: '19rem', height: 'calc(100vh - 4rem)', top: 0 }}
      >
        <div className='flex flex-row items-center px-4 pt-2 relative'>
          <div className='absolute pl-2'>
            <MdOutlineSearch size={24} />
          </div>
          <input
            type='text'
            placeholder='Search'
            value={searchTerm}
            onChange={handleSearch}
            className='w-full p-2 border border-gray-400 rounded pl-10 h-10'
          />
        </div>
        {filteredUploads.filter(filter => filter.selected).length > 0 ? (
          <div className=' py-4 px-4'>
            <div className='flex flex-row w-full items-center h-[40px] font-bold text-white border-black rounded'>
              <div className='mx-auto'>
                {filteredUploads.filter(filter => filter.selected).length} Selection(s){' '}
              </div>{' '}
              <button className='hover:bg-gray-900 py-2 px-2 rounded' onClick={handleOpen}>
                <GoTrash size={25} />
              </button>
              <button
                className='hover:bg-gray-900 py-2 px-2 rounded'
                onClick={handleClearSelections}
              >
                <GoX size={25} />
              </button>
            </div>
          </div>
        ) : (
          <div className='p-3'>
            <input
              type='file'
              multiple
              onChange={handleFileUpload}
              className='w-full bg-blue-700 text-white rounded py-2 hover:bg-blue-900 cursor-pointer'
              style={{ display: 'none' }}
              id='file-upload'
            />
            <label
              htmlFor='file-upload'
              className='w-full bg-blue-700 text-white rounded py-2 hover:bg-blue-900 cursor-pointer block text-center'
            >
              Upload Files
            </label>
          </div>
        )}
        <div className='flex-grow overflow-auto' style={{ flexBasis: 'auto' }}>
          {isLoading && (
            <div className='flex justify-center p-10'>
              <Spinner size={'large'} />
            </div>
          )}
          <div className='grid grid-cols-2 md:grid-cols-2 gap-y-4 mt-2'>
            {!isLoading &&
              filteredUploads.map((upload, index) => (
                <button
                  key={index}
                  className='image-container relative focus:outline-none flex justify-center items-center group'
                >
                  <input
                    type='checkbox'
                    checked={upload.selected}
                    className='absolute check-box-overlay ml-6 mt-3 hover:bg-blue-900 cursor-pointer rounded'
                    style={{
                      display: upload.selected ? 'block' : '',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setSelected(upload.fileId, !upload.selected);
                      e.currentTarget.blur();
                    }}
                  />
                  <div
                    className='cursor-pointer flex justify-center items-center'
                    style={{
                      minHeight: '120px',
                      minWidth: '120px',
                      maxWidth: '120px',
                      maxHeight: '120px',
                      overflow: 'visible',
                      padding: '0px',
                      display: 'flex',
                      justifyContent: 'center',
                      transition: 'transform 0.2s ease-in-out',
                      border: upload.selected ? '2px solid #3182ce' : '2px solid transparent',
                    }}
                    onClick={() => {
                      if (upload.selected) {
                        setSelected(upload.fileId, false);
                        return;
                      }
                      addImage(upload.url, upload.fileId);
                    }}
                  >
                    <img src={upload.url} alt={upload.fileName} />
                  </div>
                </button>
              ))}
          </div>
        </div>
        <div className='w-full text-center py-4'>
          <button
            className='bg-blue-500 text-white py-2 px-4 rounded'
            onClick={toggleCollectionGallery}
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
});

Uploads.displayName = 'Uploads';

export default Uploads;
