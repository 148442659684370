import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Typography,
} from '@material-tailwind/react';
import InputText from '../../../components/ui/InputText';
import { useEffect, useState } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { HiCheck } from 'react-icons/hi2';
import isEmail from 'validator/lib/isEmail';
import { useCreateUser } from '../hooks/useCreateUser';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';
import { UserDTO } from '../../../api/user/types';

interface AddUserFormProps {
  dialogOpen: boolean;
  handleOpen: () => void;
  handleConfirm: (user: UserDTO) => void;
  handleCancel: () => void;
  buttonLabel: string;
  setUsers: React.Dispatch<React.SetStateAction<UserDTO[]>>;
}
const AddUserForm = ({
  dialogOpen,
  handleOpen,
  handleConfirm,
  handleCancel,
  buttonLabel,
}: AddUserFormProps) => {
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [role, setRole] = useState('User'); // Added role state
  const [errors, setErrors] = useState({
    emailInvalid: false,
    nameEmpty: false,
  });
  const { jwt } = useUserContext();
  const [openMenu, setOpenMenu] = useState(false);
  const { createUser, response, isLoading, error } = useCreateUser();
  const { setError } = useAPIErrorContext();

  useEffect(() => {
    if (response) {
      console.log('response is', response);
      handleConfirm(response);
    }
  }, [response, handleConfirm]);

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  const handleEmailChange = (value: string) => {
    setEmail(value);
    setErrors({ ...errors, emailInvalid: false });
  };

  const handleNameChange = (value: string) => {
    setName(value);
    setErrors({ ...errors, nameEmpty: false });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {
      emailInvalid: false,
      nameEmpty: false,
    };

    if (!isEmail(email)) {
      newErrors.emailInvalid = true;
      valid = false;
    }

    if (!name) {
      newErrors.nameEmpty = true;
      valid = false;
    }

    console.log('errors is', email);

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = () => {
    console.log('isValidated', validateForm());
    if (validateForm()) {
      createUser(email, name, role, jwt!);
    }
  };

  return (
    <Dialog open={dialogOpen} handler={handleOpen} size='sm' className='p-2'>
      <DialogHeader>
        <Typography variant='h4'>Add User</Typography>
      </DialogHeader>
      <DialogBody className='flex flex-col gap-4 font-normal text-black'>
        <div>
          <label htmlFor='email'>
            <Typography variant='h6'>Email</Typography>
          </label>
          <InputText
            value={email}
            handleChange={handleEmailChange}
            placeholder='Email'
            id='email'
            type='email'
          />
        </div>
        {errors.emailInvalid && (
          <Typography variant='h6' color='red'>
            Please enter a valid email address
          </Typography>
        )}
        <div>
          <label htmlFor='name'>
            <Typography variant='h6'>Name</Typography>
          </label>
          <InputText
            value={name}
            handleChange={handleNameChange}
            placeholder='Name'
            id='name'
            type='text'
          />
        </div>
        {errors.nameEmpty && (
          <Typography variant='h6' color='red'>
            Please enter a name
          </Typography>
        )}
        <div>
          <Typography variant='h6'>Role</Typography>
          <Menu placement='bottom-start' open={openMenu} handler={setOpenMenu}>
            <MenuHandler>
              <button className='flex flex-row bg-white p-2 border-2 border-gray-400 rounded hover:bg-gray-200 items-center gap-3 text-black'>
                <Typography variant='small'>{role}</Typography>
                <ChevronDownIcon
                  strokeWidth={3}
                  className={`h-3.5 w-3.5 transition-transform ${openMenu ? 'rotate-180' : ''}`}
                />
              </button>
            </MenuHandler>
            <MenuList className='z-[9999]'>
              <MenuItem
                onClick={() => {
                  setRole('User');
                  setOpenMenu(false);
                }}
                className='flex flex-row'
              >
                <div className='flex justify-left items-center gap-5 w-full h-full'>User</div>
                {role === 'User' && <HiCheck className='text-green-500' size={20} />}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setRole('Admin');
                  setOpenMenu(false);
                }}
                className='flex flex-row'
              >
                <div className='flex justify-left items-center gap-5 w-full h-full'>Admin</div>
                {role === 'Admin' && <HiCheck className='text-green-500' size={20} />}
              </MenuItem>
            </MenuList>
          </Menu>
        </div>
      </DialogBody>
      <DialogFooter>
        <div className='flex flex-row gap-4'>
          <Button color='red' onClick={handleCancel} variant='text'>
            Cancel
          </Button>
          <Button
            color='indigo'
            onClick={handleSubmit}
            variant='text'
            role='button'
            aria-label='Submit Add User'
            disabled={isLoading || errors.emailInvalid || errors.nameEmpty}
          >
            {isLoading ? 'Saving...' : buttonLabel}
          </Button>
        </div>
      </DialogFooter>
    </Dialog>
  );
};

export default AddUserForm;
