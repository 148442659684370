interface InputTextProps {
  value: string;
  placeholder: string;
  handleChange: (value: string) => void;
  type?: string; // Optional type prop
  id?: string; // Optional id prop
}

const InputText = ({ value, handleChange, placeholder, id, type = 'text' }: InputTextProps) => {
  return (
    <div className='flex flex-row items-center w-full'>
      <input
        type={type}
        placeholder={placeholder}
        value={value}
        id={id}
        onChange={e => handleChange(e.target.value)}
        className='w-full border border-gray-400 rounded h-10 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 text-sm'
      />
    </div>
  );
};

export default InputText;
