{
  /* <div className='my-4 flex items-center justify-between'>
<hr className='w-full' /> <span className='px-2 text-gray-600 bg-white'>OR</span>{' '}
<hr className='w-full' />
</div> */
}

interface LabeledDividerProps {
  label: string;
}

const LabeledDivider = ({ label }: LabeledDividerProps) => {
  return (
    <div className='my-4 flex items-center justify-between'>
      <hr className='w-full' /> <span className='px-2 text-gray-600 bg-white'>{label}</span>{' '}
      <hr className='w-full' />
    </div>
  );
};

export default LabeledDivider;
