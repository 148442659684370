import { Option, Select, Tooltip } from '@material-tailwind/react';

interface DropdownOption {
  value: string;
  icon: string;
}

interface DropdownProps {
  options: DropdownOption[];
  onSelect: (value: string) => void;
  selectedValue: string;
  name: string;
}

const Dropdown = ({ options, onSelect, selectedValue, name }: DropdownProps) => {
  const handleSelect = (option: DropdownOption) => {
    onSelect(option.value);
  };
  const value = () => {
    return (
      <div className='flex items-center'>
        <div
          className='w-5 h-5 mr-2'
          dangerouslySetInnerHTML={{
            __html: options.find(option => option.value === selectedValue)!.icon,
          }}
        />
      </div>
    );
  };

  return (
    <div className='flex items-center'>
      <Tooltip placement='top' content={name}>
        <Select selected={value}>
          {options.map((option, index) => (
            <Option
              key={index}
              value={option.value}
              onClick={() => {
                handleSelect(option);
              }}
            >
              <div className='flex items-center'>
                <div className='w-5 h-5 mr-2' dangerouslySetInnerHTML={{ __html: option.icon }} />
              </div>
            </Option>
          ))}
        </Select>
      </Tooltip>
    </div>
  );
};

export default Dropdown;
