import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useBulkDeleteTemplates = () => {
  const { makeCall, response, isLoading, error } = useApiCall<boolean>();
  const { templateService } = useAPIContext();

  const deleteTemplates = async (templateIds: string[], jwt: string) => {
    await makeCall(signal => {
      return templateService.bulkDelete(templateIds, jwt, signal);
    });
  };

  return { deleteTemplates, response, isLoading, error };
};
