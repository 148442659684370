import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import CheckBox from '../../../components/ui/CheckBox';
import {
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { ArrowLeftIcon, ArrowRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useUserContext } from '../../../contexts/hooks/useUserContext';

import { HiOutlinePlus } from 'react-icons/hi2';
import SearchBar from '../../../components/ui/SearchBar';
import AlertConfirm from '../../../components/ui/AlertConfirm';

import { UserDTO } from '../../../api/user/types';
import { useGetAllUsers } from '../hooks/useGetAllUsers';
import { useDeleteUsers } from '../hooks/useDeleteUsers';
import AddUserForm from './AddUserForm';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';
import TableLoading from '../../../components/ui/TableLoading';

const getInitials = (user: { name?: string; email: string }) => {
  let initials = 'U'; // Default initials if user data is not available
  if (user?.name) {
    initials = user.name
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase(); // Or construct from name if available
  } else if (user?.name) {
    initials = user.email[0].toUpperCase(); // Use the first letter of the email, capitalized
  }
  return initials;
};

const columnHelper = createColumnHelper<UserDTO>();

const UserManagement = () => {
  const { deleteUsers, response: deleteUsersResponse, error } = useDeleteUsers();
  const [deletingUserIds, setDeletingUserIds] = useState<string[]>([]);
  const { jwt } = useUserContext();
  const [rowSelection, setRowSelection] = useState({});
  const [filter, setFilter] = useState('');
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [showUserAddModal, setShowUserAddModal] = useState(false);

  const [users, setUsers] = useState<UserDTO[]>([]);
  const {
    getAllUsers,
    response: getAllUsersResponse,
    error: getAllUsersError,
    isLoading: getAllUsersIsLoading,
  } = useGetAllUsers();

  const { setError } = useAPIErrorContext();

  useEffect(() => {
    if (error) {
      setError(error);
    }
    if (getAllUsersError) {
      setError(getAllUsersError);
    }
  }, [error, setError, getAllUsersError]);

  useEffect(() => {
    getAllUsers(jwt!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteUser = useCallback(
    (userId: string) => {
      setDeletingUserIds([userId]);
      deleteUsers([userId], jwt!);
    },
    [deleteUsers, jwt]
  );

  useEffect(() => {
    if (getAllUsersResponse) {
      const orderedUsers = getAllUsersResponse.sort(
        //deactivated users should be at the bottom
        (a, b) => (a.deactivatedAt ? 1 : 0) - (b.deactivatedAt ? 1 : 0)
      );
      setUsers(orderedUsers);
    }
  }, [getAllUsersResponse]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('email', {}),
      columnHelper.display({
        id: 'selection',
        cell: info => {
          return (
            <CheckBox
              checked={info.row.getIsSelected()}
              handleChange={info.row.getToggleSelectedHandler()}
            />
          );
        },
        header: info => {
          return (
            <CheckBox
              checked={info.table.getIsAllPageRowsSelected()}
              handleChange={info.table.getToggleAllPageRowsSelectedHandler()}
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        cell: info => (
          <div className='flex items-center gap-3'>
            <Tooltip placement='bottom' content={info.row.original.name}>
              <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white cursor-pointer'>
                {getInitials(info.row.original)}
              </div>
            </Tooltip>
            <div className='flex flex-col'>
              <Typography variant='h6' color='blue-gray' className='font-bold'>
                {info.row.original.name}
              </Typography>
              <Typography variant='small' className='font-normal opacity-80 text-gray-900'>
                {info.row.original.email}
              </Typography>
            </div>
          </div>
        ),
        header: 'Name',
      }),
      columnHelper.accessor('role.name', {
        cell: info => (
          <Typography variant='small' className='font-normal'>
            {info.getValue()}
          </Typography>
        ),
        header: 'Role',
      }),
      columnHelper.accessor('deactivatedAt', {
        cell: info => (
          <div className='w-max'>
            <span
              className={`inline-block px-3 py-1 text-xs font-semibold rounded ${
                info.getValue() ? 'bg-gray-300 text-gray-800' : 'bg-green-300 text-white'
              }`}
            >
              {info.getValue() ? 'INACTIVE' : 'ACTIVE'}
            </span>
          </div>
        ),
        header: 'Status',
      }),
      columnHelper.accessor('createdAt', {
        cell: info => (
          <Typography variant='small' className='font-normal'>
            {new Date(info.getValue()).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        ),
        header: 'Date Created',
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => (
          <Menu placement='left'>
            <MenuHandler>
              <IconButton
                variant='text'
                aria-label='actions'
                role='button'
                onClick={() => {
                  console.log('clicked actions');
                }}
              >
                <EllipsisHorizontalIcon className='h-7 w-7' color='black' />
              </IconButton>
            </MenuHandler>
            <MenuList>
              <MenuItem
                aria-label='deactivate'
                role='button'
                onClick={() => {
                  handleDeleteUser(info.row.original.userId);
                }}
              >
                Deactivate
              </MenuItem>
            </MenuList>
          </Menu>
        ),
        header: 'Actions',
      }),
    ],
    [handleDeleteUser]
  );

  const table = useReactTable({
    data: users,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      globalFilter: filter,
      columnVisibility: {
        email: false,
      },
    },
    onGlobalFilterChange: setFilter,

    globalFilterFn: 'includesString',
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(7);
  }, [table]);

  useEffect(() => {
    table.resetRowSelection();
  }, [users, filter, table]);

  const handleConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
    const deletingUserIds = table
      .getFilteredSelectedRowModel()
      .rows.map(row => row.original.userId);
    setDeletingUserIds(deletingUserIds);
    deleteUsers(deletingUserIds, jwt!);
  };

  useEffect(() => {
    //handle bulk delete
    if (deleteUsersResponse) {
      console.log('deleteUsersResponse', deleteUsersResponse);
      //set deactivatedAt to current date
      setUsers(prev => {
        return prev.map(user => {
          if (deletingUserIds.includes(user.userId)) {
            return {
              ...user,
              deactivatedAt: new Date(),
            };
          }
          return user as UserDTO;
        });
      });
    }
  }, [deleteUsersResponse, deletingUserIds]);

  const handleConfirm = useCallback((user: UserDTO) => {
    setShowUserAddModal(false);
    setUsers(prev => [...prev, user]);
  }, []);

  return (
    <div className='h-full w-full'>
      <AddUserForm
        dialogOpen={showUserAddModal}
        handleOpen={() => setShowUserAddModal(!showUserAddModal)}
        handleConfirm={handleConfirm}
        handleCancel={() => setShowUserAddModal(false)}
        buttonLabel='Add'
        setUsers={setUsers}
      />
      <AlertConfirm
        dialogOpen={isConfirmDeleteOpen}
        handleOpen={() => setIsConfirmDeleteOpen(!isConfirmDeleteOpen)}
        handleConfirm={handleConfirmDelete}
        handleCancel={() => setIsConfirmDeleteOpen(false)}
        message={`Are you sure you want to deactivate ${
          table.getFilteredSelectedRowModel().rows.length
        } Users(s)?`}
        buttonLabel='Deactivate'
      />
      <div className='flex justify-between gap-2'>
        <div className='flex flex-row'>
          <Typography variant='h3' color='blue-gray' className='font-bold p-5 slide-in-from-left'>
            Users
          </Typography>
          <SearchBar value={filter} handleChange={setFilter} placeholder='Find a user' />
        </div>
        <div className='flex flex-row gap-10 mx-10'>
          {Object.keys(rowSelection).length > 0 && (
            <div className='flex items-center gap-2'>
              <div className='flex flex-row items-center'>
                <Menu placement='bottom'>
                  <MenuHandler>
                    <button className='bg-gray-200 p-2 border-2 border-indigo-200 rounded hover:bg-gray-300'>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        <strong>{Object.keys(rowSelection).length}</strong> Selected
                      </Typography>
                    </button>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setIsConfirmDeleteOpen(true);
                      }}
                    >
                      <div className='flex justify-left items-center gap-5 w-full h-full'>
                        Deactivate
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          )}
          <div className='flex items-center gap-2'>
            <div className='flex flex-col'>
              {' '}
              <div className='flex flex-row gap-2 justify-center items-center'>
                <IconButton
                  variant='outlined'
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className='h-8 w-8'
                >
                  <ArrowLeftIcon strokeWidth={2} className='h-4 w-4' color='black' />
                </IconButton>

                <Typography variant='small' color='blue-gray' className='font-normal p-1'>
                  <strong>
                    {table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                      1}{' '}
                    -
                    {table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                      table.getPaginationRowModel().rows.length}
                  </strong>{' '}
                  of <strong>{table.getFilteredRowModel().rows.length}</strong>
                </Typography>
                <IconButton
                  variant='outlined'
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className='h-8 w-8'
                >
                  <ArrowRightIcon strokeWidth={2} className='h-4 w-4' color='black' />
                </IconButton>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <button
              aria-label='Add User'
              role='button'
              onClick={() => {
                setShowUserAddModal(true);
              }}
              className='flex items-center justify-center bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 gap-2'
            >
              <HiOutlinePlus size={25} />
              Add
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          maxHeight: 'calc(100vh - 13rem)',
          overflowY: 'auto',
        }}
        className='shadow-sm rounded-md overflow-x-auto'
      >
        <div className='p-2'>
          <table className='w-full min-w-max table-auto text-left bg-white'>
            <thead className='bg-white sticky top-0 z-10 shadow-sm'>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className='p-4 text-blue-gray-700'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='pt-4 bg-gray-50'>
              {getAllUsersIsLoading && <TableLoading />}
              {!getAllUsersIsLoading &&
                table.getRowModel().rows.map(row => (
                  <tr
                    key={row.original.userId}
                    className={`${row.getIsSelected() ? 'bg-blue-200' : ''}`}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className='p-4 border-b border-blue-gray-50'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='h-4' />
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
