import { useEffect } from 'react';
import usePDFStore from '../../stores/usePDFStore';
import { useUpdateTemplateContent } from '../api/templates/useUpdateTemplateContent';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import usePDFObjectsManager from './usePDFObjectsManager';

const useAutoSave = () => {
  const { updateLastSaved, pdfObjects, autoSave, lastSaved, template } = usePDFStore(state => ({
    updateLastSaved: state.updateLastSaved,
    pdfObjects: state.pdfObjects,
    autoSave: state.settings.autoSave,
    lastSaved: state.lastSaved,
    template: state.template,
  }));
  const { updateTemplateContent } = useUpdateTemplateContent();
  const { getContentJSON } = usePDFObjectsManager();
  const { jwt } = useUserContext();

  useEffect(() => {
    if (
      pdfObjects.length > 0 &&
      autoSave.isEnabled &&
      Date.now() - lastSaved > autoSave.frequencyLimit &&
      template.templateId &&
      jwt
    ) {
      const content = getContentJSON();
      updateTemplateContent(template.templateId, content, jwt);
      updateLastSaved(Date.now());
    }
  }, [
    pdfObjects,
    updateLastSaved,
    autoSave.isEnabled,
    autoSave.frequencyLimit,
    lastSaved,
    template.templateId,
    jwt,
    updateTemplateContent,
    getContentJSON,
  ]);
};
export { useAutoSave };
