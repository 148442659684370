import { UserDTO } from '../../../api/user/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useConfirmEmail = () => {
  const { makeCall, response, isLoading, error } = useApiCall<UserDTO>();
  const { userService } = useAPIContext();

  const confirmEmail = async (token: string) => {
    await makeCall(signal => {
      return userService.confirmEmail(token, signal);
    });
  };

  return { confirmEmail, confirmEmailResponse: response, isLoading, error };
};
