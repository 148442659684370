import ObjectAlignment from './ObjectAlignment';
import { LineSVGData } from '../../../types/PDFObjects';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import usePDFStore from '../../../stores/usePDFStore';

export interface LineSVGToolsPanelPropsProps {
  lineSVGData: LineSVGData;
}

const LineSVGToolsPanel = ({ lineSVGData }: LineSVGToolsPanelPropsProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const { alignObject } = usePDFObjectsManager();

  const handleUpdatePoint = (axis: 'x1' | 'y1' | 'x2' | 'y2', value: string) => {
    console.log('value', value);
    console.log('adjusted value', Math.round(parseFloat(value)) || 0);
    updatePDFObject(lineSVGData.id, {
      line: { ...lineSVGData.line, [axis]: Math.round(parseFloat(value)) || 0 },
    });
  };

  return (
    <div
      data-testid='right-side-toolbar'
      className='flex flex-col items-center p-4'
      style={{ width: '16rem' }}
    >
      <ObjectAlignment pdfObject={lineSVGData} alignObject={alignObject} />

      <div className='flex items-center space-x-2 py-4'>
        {/* X Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            X1
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(lineSVGData.line.x1)}
            onChange={e => handleUpdatePoint('x1', e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Y1
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(lineSVGData.line.y1)}
            onChange={e => handleUpdatePoint('y1', e.target.value)}
          />
        </div>
      </div>
      <div className='flex items-center space-x-2'>
        {/* X Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            X2
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(lineSVGData.line.x2)}
            onChange={e => handleUpdatePoint('x2', e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Y2
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(lineSVGData.line.y2)}
            onChange={e => handleUpdatePoint('y2', e.target.value)}
          />
        </div>
      </div>
    </div>
  );
};

export default LineSVGToolsPanel;
