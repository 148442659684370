import { CreateTemplateRequest, Template } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useCreateTemplate = () => {
  const { makeCall, response, isLoading, error } = useApiCall<Template>();
  const { templateService } = useAPIContext();

  const createTemplate = async (data: CreateTemplateRequest, jwt: string) => {
    await makeCall(signal => {
      return templateService.create(data, jwt, signal);
    });
  };

  return { createTemplate, response, isLoading, error };
};
