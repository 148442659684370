import { useCallback, useEffect, useMemo, useRef } from 'react';
import { FontFamilies, FontFamilyMarginTopScale } from '../../../../types/FontFamilies';
import { setTextEditCursorToFront } from '../../../../helpers/domHelpers';
import React from 'react';

interface EditableTextDivProps {
  onContentChange: (e: React.FormEvent<HTMLDivElement>) => void;
  value: string;
  fontSize: number;
  lineSpacing: number;
  fontFamily: FontFamilies;
  content: string;
  isEditing: boolean;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
}

const EditableTextDiv = React.memo(
  ({
    onContentChange,
    value,
    fontSize,
    fontFamily,
    lineSpacing,
    content,
    isEditing,
    setIsEditing,
  }: EditableTextDivProps) => {
    const divRef = useRef<HTMLDivElement>(null);
    //on content change replace the content with the new content
    useEffect(() => {
      if (!isEditing) {
        divRef.current!.innerText = content;
      }
    }, [content, isEditing]);
    const handleContentChange = (e: React.FormEvent<HTMLDivElement>) => {
      setIsEditing(true);
      onContentChange(e);
    };

    const handlePaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
      e.preventDefault();
      const text = e.clipboardData.getData('text/plain');
      //use range to insert text at cursor position
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) return;
      const range = selection.getRangeAt(0);
      range.deleteContents();
      range.insertNode(document.createTextNode(text));
      //update text value
      handleContentChange(e);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.key === 'Backspace' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
      if (e.key === 'b' && e.metaKey) {
        e.preventDefault();
      }
      if (e.key === 'i' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
      if (e.key === 'u' && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
      }
      //escape key to blur the div
      if (e.key === 'Escape') {
        divRef.current?.blur();
      }
    };

    const valueSplit = useMemo(
      () =>
        value
          .split('\n')
          .map(line => `<div>${line ? line : '<br/>'}</div>`)
          .join(''),
      [value]
    );

    const marginTop = useMemo(() => {
      return `${fontSize * FontFamilyMarginTopScale[fontFamily] - ((lineSpacing - 1) * fontSize) / 2}px`;
    }, [fontSize, fontFamily, lineSpacing]);

    const handleDoubleClick = (e: React.MouseEvent) => {
      console.log('double click');
      divRef.current?.focus();
      setTextEditCursorToFront(divRef.current);
      e.stopPropagation();
    };

    const handleOnInput = (e: React.FormEvent<HTMLDivElement>) => {
      handleContentChange(e);
    };

    const handleBlur = useCallback(() => {
      setIsEditing(false);
    }, [setIsEditing]);

    return (
      <div
        className='editable-text-div'
        ref={divRef}
        onDoubleClick={handleDoubleClick}
        onInput={handleOnInput}
        onPaste={handlePaste}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onMouseDownCapture={e => {
          if (!isEditing) return;
          e.stopPropagation();
        }}
        onMouseDown={e => {
          if (!isEditing) return;
          e.stopPropagation();
        }}
        onFocus={() => setIsEditing(true)}
        contentEditable
        suppressContentEditableWarning={true}
        dangerouslySetInnerHTML={{
          __html: valueSplit,
        }}
        style={{
          outline: 'none',
          width: '100%',
          height: '100%',
          whiteSpace: 'break-spaces',
          wordBreak: 'break-word',
          marginTop: `${marginTop}`,
        }}
      ></div>
    );
  }
);

EditableTextDiv.displayName = 'EditableTextDiv';

export default EditableTextDiv;
