import { AuthResponse } from '../../../api/auth/types';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';

export interface UseLoginReturn {
  login: (email: string, password: string) => Promise<void>;
  loginResponse: AuthResponse | null;
  isLoading: boolean;
  error: string | null;
}

export const useLogin = (): UseLoginReturn => {
  const { makeCall, response, isLoading, error } = useApiCall<AuthResponse>();
  const { authService } = useAPIContext();

  const login = async (email: string, password: string) => {
    await makeCall(signal => authService.login(email, password, signal));
  };

  return { login, loginResponse: response, isLoading, error };
};
