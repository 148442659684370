import { Card, CardBody, CardFooter, Typography, Button } from '@material-tailwind/react';
import { useNavigate } from 'react-router-dom';

const CreatePDFCTA = () => {
  const navigate = useNavigate();
  return (
    <Card className='mt-6 w-full text-center'>
      <CardBody>
        <div className='flex row w-full justify-center'>
          <span className='text-[40px]'>⚡️</span>
          <Typography variant='h5' color='blue-gray' className='mb-2 px-5 text-center'>
            Welcome to ScribePDF in BETA
          </Typography>
          <span className='text-[40px]'>⚡️</span>
        </div>
        <Typography>
          ScribePDF is a powerful tool that allows you to design and create PDFs with ease. In order
          to generate a PDF, you need to create a template first using our template editor.
        </Typography>
      </CardBody>
      <CardFooter className='pt-0'>
        <a className='inline-block'>
          <Button
            size='sm'
            variant='text'
            className='flex items-center gap-2'
            onClick={() => {
              navigate('/templates/create');
            }}
          >
            Create First PDF
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              strokeWidth={2}
              stroke='currentColor'
              className='h-4 w-4'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                d='M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3'
              />
            </svg>
          </Button>
        </a>
      </CardFooter>
    </Card>
  );
};

export default CreatePDFCTA;
