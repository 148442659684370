import api from '../api';

export interface IPDFService {
  downloadPdf(templateId: string, jwt: string): Promise<Blob>;
}

export class PDFService implements IPDFService {
  private static instance: PDFService;

  public static getInstance(): PDFService {
    if (!PDFService.instance) {
      PDFService.instance = new PDFService();
    }
    return PDFService.instance;
  }
  public async downloadPdf(templateId: string, jwt: string): Promise<Blob> {
    const response = await api.post(
      `templates/${templateId}/generate-pdf`,
      {
        data: {},
      },
      {
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwt}`,
        },
      }
    );

    return response.data;
  }
}
