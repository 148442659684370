import api from '../api';
import { handleAxiosError } from '../helpers';
import { SVGIcon } from './types';

export interface ISVGIconsService {
  getAll(jwt: string, signal: AbortSignal): Promise<SVGIcon[]>;
}

export class SVGIconsService implements ISVGIconsService {
  private static instance: SVGIconsService;

  public static getInstance(): SVGIconsService {
    if (!SVGIconsService.instance) {
      SVGIconsService.instance = new SVGIconsService();
    }
    return SVGIconsService.instance;
  }
  public async getAll(jwt: string, signal: AbortSignal): Promise<SVGIcon[]> {
    try {
      const response = await api.get(`svg-icons`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwt}`,
        },
        signal,
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
