import { Dialog, DialogBody } from '@material-tailwind/react';
import ShareTemplate from './ShareTemplate';

interface SharingModalProps {
  dialogOpen: boolean;
  handleOpen: () => void;
  templateId: string;
}

const SharingModal = ({ dialogOpen, handleOpen, templateId }: SharingModalProps) => {
  return (
    <Dialog
      open={dialogOpen}
      handler={handleOpen}
      size='md'
      style={{
        maxHeight: '80vh',
      }}
      className='overflow-auto'
    >
      <DialogBody className='font-normal'>
        <ShareTemplate templateId={templateId} />
      </DialogBody>
    </Dialog>
  );
};

export default SharingModal;
