import { create } from 'zustand';

export enum KeyBindingAction {
  DELETE = 'delete',
  REDO = 'redo',
  UNDO = 'undo',
  COPY = 'copy',
  PASTE = 'paste',
  SAVE = 'save',
  ESCAPE = 'escape',
}
// Define the structure of the keybindings state
interface KeyBindingsState {
  keyBindings: Record<KeyBindingAction, string>;
  updateKeyBinding: (action: KeyBindingAction, keyCombination: string) => void;
  resetKeyBindings: () => void;
}

// Define the default keybindings
const defaultKeyBindings: Record<KeyBindingAction, string> = {
  [KeyBindingAction.DELETE]: 'Backspace',
  [KeyBindingAction.ESCAPE]: 'Escape',
  [KeyBindingAction.REDO]: 'Ctrl+y',
  [KeyBindingAction.UNDO]: 'Ctrl+z',
  [KeyBindingAction.COPY]: 'Ctrl+c',
  [KeyBindingAction.PASTE]: 'Ctrl+v',
  [KeyBindingAction.SAVE]: 'Ctrl+s',
};

// Create the store
const useKeyBindingsStore = create<KeyBindingsState>(set => ({
  keyBindings: { ...defaultKeyBindings },
  updateKeyBinding: (action, keyCombination) =>
    set(state => ({
      keyBindings: {
        ...state.keyBindings,
        [action]: keyCombination,
      },
    })),
  resetKeyBindings: () => set({ keyBindings: { ...defaultKeyBindings } }),
}));

export default useKeyBindingsStore;
