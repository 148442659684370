import usePDFStore from '../../stores/usePDFStore';

const usePDFSettings = () => {
  const page = usePDFStore(state => state.settings.page);
  const scale = usePDFStore(state => state.settings.scale);
  const updateScale = usePDFStore(state => state.updateScale);

  return {
    page,
    scale,
    updateScale,
  };
};

export default usePDFSettings;
