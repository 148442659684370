import { useGoogleAuth } from '../../../features/auth/hooks/useGoogleAuth';
import { useExchangeGoogleAuthCode } from '../../../features/auth/hooks/useExchangeGoogleAuthCode';
import { useEffect, useState } from 'react';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import { useNavigate } from 'react-router-dom';
import { useLogin } from '../../../features/auth/hooks/useLogin';
import PageOverlayLoader from '../../../components/ui/PageOverlayLoader';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import GoogleLoginButton from '../../../features/auth/components/GoogleLoginButton';
import LabeledDivider from '../../../components/ui/LabeledDivider';
import InputText from '../../../components/ui/InputText';
import FlatButton from '../../../components/ui/FlatButton';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';

const LoginPage = () => {
  const { initiateGoogleLogin, codeResponse, setCodeResponse } = useGoogleAuth();
  const { isLoading, exchangeAuthCode, authResponse, error, sentRequest, setSentRequest } =
    useExchangeGoogleAuthCode();
  const { isLoading: isLoadingLogin, loginResponse, error: loginError, login } = useLogin();
  const { setSession } = useUserContext();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [formError, setFormError] = useState<string | null>(null);
  const { setError } = useAPIErrorContext();

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  useEffect(() => {
    if (error) {
      setCodeResponse(null);
    }
  }, [error, setCodeResponse]);

  useEffect(() => {
    if (codeResponse && !sentRequest) {
      exchangeAuthCode(codeResponse);
      setCodeResponse(null);
    }
  }, [codeResponse, exchangeAuthCode, sentRequest, setCodeResponse]);

  useEffect(() => {
    if (authResponse) {
      setSession(authResponse.user, authResponse.jwt);
      navigate('/dashboard');
    }
  }, [authResponse, setSession, navigate]);

  useEffect(() => {
    if (loginResponse) {
      setSession(loginResponse.user, loginResponse.jwt);
      navigate('/dashboard');
    }
  }, [loginResponse, setSession, navigate]);

  const handleLogin = () => {
    if (!email || !password) {
      setFormError('Please fill in all fields');
      return;
    }
    login(email, password);
  };

  const handleLoginWithGoogle = () => {
    setSentRequest(false);
    initiateGoogleLogin();
  };

  useEffect(() => {
    setFormError(null);
  }, [email, password]);

  return (
    <AuthPageContainer>
      {isLoading && <PageOverlayLoader message='Logging you in...' />}
      <AuthContentCard>
        <AuthContentCard.Logo />
        <AuthContentCard.Title>ScribePDF</AuthContentCard.Title>
        <br />
        <GoogleLoginButton onClick={handleLoginWithGoogle} />
        <LabeledDivider label='OR' />

        <div className='mb-5'>
          <label htmlFor='email' className='block mb-2 text-sm text-gray-600'>
            Email
          </label>
          <InputText
            handleChange={setEmail}
            value={email}
            placeholder='Enter your email'
            id='email'
            type='email'
          />
        </div>
        <div className='mb-6'>
          <label htmlFor='password' className='block mb-2 text-sm text-gray-600'>
            Password
          </label>
          <InputText
            handleChange={setPassword}
            value={password}
            placeholder='Enter your password'
            type='password'
            id='password'
          />
        </div>
        {formError && <div className='mb-4 text-sm text-red-500 text-center'>{formError}</div>}
        {loginError && <div className='mb-4 text-sm text-red-500 text-center'>{loginError}</div>}
        <div className='mb-6'>
          <FlatButton text='Login' onClick={handleLogin} disabled={isLoadingLogin} />
        </div>
        <div className='text-center py-2'>
          <button
            onClick={() => navigate('/reset-password-request')}
            className='text-sm text-indigo-600 hover:underline focus:outline-none focus:underline'
            type='button'
          >
            Reset Password
          </button>
        </div>
        <p className='text-sm text-center text-gray-500'>
          Don&apos;t have an account yet?{' '}
          <a
            onClick={() => navigate('/register')}
            className='text-indigo-600 focus:outline-none focus:underline hover:underline'
          >
            Sign up
          </a>
          .
        </p>
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default LoginPage;
