import { useCallback, useEffect, useRef, useState } from 'react';
import usePDFStore from '../../stores/usePDFStore';

interface UseDraggableProps {
  x: number;
  y: number;
  id: string;
  updatePosition: (position: { x: number; y: number }) => void;
  onDragEnd?: (
    id: string,
    position: {
      x: number;
      y: number;
    }
  ) => void;
}

const useDraggable = ({ x, y, id, updatePosition, onDragEnd }: UseDraggableProps) => {
  const gridSnap = usePDFStore(state => state.settings.gridSnap);
  const [dragging, setDragging] = useState(false);
  const [hasMoved, setHasMoved] = useState(false); // New state to track movement
  const startPosRef = useRef({ x: 0, y: 0 });
  const lastUpdate = useRef(Date.now());
  const scale = usePDFStore(state => state.settings.scale);
  const setIsDragging = usePDFStore(state => state.setIsDragging);

  const calculateSnappedPosition = useCallback(
    (position: { x: number; y: number }) => {
      return {
        x: Math.round(position.x / gridSnap.snapInterval) * gridSnap.snapInterval,
        y: Math.round(position.y / gridSnap.snapInterval) * gridSnap.snapInterval,
      };
    },
    [gridSnap.snapInterval]
  );
  const startDrag = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      startPosRef.current = { x, y };
      setDragging(true);
      setHasMoved(false); // Reset movement state
      setIsDragging(true);
    },
    [x, y]
  );

  const onDrag = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!hasMoved) setHasMoved(true);
      if (!dragging) return;

      const newPosition = {
        x: startPosRef.current.x + e.movementX / scale,
        y: startPosRef.current.y + e.movementY / scale,
      };

      startPosRef.current = newPosition;

      if (Date.now() - lastUpdate.current < 16) return;
      lastUpdate.current = Date.now();
      updatePosition(calculateSnappedPosition(newPosition));
    },
    [dragging, updatePosition, hasMoved, calculateSnappedPosition, scale]
  );

  const endDrag = useCallback(() => {
    setDragging(false);
    setIsDragging(false);
    if (onDragEnd) {
      const newPos = calculateSnappedPosition(startPosRef.current);
      onDragEnd(id, newPos);
    }
  }, [id, onDragEnd, calculateSnappedPosition]);

  useEffect(() => {
    if (dragging) {
      const handleMouseMove = (e: MouseEvent) => onDrag(e);
      document.addEventListener('mousemove', handleMouseMove);

      const handleMouseUp = () => {
        endDrag();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [dragging, onDrag, endDrag]);

  return { dragging, startDrag, onDrag, endDrag };
};

export default useDraggable;
