import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { RectSVGData } from '../../../types/PDFObjects';
import useDraggable from '../../../hooks/pdf/useDraggable';
import usePDFStore from '../../../stores/usePDFStore';

interface RectSVGDataProps {
  rectSVGData: RectSVGData;
}

const RectSVG = memo(({ rectSVGData }: RectSVGDataProps) => {
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const scale = usePDFStore(state => state.settings.scale);

  const [position, setPosition] = useState({
    x: rectSVGData.position.x,
    y: rectSVGData.position.y,
  });
  const [size, setSize] = useState({
    width: rectSVGData.rect.width,
    height: rectSVGData.rect.height,
  });

  const posRef = useRef(position);
  const sizeRef = useRef(size);

  useEffect(() => {
    posRef.current = position;
    sizeRef.current = size;
  }, [position, size]);

  // Function to handle the start of resize (drag start) for either end of the line
  const handleResizeStart = (e: React.MouseEvent, dragLocation: string) => {
    e.stopPropagation(); // Prevent event bubbling
    setCurrentSelections([rectSVGData.id]);
    const startX = e.clientX / scale;
    const startY = e.clientY / scale;

    // Function to handle dragging (resizing)
    const handleMouseMove = (moveEvent: MouseEvent) => {
      moveEvent.preventDefault();
      const diffX = moveEvent.clientX / scale - startX;
      const diffY = moveEvent.clientY / scale - startY;

      //calculate new size based on drag

      let newWidth = rectSVGData.rect.width;
      let newHeight = rectSVGData.rect.height;
      let newX = position.x;
      let newY = position.y;

      if (dragLocation === 'top-left') {
        newWidth -= diffX;
        newHeight -= diffY;
        newX += diffX;
        newY += diffY;
      } else if (dragLocation === 'top-right') {
        newWidth += diffX;
        newHeight -= diffY;
        newY += diffY;
      } else if (dragLocation === 'bottom-left') {
        newWidth -= diffX;
        newHeight += diffY;
        newX += diffX;
      } else {
        // 'bottom-right'
        newWidth += diffX;
        newHeight += diffY;
      }

      //if width and heigh is less than 10 ignore
      if (newWidth < 3 || newHeight < 3) return;
      setSize({ width: newWidth, height: newHeight });
      setPosition({ x: newX, y: newY });
    };

    // Cleanup function to remove event listeners after resizing
    const handleMouseUp = () => {
      updatePDFObject(rectSVGData.id, {
        rect: {
          x: rectSVGData.rect.x,
          y: rectSVGData.rect.y,
          width: sizeRef.current.width,
          height: sizeRef.current.height,
        },
        position: {
          x: posRef.current.x,
          y: posRef.current.y,
        },
      });
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    // Add mouse move and mouse up event listeners to document for drag functionality
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleDragEnd = useCallback(
    (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      updatePDFObject(id, { position });
    },
    [updatePDFObject]
  );

  const { dragging, startDrag } = useDraggable({
    x: rectSVGData.position.x,
    y: rectSVGData.position.y,
    id: rectSVGData.id,
    updatePosition: position => {
      setPosition(position);
    },
    onDragEnd: (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      handleDragEnd(id, position);
    },
  });

  useEffect(() => {
    setPosition(rectSVGData.position);
  }, [rectSVGData.position]);

  return (
    <>
      <div
        className='pdf-component-overlay'
        style={{
          zIndex: rectSVGData.zIndex,
          overflow: 'clip',
        }}
      >
        <div
          style={{
            transform: `translate(${rectSVGData.rect.x + position.x}px, ${rectSVGData.rect.y + position.y}px)`,
            width: `${size.width}px`,
            maxWidth: `${size.width}px`,
            height: `${size.height}px`,
            maxHeight: `${size.height}px`,
            position: 'absolute',
            display: 'inline-block',
            overflow: 'visible',
            pointerEvents: 'auto',
          }}
        >
          <svg
            id={rectSVGData.id}
            xmlns='http://www.w3.org/2000/svg'
            width={size.width}
            height={size.height}
            style={{
              overflow: 'visible',
              cursor: 'pointer',
            }}
          >
            <rect width={size.width} height={size.height} fill={rectSVGData.fill} />
          </svg>
        </div>
      </div>
      <div
        className='pdf-component-overlay'
        style={{
          outline: `${rectSVGData.isSelected ? '2px dashed blue' : ''}`,
          transform: `translate(${rectSVGData.rect.x + position.x}px, ${rectSVGData.rect.y + position.y}px)`,
          width: `${size.width}px`,
          maxWidth: `${size.width}px`,
          height: `${size.height}px`,
          maxHeight: `${size.height}px`,
          position: 'absolute',
          display: 'inline-block',
          overflow: 'visible',
          pointerEvents: 'auto',
        }}
      />
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'visible',
          zIndex: rectSVGData.zIndex,
        }}
      >
        <div
          style={{
            transform: `translate(${rectSVGData.rect.x + position.x}px, ${rectSVGData.rect.y + position.y}px)`,
            width: `${size.width}px`,
            maxWidth: `${size.width}px`,
            height: `${size.height}px`,
            maxHeight: `${size.height}px`,
            cursor: dragging ? 'grabbing' : 'grab',
            position: 'absolute',
            display: 'inline-block',
            overflow: 'visible',
            pointerEvents: 'auto',
            zIndex: rectSVGData.zIndex,
          }}
          onMouseDown={e => {
            e.stopPropagation();
            setCurrentSelections([rectSVGData.id]);
            startDrag(e);
          }}
        >
          <svg
            id={rectSVGData.id}
            xmlns='http://www.w3.org/2000/svg'
            style={{
              overflow: 'visible',
              cursor: 'pointer',
            }}
          >
            {rectSVGData.isSelected && (
              <>
                <circle
                  cx={rectSVGData.rect.x}
                  cy={rectSVGData.rect.y}
                  r='8'
                  fill='#f0f0f0'
                  stroke='#b0b0b0'
                  strokeOpacity='0.5'
                  onMouseDown={e => handleResizeStart(e, 'top-left')}
                  style={{ cursor: 'nwse-resize', pointerEvents: 'auto', overflow: 'visible' }}
                />
                <circle
                  cx={rectSVGData.rect.x + size.width}
                  cy={rectSVGData.rect.y}
                  r='8'
                  fill='#f0f0f0'
                  stroke='#b0b0b0'
                  strokeOpacity='0.5'
                  onMouseDown={e => handleResizeStart(e, 'top-right')}
                  style={{ cursor: 'nesw-resize', pointerEvents: 'auto' }}
                />
                {/* Bottom-left corner */}
                <circle
                  cx={rectSVGData.rect.x}
                  cy={rectSVGData.rect.y + size.height}
                  r='8'
                  fill='#f0f0f0'
                  stroke='#b0b0b0'
                  strokeOpacity='0.5'
                  onMouseDown={e => handleResizeStart(e, 'bottom-left')}
                  style={{ cursor: 'nesw-resize', pointerEvents: 'auto' }}
                />
                {/* Bottom-right corner */}
                <circle
                  cx={rectSVGData.rect.x + size.width}
                  cy={rectSVGData.rect.y + size.height}
                  r='8'
                  fill='#f0f0f0'
                  stroke='#b0b0b0'
                  strokeOpacity='0.5'
                  onMouseDown={e => handleResizeStart(e, 'bottom-right')}
                  style={{ cursor: 'nwse-resize', pointerEvents: 'auto' }}
                />
              </>
            )}
          </svg>
        </div>
      </div>
    </>
  );
});

RectSVG.displayName = 'RectSVG';
export default RectSVG;
