interface FlatButtonProps {
  text: string;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const FlatButton = ({ text, onClick, className, disabled = false }: FlatButtonProps) => {
  return (
    <button
      role='button'
      type='button'
      onClick={onClick}
      className={`w-full px-3 py-4 text-white rounded-md ${
        disabled ? 'bg-gray-400' : 'bg-indigo-600 hover:bg-indigo-700'
      } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 ${className}`}
      disabled={disabled}
    >
      {text}
    </button>
  );
};

export default FlatButton;
