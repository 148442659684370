import { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import InputText from './InputText'; // Assuming InputText is in the same directory

interface PasswordInputProps {
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
  id: string;
}

const PasswordInput = ({ value, onChange, placeholder, id }: PasswordInputProps) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <div className='flex items-center border border-gray-300 rounded-md'>
      <InputText
        type={passwordVisibility ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        handleChange={onChange}
        id={id}
      />
      <div
        className='p-2 cursor-pointer focus:border-blue-500 focus:ring-2 focus:ring-blue-500'
        onClick={togglePasswordVisibility}
      >
        {passwordVisibility ? <MdVisibility size={22} /> : <MdVisibilityOff size={22} />}
      </div>
    </div>
  );
};

export default PasswordInput;
