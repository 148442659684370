import { useState } from 'react';
import { MdSearch } from 'react-icons/md';

interface SearchBarProps {
  value: string;
  handleChange: (value: string) => void;
  placeholder: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const SearchBar = ({ value, handleChange, placeholder, onBlur, onFocus }: SearchBarProps) => {
  const [focused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    onFocus && onFocus();
  };

  const handleBlur = () => {
    setFocused(false);
    onBlur && onBlur();
  };

  return (
    <div className='flex flex-row items-center relative'>
      <div
        className='absolute pl-2 transition-transform duration-300 ease-in-out'
        style={{ transform: focused ? 'scale(1.2)' : 'scale(1)' }}
      >
        <MdSearch size={24} color={focused ? '#4B0082' : '#9CA3AF'} />
      </div>
      <input
        type='text'
        placeholder={placeholder}
        value={value}
        onChange={e => handleChange(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className='w-full p-2 border border-gray-400 rounded pl-10 h-10 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 text-sm'
      />
    </div>
  );
};

export default SearchBar;
