import { Tooltip } from '@material-tailwind/react';
import { LineSVGData } from '../../../types/PDFObjects';
import Dropdown from '../../ui/Dropdown';
import { LineCaps } from '../../../enums/LineCaps';
import usePDFStore from '../../../stores/usePDFStore';

interface LineSVGToolsProps {
  lineSVGData: LineSVGData;
}

const LineSVGTools = ({ lineSVGData }: LineSVGToolsProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);

  const lineCapStyles = [
    {
      label: LineCaps.NONE,
      value: LineCaps.NONE,
      icon: `<svg width="100" height="25" xmlns="http://www.w3.org/2000/svg">
        <line x1="17.5" y1="12.5" x2="82.5" y2="12.5" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}" />
      </svg>`,
    },
    {
      label: LineCaps.SQUARE,
      value: LineCaps.SQUARE,
      icon: `<svg width="100" height="25" xmlns="http://www.w3.org/2000/svg">
        <line x1="17.5" y1="12.5" x2="82.5" y2="12.5" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}" />
        <rect x="7.5" y="7.5" width="10" height="10" fill="${lineSVGData.stroke.color}"/>
        <rect x="82.5" y="7.5" width="10" height="10" fill="${lineSVGData.stroke.color}"/>
      </svg>`,
    },

    {
      label: LineCaps.CIRCLE,
      value: LineCaps.CIRCLE,
      icon: `<svg width="100" height="25" xmlns="http://www.w3.org/2000/svg">
          <line x1="17.5" y1="12.5" x2="82.5" y2="12.5" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}" />
          <circle cx="17.5" cy="12.5" r="5" fill="${lineSVGData.stroke.color}"/>
          <circle cx="82.5" cy="12.5" r="5" fill="${lineSVGData.stroke.color}"/>
        </svg>`,
    },

    {
      label: LineCaps.CIRCLE_OUTLINE,
      value: LineCaps.CIRCLE_OUTLINE,
      icon: `<svg width="100" height="25" xmlns="http://www.w3.org/2000/svg">
          <line x1="22.5" y1="12.5" x2="77.5" y2="12.5" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}" />
          <circle cx="17.5" cy="12.5" r="5" fill="none" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}"/>
          <circle cx="82.5" cy="12.5" r="5" fill="none" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}"/>
        </svg>`,
    },
    {
      label: LineCaps.SQUARE_OUTLINE,
      value: LineCaps.SQUARE_OUTLINE,
      icon: `<svg width="100" height="25" xmlns="http://www.w3.org/2000/svg">
          <line x1="22.5" y1="12.5" x2="77.5" y2="12.5" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}" />
          <rect x="12.5" y="7.5" width="10" height="10" fill="none" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}"/>
          <rect x="77.5" y="7.5" width="10" height="10" fill="none" stroke="${lineSVGData.stroke.color}" stroke-width="${lineSVGData.stroke.width}"/>
        </svg>`,
    },
  ];

  const handleLineWidthChange = (newSize: string) => {
    if (Number(newSize) < 1 || Number(newSize) > 10) return;
    updatePDFObject<LineSVGData>(lineSVGData.id, {
      stroke: { color: lineSVGData.stroke.color, width: Number(newSize) },
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleLineCapChange = (newCapStyle: any) => {
    updatePDFObject<LineSVGData>(lineSVGData.id, {
      ...lineSVGData,
      caps: newCapStyle,
    });
  };

  return (
    <div className='flex items-center py-5' data-testid='textbox-tools'>
      <Dropdown
        name='Line Cap'
        options={lineCapStyles}
        onSelect={handleLineCapChange}
        selectedValue={lineSVGData.caps}
      />
      <Tooltip placement='top' content='Line Width'>
        <div className='px-2'>
          <input
            aria-label='Line Width'
            type='number'
            className='w-16 h-9 text-center border rounded-md text-sm'
            value={lineSVGData.stroke.width}
            onChange={e => handleLineWidthChange(e.target.value)}
            min={1}
            max={10}
          />
        </div>
      </Tooltip>

      <Tooltip placement='top' content='Line Color'>
        <input
          type='color'
          className='px-2 h-8 w-14 block bg-white cursor-pointer disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700'
          id='hs-color-input'
          value={lineSVGData.stroke.color}
          title='Choose your color'
          onChange={e =>
            updatePDFObject<LineSVGData>(lineSVGData.id, {
              stroke: { color: e.target.value, width: lineSVGData.stroke.width },
            })
          }
        ></input>
      </Tooltip>
    </div>
  );
};

export default LineSVGTools;
