import ObjectAlignment from './ObjectAlignment';
import { TableData } from '../../../types/PDFObjects';
import {
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatLineSpacing,
  MdFormatSize,
  MdFormatUnderlined,
} from 'react-icons/md';
import { IconButton } from '@material-tailwind/react';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import { FontFamilies } from '../../../types/FontFamilies';
import usePDFStore from '../../../stores/usePDFStore';

export interface TableToolsPanelProps {
  tableData: TableData;
}

const TableToolsPanel = ({ tableData }: TableToolsPanelProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const { alignObject, addRow, removeRow, addColumn, removeColumn } = usePDFObjectsManager();

  const handlePositionChange = (axis: 'x' | 'y', value: string) => {
    const newPosition = { ...tableData.position, [axis]: parseFloat(value) || 0 };
    updatePDFObject(tableData.id, { position: newPosition });
  };

  const handleSizeChange = (axis: 'width' | 'height', value: string) => {
    const newSize = { ...tableData.size, [axis]: parseFloat(value) };
    updatePDFObject(tableData.id, { size: newSize });
  };

  const handleFontFamilyChange = (value: string) => {
    updatePDFObject<TableData>(tableData.id, {
      headersStyle: {
        ...tableData.headersStyle,
        fontFamily: value as FontFamilies,
      },
      rowsStyle: {
        ...tableData.rowsStyle,
        fontFamily: value as FontFamilies,
      },
    });
  };
  const handleFontSizeChange = (type: 'headers' | 'rows', value: string) => {
    const newSize = parseFloat(value);
    if (type === 'headers') {
      updatePDFObject<TableData>(tableData.id, {
        headersStyle: {
          ...tableData.headersStyle,
          fontSize: newSize,
        },
      });
    } else {
      updatePDFObject<TableData>(tableData.id, {
        rowsStyle: {
          ...tableData.rowsStyle,
          fontSize: newSize,
        },
      });
    }
  };

  const handleHeaderHeightChange = (value: string) => {
    const newSize = parseFloat(value) || 0;
    updatePDFObject<TableData>(tableData.id, { headersHeight: newSize });
  };

  const handleMinRowHeightChange = (value: string) => {
    const newSize = parseFloat(value);
    updatePDFObject<TableData>(tableData.id, {
      rowsStyle: { ...tableData.rowsStyle, minHeight: newSize },
    });
  };

  const handleRowsChange = (value: string) => {
    if (tableData.rows.length < parseInt(value)) {
      addRow(tableData);
    } else {
      removeRow(tableData);
    }
  };

  const handleColumnsChange = (value: string) => {
    if (tableData.headers.length < parseInt(value)) {
      addColumn(tableData);
    } else {
      removeColumn(tableData);
    }
  };

  const toggleBold = (type: 'headers' | 'rows') => () => {
    const newWeight = tableData[`${type}Style`].fontWeight === 'bold' ? 'normal' : 'bold';
    updatePDFObject<TableData>(tableData.id, {
      [`${type}Style`]: {
        ...tableData[`${type}Style`],
        fontWeight: newWeight,
      },
    });
  };

  const toggleItalic = (type: 'headers' | 'rows') => () => {
    const newStyle = tableData[`${type}Style`].fontStyle === 'italic' ? 'normal' : 'italic';
    updatePDFObject<TableData>(tableData.id, {
      [`${type}Style`]: {
        ...tableData[`${type}Style`],
        fontStyle: newStyle,
      },
    });
  };

  const toggleUnderline = (type: 'headers' | 'rows') => () => {
    updatePDFObject<TableData>(tableData.id, {
      [`${type}Style`]: {
        ...tableData[`${type}Style`],
        underlined: !tableData[`${type}Style`].underlined,
      },
    });
  };
  const handleLineSpacingChange = (type: 'headers' | 'rows', value: string) => {
    const newSize = parseFloat(value);
    updatePDFObject<TableData>(tableData.id, {
      [`${type}Style`]: {
        ...tableData[`${type}Style`],
        lineSpacing: newSize,
      },
    });
  };

  const handleAlign = (type: 'headers' | 'rows', align: 'left' | 'center' | 'right') => () => {
    updatePDFObject<TableData>(tableData.id, {
      [`${type}Style`]: {
        ...tableData[`${type}Style`],
        align,
      },
    });
  };
  return (
    <div className='flex flex-wrap justify-center gap-4 p-4' style={{ maxWidth: '32rem' }}>
      <ObjectAlignment pdfObject={tableData} alignObject={alignObject} />
      <div className='flex items-center space-x-2 mx-5'>
        {/* X Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            X
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={tableData.position.x}
            onChange={e => handlePositionChange('x', e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Y
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={tableData.position.y}
            onChange={e => handlePositionChange('y', e.target.value)}
          />
        </div>
      </div>
      <div className='flex items-center space-x-2 mx-5'>
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
            Rows
          </span>
          <input
            type='number'
            onKeyDown={e => e.preventDefault()}
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={tableData.rows.length}
            onChange={e => handleRowsChange(e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
            Cols
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={tableData.headers.length}
            onChange={e => handleColumnsChange(e.target.value)}
          />
        </div>
      </div>
      <div className='flex flex-wrap mx-2'>
        <div className='w-full px-2'>
          <div className='mt-1 relative rounded-md'>
            <div className='mt-0 flex flex-col rounded shadow-sm items-center'>
              <div className='flex-row'>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-900 sm:text-xs'>
                  Table Width
                </span>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
                  {tableData.size.width}px
                </span>
              </div>
            </div>
            <input
              type='range'
              className='w-full h-2 bg-gray-200 rounded cursor-pointer appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500'
              min='10'
              max='900'
              value={tableData.size.width}
              onMouseUp={() => console.log('mouse up')}
              onMouseLeave={() => console.log('mouse leave')}
              onChange={e => handleSizeChange('width', e.target.value)}
              step='1'
            />
            <div className='flex justify-between text-xs text-gray-500 mt-2'>
              <span>Min</span>
              <span>Max</span>
            </div>
          </div>
        </div>
        <div className='w-full px-2'>
          <div className='mt-1 relative rounded-md'>
            <div className='mt-0 flex flex-col rounded shadow-sm items-center'>
              <div className='flex-row'>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-900 sm:text-xs'>
                  Header Height
                </span>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
                  {tableData.headersHeight}px
                </span>
              </div>
            </div>
            <input
              type='range'
              className='w-full h-2 bg-gray-200 rounded cursor-pointer appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500'
              min='10'
              max='900'
              value={tableData.headersHeight}
              onChange={e => handleHeaderHeightChange(e.target.value)}
              step='1'
            />
            <div className='flex justify-between text-xs text-gray-500 mt-2'>
              <span>Min</span>
              <span>Max</span>
            </div>
          </div>
        </div>
        <div className='w-full px-2'>
          <div className='mt-1 relative rounded-md'>
            <div className='mt-0 flex flex-col rounded shadow-sm items-center'>
              <div className='flex-row'>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-900 sm:text-xs'>
                  Min Row Height
                </span>
                <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
                  {tableData.rowsStyle.minHeight}px
                </span>
              </div>
            </div>
            <input
              type='range'
              className='w-full h-2 bg-gray-200 rounded cursor-pointer appearance-none focus:outline-none focus:ring-2 focus:ring-blue-500'
              min='10'
              max='900'
              value={tableData.rowsStyle.minHeight}
              onChange={e => handleMinRowHeightChange(e.target.value)}
              step='1'
            />
            <div className='flex justify-between text-xs text-gray-500 mt-2'>
              <span>Min</span>
              <span>Max</span>
            </div>
          </div>
        </div>
        <div className='flex text-center space-x-2 mx-5 my-4'>
          <select
            className='w-30 h-8 text-center border rounded-md text-sm py-1'
            value={tableData.headersStyle.fontFamily}
            onChange={e => handleFontFamilyChange(e.target.value)}
          >
            {Object.entries(FontFamilies).map(([key, value]) => (
              <option
                key={key}
                value={value}
                style={{
                  fontFamily: value,
                }}
              >
                {value}
              </option>
            ))}
          </select>
        </div>
        <div className='flex items-center text-center text-sm w-full mb-2'>
          <span className='w-full text-center border'>Header Style</span>
        </div>
        <div className='flex items-center space-x-1 mx-2 my-2'>
          <div className='mt-0 flex rounded shadow-sm w-full'>
            <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
              <MdFormatSize size={18} />
            </span>
            <input
              type='number'
              className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
              value={tableData.headersStyle.fontSize}
              onChange={e => handleFontSizeChange('headers', e.target.value)}
            />
          </div>
          <div className='flex items-center space-x-2 mx-5'>
            <IconButton
              variant='text'
              color={tableData.headersStyle.fontWeight === 'bold' ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleBold('headers')}
            >
              <MdFormatBold size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.headersStyle.fontStyle === 'italic' ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleItalic('headers')}
            >
              <MdFormatItalic size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.headersStyle.underlined ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleUnderline('headers')}
            >
              <MdFormatUnderlined size={24} />
            </IconButton>
          </div>
        </div>

        <div className='flex items-center space-x-1 mx-2'>
          {/* X Position Input */}
          <div className='mt-0 flex rounded shadow-sm w-full'>
            <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
              <MdFormatLineSpacing size={18} />
            </span>
            <input
              type='number'
              className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
              value={tableData.headersStyle.lineSpacing}
              min={1.2}
              onChange={e => handleLineSpacingChange('headers', e.target.value)}
            />
          </div>
          <div className='flex items-center space-x-2 mx-5'>
            <IconButton
              variant='text'
              color={tableData.headersStyle.align === 'left' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('headers', 'left')}
            >
              <MdFormatAlignLeft size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.headersStyle.align === 'center' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('headers', 'center')}
            >
              <MdFormatAlignCenter size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.headersStyle.align === 'right' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('headers', 'right')}
            >
              <MdFormatAlignRight size={24} />
            </IconButton>
          </div>
        </div>
        <div className='flex items-center text-center text- w-full mb-2'>
          <span className='w-full text-center border mt-1'>Rows Style</span>
        </div>
        <div className='flex items-center space-x-1 mx-2 my-2'>
          {/* X Position Input */}
          <div className='mt-0 flex rounded shadow-sm w-full'>
            <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
              <MdFormatSize size={18} />
            </span>
            <input
              type='number'
              className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
              value={tableData.rowsStyle.fontSize}
              onChange={e => handleFontSizeChange('rows', e.target.value)}
            />
          </div>
          <div className='flex items-center space-x-2 mx-5'>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.fontWeight === 'bold' ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleBold('rows')}
            >
              <MdFormatBold size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.fontStyle === 'italic' ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleItalic('rows')}
            >
              <MdFormatItalic size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.underlined ? 'blue' : 'gray'}
              size='sm'
              onClick={toggleUnderline('rows')}
            >
              <MdFormatUnderlined size={24} />
            </IconButton>
          </div>
        </div>

        <div className='flex items-center space-x-1 mx-2'>
          {/* X Position Input */}
          <div className='mt-0 flex rounded shadow-sm w-full'>
            <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-1 text-gray-500 sm:text-xs'>
              <MdFormatLineSpacing size={18} />
            </span>
            <input
              type='number'
              className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
              value={tableData.rowsStyle.lineSpacing}
              min={1.2}
              max={2.0}
              step={0.1}
              onChange={e => handleLineSpacingChange('rows', e.target.value)}
            />
          </div>
          <div className='flex items-center space-x-2 mx-5'>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.align === 'left' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('rows', 'left')}
            >
              <MdFormatAlignLeft size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.align === 'center' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('rows', 'center')}
            >
              <MdFormatAlignCenter size={24} />
            </IconButton>
            <IconButton
              variant='text'
              color={tableData.rowsStyle.align === 'right' ? 'blue' : 'gray'}
              size='sm'
              onClick={handleAlign('rows', 'right')}
            >
              <MdFormatAlignRight size={24} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableToolsPanel;
