interface AuthContentCardProps {
  children: React.ReactNode;
}

const AuthContentCard = ({ children }: AuthContentCardProps) => {
  return (
    <div className='px-8 py-6 mx-auto w-full max-w-sm bg-white rounded-lg shadow-lg'>
      {children}
    </div>
  );
};

const Logo = () => {
  return (
    <div className='flex flex-row justify-center'>
      <div className='mb-0 text-center w-[100px] h-[100px]'>
        {/* 
    Quill Pen SVG Vector 3
    Free Download Quill Pen 3 SVG vector file in monocolor and multicolor type for Sketch and Figma from Quill Pen 3 Vectors svg vector collection. Quill Pen 3 Vectors SVG vector illustration graphic art design format.
    https://www.svgrepo.com/svg/431208/quill-pen
    COLLECTION: Mingcute Tiny Bold Line Icons
    LICENSE: Apache License
    AUTHOR: Richard9394*/}
        <img src='/logo.svg' alt='Descriptive Alt Text' className='mx-auto' />
      </div>
    </div>
  );
};
Logo.displayName = 'AuthContentCard.Logo';
AuthContentCard.Logo = Logo;

const Title = ({ children }: { children: React.ReactNode }) => {
  return <h1 className='text-3xl font-bold text-[#09244B] text-center'>{children}</h1>;
};
Title.displayName = 'AuthContentCard.Title';
AuthContentCard.Title = Title;

export default AuthContentCard;
