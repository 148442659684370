import { Template } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useGetTemplates = () => {
  const { makeCall, response, isLoading, error } = useApiCall<Template[]>();
  const { templateService } = useAPIContext();

  const getTemplates = async (jwt: string) => {
    await makeCall(signal => {
      return templateService.get(jwt, signal);
    });
  };

  return { getTemplates, response, isLoading, error };
};
