import {
  disableTextSelection,
  enableTextSelection,
  setTextEditCursorToFront,
} from '../../../../helpers/domHelpers';

import usePDFStore from '../../../../stores/usePDFStore';
import { FontFamilyMarginTopScale } from '../../../../types/FontFamilies';
import { TableData } from '../../../../types/PDFObjects';

import { MouseEvent, useRef } from 'react';

interface TableHeaderProps {
  index: number;
  tableData: TableData;
  tableRef: React.RefObject<HTMLTableElement>;
}

const TableHeader = ({ tableRef, index, tableData }: TableHeaderProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const cellRef = useRef<HTMLTableCellElement>(null);
  const value = useRef<string>(tableData.headers[index].content);

  const onContentChange = (content: string, index: number) => {
    const updatedRows = tableData.headers.map((row, i) => {
      if (i === index) {
        return {
          ...row,
          content: content,
        };
      } else {
        return row;
      }
    });

    updatePDFObject<TableData>(tableData.id, {
      ...tableData,
      headers: updatedRows,
    });
  };

  const handleOnInput = (e: React.FormEvent<HTMLDivElement>, index: number) => {
    const updatedContent = e.currentTarget.innerText.replace(/\n{3,}/g, '\n\n');
    onContentChange(updatedContent, index);
  };

  const startResize = (index: number, event: MouseEvent) => {
    if (index === tableData.headers.length - 1) return;
    const cell = cellRef.current;
    if (!cell) return;
    // Determine the mouse position relative to the cell
    const { right } = cell.getBoundingClientRect();
    const threshold = 10; // Distance from edge to consider for resizing
    const nearRightEdge = right - event.clientX < threshold;

    if (!nearRightEdge) return; // Do nothing if not near an edge

    event.stopPropagation(); // Prevent event bubbling
    disableTextSelection(); // Disable text selection during drag
    const startX = event.clientX;
    const startWidth = tableData.headerWidths[index];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleMouseMove = (e: any) => {
      e.preventDefault();
      const movementX = e.clientX - startX; // Calculate the horizontal movement
      const currentWidth = Math.max(startWidth + movementX, 20); // Prevent the column from being too narrow

      // Calculate the new width for the next column, if there is one
      if (index < tableData.headers.length - 1) {
        const nextColumnKey = index + 1;
        const nextColumnOriginalWidth = tableData.headerWidths[nextColumnKey];
        const nextColumnNewWidth = Math.max(nextColumnOriginalWidth - movementX, 20); // Also prevent this column from being too narrow
        const updatedHeaders = tableData.headerWidths.map((h, i) => {
          if (i === index) {
            return currentWidth;
          } else if (i === nextColumnKey) {
            return nextColumnNewWidth;
          } else {
            return h;
          }
        });
        //check total width does not exceed table width
        const totalWidth = updatedHeaders.reduce((acc, curr) => acc + curr, 0);
        if (totalWidth <= tableData.size.width && currentWidth > 20 && nextColumnNewWidth > 20) {
          if (tableRef.current) {
            const height = tableRef.current.offsetHeight;
            const headerHeight = tableRef.current.rows[0].offsetHeight;
            updatePDFObject(tableData.id, {
              ...tableData,
              size: { ...tableData.size, height },
              headersHeight: headerHeight,
              headerWidths: updatedHeaders,
            });
          }
        }
      }
    };

    const handleMouseUp = () => {
      enableTextSelection(); // Re-enable text selection after drag
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const updateCursor = (event: MouseEvent) => {
    const cell = cellRef.current;
    if (!cell) return;

    const { right } = cell.getBoundingClientRect();
    const threshold = 10; // Pixels from edge to trigger resize cursor
    const nearEdge = right - event.clientX < threshold;
    cell.style.cursor = nearEdge && index !== tableData.headers.length - 1 ? 'col-resize' : 'grab';
  };

  return (
    <td
      key={index}
      className='border border-black'
      style={{
        width: `${tableData.headerWidths[index]}px`,
        maxWidth: `${tableData.headerWidths[index]}px`,
        wordWrap: 'break-word',
        height: `${tableData.headersHeight}px`,
        maxHeight: `${tableData.headersHeight}px`,
        wordBreak: 'break-word',
        overflow: 'clip',
        padding: '0px',
        boxSizing: 'border-box', // Ensure padding does not affect the size
        position: 'relative', // Ensure child absolute positioning is relative to this div
      }}
      onMouseDown={e => startResize(index, e)}
      onMouseMove={updateCursor}
      onDoubleClick={() => {
        cellRef.current!.focus();
        setTextEditCursorToFront(cellRef!.current!);
      }}
    >
      <div
        id='wrap'
        ref={cellRef}
        onInput={e => handleOnInput(e, index)}
        dangerouslySetInnerHTML={{
          __html: value.current
            .split('\n')
            .map(line => `<div>${line}</div>`)
            .join(''),
        }}
        contentEditable
        suppressContentEditableWarning={true}
        style={{
          outline: 'none',
          height: '100%',
          width: '100%',
          marginTop: `-${tableData.headersStyle.fontSize * FontFamilyMarginTopScale[tableData.headersStyle.fontFamily] - ((tableData.headersStyle.lineSpacing - 1) * tableData.headersStyle.fontSize) / 2}px`,
        }}
      ></div>
    </td>
  );
};

export default TableHeader;
