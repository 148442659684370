import React, { useState } from 'react';
import {
  MdOutlineEmojiFlags,
  MdOutlineQrCode,
  MdOutlineShapeLine,
  MdOutlineTableView,
  MdOutlineTextFields,
} from 'react-icons/md';
import { IoCloudUploadOutline } from 'react-icons/io5';

import { IconButton } from '@material-tailwind/react';
import CollectionGallery from './CollectionGallery';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import ShapesGallery from './ShapesGallery';
import Uploads from './Uploads';

const LeftToolbar: React.FC = () => {
  const [showGalleryToolbar, setShowGalleryToolbar] = useState(false);
  const [showShapeToolbar, setShowShapeToolbar] = useState(false);
  const [showUploads, setShowUploads] = useState(false);
  const qrCode = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" 
  shape-rendering="crispEdges"><path stroke="#000000" d="M4 4.5h7m2 0h1m3 0h1m4 
  0h7M4 5.5h1m5 0h1m2 0h1m1 0h5m2 0h1m5 0h1M4 6.5h1m1 0h3m1 0h1m1 0h4m4 0h1m1 0h1m1 0h3m1
   0h1M4 7.5h1m1 0h3m1 0h1m1 0h3m1 0h4m2 0h1m1 0h3m1 0h1M4 8.5h1m1 0h3m1 0h1m1 0h3m5 0h1m1 
   0h1m1 0h3m1 0h1M4 9.5h1m5 0h1m1 0h1m1 0h1m1 0h2m1 0h1m2 0h1m5 0h1M4 10.5h7m1 0h1m1 0h1m1 
   0h1m1 0h1m1 0h1m1 0h7M12 11.5h2m1 0h1m2 0h3M4 12.5h1m1 0h5m4 0h5m2 0h5M4 13.5h1m2 0h2m2 
   0h1m3 0h3m2 0h1m2 0h1m3 0h1M5 14.5h1m2 0h5m3 0h4m2 0h4m1 0h2M5 15.5h5m1 0h2m2 0h2m1 0h2m1 
   0h1m6 0h1M4 16.5h4m2 0h1m2 0h12m1 0h3M4 17.5h1m2 0h1m1 0h1m1 0h2m4 0h1m2 0h2m1 0h1m1 0h1m1 
   0h1M4 18.5h1m1 0h2m1 0h4m2 0h2m1 0h3m1 0h4m1 0h2M4 19.5h1m3 0h2m3 0h1m1 0h1m2 0h3m2 0h2m3 0h1M4 
   20.5h1m1 0h1m3 0h4m1 0h1m3 0h6m1 0h1M12 21.5h3m1 0h2m1 0h2m3 0h2M4 22.5h7m2 0h1m3 0h2m1 0h1m1 0h1m1 
   0h1m1 0h3M4 23.5h1m5 0h1m1 0h1m3 0h2m2 0h1m3 0h2m1 0h2M4 24.5h1m1 0h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h7m1 
   0h3M4 25.5h1m1 0h3m1 0h1m1 0h1m6 0h4m1 0h5M4 26.5h1m1 0h3m1 0h1m1 0h1m1 0h3m2 0h1m5 0h2m1 0h1M4 27.5h1m5 
   0h1m2 0h1m1 0h1m2 0h3m2 0h3m2 0h1M4 28.5h7m1 0h4m5 0h8"/></svg>`;
  const { addTextBox, addTable, addQRCode } = usePDFObjectsManager();

  const toggleCollectionGallery = () => {
    setShowGalleryToolbar(!showGalleryToolbar);
    setShowShapeToolbar(false);
    setShowUploads(false);
  };

  const toggleShapeToolbar = () => {
    setShowShapeToolbar(!showShapeToolbar);
    setShowGalleryToolbar(false);
    setShowUploads(false);
  };

  const toggleUploads = () => {
    setShowUploads(!showUploads);
    setShowGalleryToolbar(false);
    setShowShapeToolbar(false);
  };

  return (
    <>
      <div
        data-testid='side-toolbar'
        className='bg-gray-900 flex col h-full flex flex-col items-center py-5'
        style={{
          minWidth: '64px',
          maxWidth: '64px',
          height: 'calc(100vh - 64px)', // Adjust based on the actual navbar height
          overflowY: 'auto', // Adds scrolling to the toolbar if its content overflows
        }}
      >
        {/* Text Tool */}
        <div className='flex flex-col items-center mb-2 w-full text-center text-white'>
          {' '}
          {/* Ensure full width and text centering */}
          <IconButton
            variant='text'
            color='white'
            onClick={addTextBox}
            className='mx-auto jr-text-field'
          >
            {' '}
            {/* Center icon button */}
            <MdOutlineTextFields size={24} />
          </IconButton>
          <span className='text-xs normal-case'>Text</span>
        </div>
        {/* Table Tool */}
        <div className='flex flex-col items-center mb-2 w-full text-center text-white'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton variant='text' color='white' onClick={addTable} className='mx-auto jr-table'>
            <MdOutlineTableView size={24} />
          </IconButton>
          <span className='text-xs normal-case'>Table</span>
        </div>

        {/* Shape Tool */}
        <div
          className={`flex flex-col items-center mb-2 w-full text-center text-white ${showGalleryToolbar ? 'bg-gray-800' : ''}`}
        >
          <div
            onClick={toggleCollectionGallery}
            className='flex flex-col justify-center mx-auto jr-table p-2'
            style={{
              cursor: 'pointer',
            }}
          >
            <div className='flex justify-center'>
              <MdOutlineEmojiFlags size={24} />
            </div>
            <span className='text-xs normal-case'>Icons</span>
          </div>
        </div>
        <div
          className={`flex flex-col items-center mb-2 w-full text-center text-white ${showShapeToolbar ? 'bg-gray-800' : ''}`}
        >
          <div
            onClick={toggleShapeToolbar}
            className='flex flex-col justify-center mx-auto jr-table p-2'
            style={{
              cursor: 'pointer',
            }}
          >
            <div className='flex justify-center'>
              <MdOutlineShapeLine size={24} />
            </div>
            <span className='text-xs normal-case'>Shapes</span>
          </div>
        </div>
        <div className='flex flex-col items-center mb-2 w-full text-center text-white'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            color='white'
            onClick={() => addQRCode(qrCode)}
            className='mx-auto jr-table'
          >
            <MdOutlineQrCode size={24} />
          </IconButton>
          <span className='text-xs normal-case'>QR Code</span>
        </div>
        <div
          className={`flex flex-col items-center mb-2 w-full text-center text-white ${showUploads ? 'bg-gray-800' : ''}`}
        >
          {' '}
          {/* Same adjustments for other buttons */}
          <div
            onClick={toggleUploads}
            className='flex flex-col justify-center mx-auto jr-table p-2'
            style={{
              cursor: 'pointer',
            }}
          >
            <div className='flex justify-center'>
              <IoCloudUploadOutline size={24} />
            </div>
            <span className='text-xs normal-case'>Uploads</span>
          </div>
        </div>
      </div>
      {showUploads && <Uploads toggleCollectionGallery={toggleUploads} />}
      {showShapeToolbar ? <ShapesGallery toggleCollectionGallery={toggleShapeToolbar} /> : null}
      {showGalleryToolbar ? (
        <CollectionGallery toggleCollectionGallery={toggleCollectionGallery} />
      ) : null}
    </>
  );
};

export default LeftToolbar;
