import { useCallback, useEffect, useRef, useState } from 'react';
import usePDFStore from '../../stores/usePDFStore';
import { Line } from '../../types/Shapes';

interface UseDraggableLineProps {
  line: Line;
  updateLine: (line: Line) => void;
  onDragEnd?: (line: Line) => void;
}

const useDraggableLine = ({ line, updateLine, onDragEnd }: UseDraggableLineProps) => {
  const gridSnap = usePDFStore(state => state.settings.gridSnap);
  const [dragging, setDragging] = useState(false);
  const [hasMoved, setHasMoved] = useState(false); // New state to track movement
  const startLineRef = useRef<Line>({ x1: 0, y1: 0, x2: 0, y2: 0 });
  const lastUpdate = useRef(Date.now());
  const scale = usePDFStore(state => state.settings.scale);
  const setIsDragging = usePDFStore(state => state.setIsDragging);

  const calculateSnappedPosition = useCallback(
    (line: Line) => {
      const xDiff = line.x2 - line.x1;
      const yDiff = line.y2 - line.y1;
      const x1 = Math.round(line.x1 / gridSnap.snapInterval) * gridSnap.snapInterval;
      const y1 = Math.round(line.y1 / gridSnap.snapInterval) * gridSnap.snapInterval;
      const x2 = x1 + xDiff;
      const y2 = y1 + yDiff;
      return {
        x1: x1,
        y1: y1,
        x2: x2,
        y2: y2,
      };
    },
    [gridSnap.snapInterval]
  );
  const startDrag = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      startLineRef.current = line;
      setDragging(true);
      setHasMoved(false);
      setIsDragging(true);
    },
    [line]
  );

  const onDrag = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      if (!hasMoved) setHasMoved(true);
      if (!dragging) return;

      const xChange = e.movementX / scale;
      const yChange = e.movementY / scale;
      const newLine = {
        x1: startLineRef.current.x1 + xChange,
        y1: startLineRef.current.y1 + yChange,
        x2: startLineRef.current.x2 + xChange,
        y2: startLineRef.current.y2 + yChange,
      };

      //   startPosRef.current = newPosition;
      startLineRef.current = newLine;

      if (Date.now() - lastUpdate.current < 16) return;
      lastUpdate.current = Date.now();
      updateLine(calculateSnappedPosition(newLine));
    },
    [dragging, updateLine, hasMoved, calculateSnappedPosition, scale]
  );

  const endDrag = useCallback(() => {
    setDragging(false);
    setIsDragging(false);
    if (onDragEnd) {
      const newLine = calculateSnappedPosition(startLineRef.current);
      onDragEnd(newLine);
    }
  }, [onDragEnd, calculateSnappedPosition]);

  useEffect(() => {
    if (dragging) {
      const handleMouseMove = (e: MouseEvent) => onDrag(e);
      document.addEventListener('mousemove', handleMouseMove);

      const handleMouseUp = () => {
        endDrag();
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mouseup', handleMouseUp);

      return () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [dragging, onDrag, endDrag]);

  return { dragging, startDrag, onDrag, endDrag };
};

export default useDraggableLine;
