import { Template } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useUpdateTemplateContent = () => {
  const { makeCall, response, isLoading, error } = useApiCall<Template>();
  const { templateService } = useAPIContext();

  const updateTemplateContent = async (templateId: string, content: string, jwt: string) => {
    await makeCall(signal => {
      return templateService.updateContent(templateId, content, jwt, signal);
    });
  };

  return { updateTemplateContent, response, isLoading, error };
};
