import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useDeleteApiKey = () => {
  const { makeCall, response, isLoading, error } = useApiCall<boolean>();
  const { apiKeyService } = useAPIContext();

  const deleteApiKey = async (token: string, apiKeyId: string) => {
    await makeCall(signal => {
      return apiKeyService.deleteApiKey(token, apiKeyId, signal);
    });
  };

  return { deleteApiKey, response, isLoading, error };
};
