import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPasswordRequest } from '../../../hooks/api/users/useResetPasswordRequest';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import InputText from '../../../components/ui/InputText';
import FlatButton from '../../../components/ui/FlatButton';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { resetPasswordRequest, response, error, isLoading } = useResetPasswordRequest();
  const [isSuccess, setIsSuccess] = useState(false);

  const { setError } = useAPIErrorContext();

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  useEffect(() => {
    if (response) {
      setIsSuccess(true);
    }
  }, [response, navigate]);

  const handleSubmit = async () => {
    resetPasswordRequest(email);
  };

  if (isSuccess) {
    // New success message display block
    return (
      <AuthPageContainer>
        <AuthContentCard>
          <AuthContentCard.Logo />
          <div className='mb-6'>
            <div className='text-3xl text-center text-indigo-900'>Request Submitted</div>
            <p className='text-sm text-center text-gray-400 pt-5'>
              <a
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/login')}
                className='text-indigo-600 focus:outline-none focus:underline hover:underline'
              >
                Back To Login
              </a>
            </p>
          </div>
        </AuthContentCard>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      <AuthContentCard>
        <AuthContentCard.Logo />
        <AuthContentCard.Title>Reset Password</AuthContentCard.Title>
        <p className='text-center text-md text-gray-600 py-5'>
          Enter your email to receive a reset link. If an account exists we will send you an email
          to reset your password.
        </p>

        <div className='mb-5'>
          <label htmlFor='email' className='block mb-2 text-sm text-gray-600'>
            Email
          </label>
          <InputText
            handleChange={setEmail}
            value={email}
            placeholder='Enter your email'
            type='email'
            id='email'
          />
        </div>
        <div className='mb-6'>
          <FlatButton text='Submit' onClick={handleSubmit} disabled={isLoading} />
        </div>
        <p className='text-sm text-center text-gray-400'>
          Remembered your password?{' '}
          <a
            onClick={() => navigate('/login')}
            className='text-indigo-600 focus:outline-none focus:underline hover:underline'
          >
            Login
          </a>
        </p>
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default ResetPasswordRequest;
