import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useDeleteUsers = () => {
  const { makeCall, response, isLoading, error } = useApiCall<boolean>();
  const { userService } = useAPIContext();

  const deleteUsers = async (userIds: string[], token: string) => {
    await makeCall(signal => {
      return userService.deleteUsers(userIds, token, signal);
    });
  };

  return { deleteUsers, response, isLoading, error };
};
