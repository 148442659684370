import { ApiKeyDTO } from '../../../api/apiKey/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useReactivateApiKey = () => {
  const { makeCall, response, isLoading, error } = useApiCall<ApiKeyDTO>();
  const { apiKeyService } = useAPIContext();

  const reactivateApiKey = async (token: string, apiKeyId: string) => {
    await makeCall(signal => {
      return apiKeyService.reactivateApiKey(token, apiKeyId, signal);
    });
  };

  return { reactivateApiKey, response, isLoading, error };
};
