import React from 'react';

interface PlayerCursorProps {
  x: number;
  y: number;
  playerName: string;
  scale: number;
}

const PlayerCursor = ({ x, y, playerName, scale }: PlayerCursorProps) => {
  const cursorStyle: React.CSSProperties = {
    position: 'absolute',
    pointerEvents: 'none',
    zIndex: 1000,
    transform: `translate(${x}px, ${y}px) scale(${1 / scale})`,
    transition: 'transform 0.1s ease-in-out',
  };

  const iconStyle: React.CSSProperties = {
    background: 'linear-gradient(145deg, #b3b3ff, #8080ff)',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    boxShadow: '0px 0px 8px rgba(1,0,0,0.5)',
    outline: '1px solid #000',
  };

  const nameLabelStyle: React.CSSProperties = {
    background: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
    color: '#fff',
    textShadow: '1px 1px 2px black',
    fontSize: '14px',
    fontFamily: '"Roboto", sans-serif',
    fontWeight: 500,
    padding: '2px 4px', // Padding around text
    borderRadius: '4px', // Rounded corners for the label
    position: 'absolute',
    top: '20px',
    left: '0%',
    whiteSpace: 'nowrap',
  };

  return (
    <div style={cursorStyle}>
      <div className='cursor-icon' style={iconStyle}></div>
      <div style={nameLabelStyle}>{playerName}</div>{' '}
      {/* Changed from <small> to <div> for better style control */}
    </div>
  );
};

export default PlayerCursor;
