import api from '../api';
import { handleAxiosError } from '../helpers';

export interface IQRCodesService {
  generate(
    jwt: string,
    text: string,
    signal: AbortSignal
  ): Promise<{
    qrCode: string;
    text: string;
  }>;
}

export class QRCodesService implements IQRCodesService {
  private static instance: QRCodesService;

  public static getInstance(): QRCodesService {
    if (!QRCodesService.instance) {
      QRCodesService.instance = new QRCodesService();
    }
    return QRCodesService.instance;
  }
  public async generate(
    jwt: string,
    text: string,
    signal: AbortSignal
  ): Promise<{
    qrCode: string;
    text: string;
  }> {
    try {
      const response = await api.post(
        `qr-codes`,
        {
          text,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${jwt}`,
          },
          signal,
        }
      );

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
