enum PDFObjectType {
  TEXTBOX = 'TextBox',
  SVG = 'SVG',
  TABLE = 'Table',
  LINE_SVG = 'LineSVG',
  RECT_SVG = 'RectSVG',
  ICON_SVG = 'IconSVG',
  QR_CODE = 'QRCode',
  IMAGE = 'Image',
}

enum Alignment {
  LEFT = 'left',
  RIGHT = 'right',
  CENTER = 'center',
  TOP = 'top',
  VCENTER = 'vCenter',
  BOTTOM = 'bottom',
  HCENTER = 'hCenter',
}

export { PDFObjectType, Alignment };
