import api from '../api';
import { handleAxiosError } from '../helpers';
import { AnalyticsEvent } from './types';

export interface IAnalyticsService {
  getAll(jwt: string, signal: AbortSignal): Promise<AnalyticsEvent[]>;
}

export class AnalyticsService implements IAnalyticsService {
  private static instance: AnalyticsService;

  public static getInstance(): AnalyticsService {
    if (!AnalyticsService.instance) {
      AnalyticsService.instance = new AnalyticsService();
    }
    return AnalyticsService.instance;
  }

  public async getAll(jwt: string, signal: AbortSignal): Promise<AnalyticsEvent[]> {
    try {
      const response = await api.get(`/analytics/events`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
