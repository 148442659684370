import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Loader from '../../../components/ui/Loaders';
import { useConfirmEmail } from '../../../hooks/api/users/useConfirmEmail';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import FlatButton from '../../../components/ui/FlatButton';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';

const RegistrationConfirmation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { confirmEmail, isLoading, error, confirmEmailResponse } = useConfirmEmail();
  const { setError } = useAPIErrorContext();

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    if (!token) {
      navigate('/login');
      return;
    }
    confirmEmail(token);
  }, []);

  const redirectToLogin = () => {
    navigate('/login');
  };

  return (
    <AuthPageContainer>
      <AuthContentCard>
        <div className='mb-6 text-center'>
          <AuthContentCard.Logo />
          <AuthContentCard.Title>Email Confirmation</AuthContentCard.Title>
          {confirmEmailResponse === null ? (
            <>
              <Loader /> {/* Display a loader component */}
              <p className='text-md text-gray-500'>Confirming email...</p>
            </>
          ) : (
            <p className='text-md text-gray-500'>
              Email Confirmed! Welcome, login to start using ScribePDF.
            </p>
          )}
        </div>
        {!isLoading && (
          <div className='mb-6'>
            <FlatButton onClick={redirectToLogin} text='Login' />
          </div>
        )}
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default RegistrationConfirmation;
