interface MultiSelectPreviewBoxProps {
  isSelecting: boolean;
  startPosition: { x: number; y: number };
  endPosition: { x: number; y: number };
}

const MultiSelectPreviewBox = ({
  isSelecting,
  startPosition,
  endPosition,
}: MultiSelectPreviewBoxProps) => {
  const left = startPosition.x > endPosition.x ? endPosition.x : startPosition.x;
  const top = startPosition.y > endPosition.y ? endPosition.y : startPosition.y;
  const width = Math.abs(endPosition.x - startPosition.x);
  const height = Math.abs(endPosition.y - startPosition.y);

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 9999,
        border: '2px solid rgba(0, 123, 255, 0.5)',
        backgroundColor: 'rgba(0, 123, 255, 0.2)',
        display: isSelecting ? 'block' : 'none',
        transform: `translate(${left}px, ${top}px)`, // `translate(${left}px, ${top}px)
        transformOrigin: 'top left',
        width: `${width}px`,
        height: `${height}px`,
        pointerEvents: 'none',
      }}
    />
  );
};

export default MultiSelectPreviewBox;
