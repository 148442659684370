import { useAPIContext } from '../../../contexts/hooks/useAPIContext';

import { useApiCall } from '../helpers/useApiCall';

export const useGenerateQRCode = () => {
  const { makeCall, response, isLoading, error } = useApiCall<{
    qrCode: string;
    text: string;
  }>();
  const { qrCodeService } = useAPIContext();

  const generateQRCode = async (token: string, text: string) => {
    await makeCall(signal => {
      return qrCodeService.generate(token, text, signal);
    });
  };

  return { generateQRCode, response, isLoading, error };
};
