import { SVGIcon } from '../../../api/svgIcons/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useGetAll = () => {
  const { makeCall, response, isLoading, error } = useApiCall<SVGIcon[]>();
  const { svgIconService } = useAPIContext();

  const getAll = async (token: string) => {
    await makeCall(signal => {
      return svgIconService.getAll(token, signal);
    });
  };

  return { getAll, response, isLoading, error };
};
