import { Fragment, useEffect, useState } from 'react';
import { Disclosure, Menu, Transition } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { Button, Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';
import GenerateDocumentAPI from '../Documentation/GenerateDocumentAPI';
import usePDFStore from '../../stores/usePDFStore';
import { HiOutlineSparkles } from 'react-icons/hi2';

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}
const navigation: NavigationItem[] = [{ name: 'View API Request', href: '#', current: false }];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
  const { clearSession, user } = useUserContext();
  const { template } = usePDFStore(state => {
    return {
      template: state.template,
    };
  });

  const [userInitials, setUserInitials] = useState('');
  const [dialogOpen, setOpen] = useState(false);

  const handleOpen = () => setOpen(!dialogOpen);

  useEffect(() => {
    let initials = 'U'; // Default initials if user data is not available
    if (user?.name) {
      initials = user.name
        .split(' ')
        .map(name => name[0])
        .join('')
        .toUpperCase(); // Or construct from name if available
    } else if (user?.name) {
      initials = user.email[0].toUpperCase(); // Use the first letter of the email, capitalized
    }
    setUserInitials(initials);
  }, [user]);

  return (
    <Disclosure as='nav' className='flex-col bg-indigo-900 top-0 w-full h-[64px] sticky top-0 z-10'>
      {({ open }) => (
        <>
          <Dialog open={dialogOpen} handler={handleOpen} size='xl'>
            <DialogBody>
              <GenerateDocumentAPI templateId={template.templateId ?? ''} />
            </DialogBody>
            <DialogFooter>
              <Button variant='text' color='red' onClick={handleOpen} className='mr-1'>
                <span>Close</span>
              </Button>
            </DialogFooter>
          </Dialog>
          <div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='absolute -inset-0.5' />
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon className='block h-6 w-6' aria-hidden='true' />
                  ) : (
                    <Bars3Icon className='block h-6 w-6' aria-hidden='true' />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='flex flex-shrink-0 items-center'></div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-10'>
                    <div className='flex items-center text-white gap-3 text-md font-semibold'>
                      {template.name}
                    </div>
                    <div className='flex items-center flex-row'>
                      <Button
                        color='purple'
                        onClick={handleOpen}
                        className={classNames(
                          'text-white hover:bg-gray-300 hover:text-black',
                          'rounded-md px-3 py-2 text-sm font-medium cursor-pointer flex flex-row items-center gap-2'
                        )}
                      >
                        <HiOutlineSparkles className='h-6 w-6' />
                        Dynamic Generation
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='relative flex items-center justify-center rounded-full bg-gray-200 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='absolute -inset-1.5' />
                      <span className='sr-only'>Open user menu</span>
                      {/* Center initials inside the circle */}
                      <div className='flex items-center justify-center h-8 w-8 rounded-full bg-white-200 text-black'>
                        {userInitials}
                      </div>
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Your Profile
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                          >
                            Settings
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href='#'
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm text-gray-700'
                            )}
                            onClick={clearSession}
                          >
                            Sign out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2'>
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  href={item.href}
                  className={classNames(
                    item.current
                      ? 'bg-gray-900 text-white'
                      : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
