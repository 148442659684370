import PageContainer from '../../components/layout/PageContainer';
import ApiKeyList from '../../features/apiKeys/components/ApiKeyList';

const ApiKeyManagement = () => {
  return (
    <PageContainer>
      <ApiKeyList />
    </PageContainer>
  );
};

export default ApiKeyManagement;
