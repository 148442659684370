import { useCallback, useMemo } from 'react';
import { Alignment, PDFObjectType } from '../../enums/PDFObjects';
import { parseSVGSize } from '../../helpers/svgHelpers';
import usePDFStore from '../../stores/usePDFStore';
import {
  IconSVGData,
  ImageData,
  LineSVGData,
  PDFObject,
  QRCodeData,
  RectSVGData,
  SVGData,
  TableCellData,
  TableData,
  TableRowData,
  TextBoxData,
} from '../../types/PDFObjects';
import { v4 as uuidv4 } from 'uuid';
import { LineCaps } from '../../enums/LineCaps';

const usePDFObjectsManager = () => {
  const page = usePDFStore(state => state.settings.page);
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const updatePDFObjects = usePDFStore(state => state.updatePDFObjects);
  const settings = usePDFStore(state => state.settings);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const addPDFObject = usePDFStore(state => state.addPDFObject);
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const clearCurrentSelections = usePDFStore(state => state.clearCurrenSelections);
  const removePDFObjects = usePDFStore(state => state.removePDFObjects);
  const setCopyObjects = usePDFStore(state => state.setCopyObjects);
  const copyObjects = usePDFStore(state => state.copyObjects);
  const currentPage = usePDFStore(state => state.currentPage);
  const setScrollToId = usePDFStore(state => state.setScrollToId);

  const duplicateObjects = useCallback(
    (objects: PDFObject[]) => {
      clearCurrentSelections();
      objects.map(obj => {
        if (obj.type === PDFObjectType.LINE_SVG) {
          //add 10 to line x1 and x2
          const line = {
            x1: obj.line.x1 + 10,
            y1: obj.line.y1 + 10,
            x2: obj.line.x2 + 10,
            y2: obj.line.y2 + 10,
          };
          console.log('adding line', line);
          addPDFObject({ ...obj, id: uuidv4(), isSelected: true, line });
        } else {
          const newPosition = { x: obj.position.x + 10, y: obj.position.y + 10 };
          addPDFObject({ ...obj, id: uuidv4(), isSelected: true, position: newPosition });
        }
      });
    },
    [clearCurrentSelections, addPDFObject]
  );

  const pasteCopiedObjects = useCallback(
    (pageNumber?: number) => {
      clearCurrentSelections();
      copyObjects.map(obj => {
        //slightly move the object
        if (obj.type === PDFObjectType.LINE_SVG) {
          const line = {
            x1: obj.line.x1 + 10,
            y1: obj.line.y1 + 10,
            x2: obj.line.x2 + 10,
            y2: obj.line.y2 + 10,
          };
          addPDFObject({ ...obj, id: uuidv4(), isSelected: true, line });
          return obj;
        } else {
          const newPosition = { x: obj.position.x + 10, y: obj.position.y + 10 };
          const newObj: PDFObject = {
            ...obj,
            id: uuidv4(),
            isSelected: true,
            position: newPosition,
            page: pageNumber || currentPage,
          };
          addPDFObject(newObj);
          return obj;
        }
      });
    },
    [addPDFObject, copyObjects, clearCurrentSelections, currentPage]
  );

  const alignObject = useCallback(
    (pdfObject: PDFObject, alignment: Alignment) => {
      const { width, height } = pdfObject.size;
      const newPosition = { x: pdfObject.position.x, y: pdfObject.position.y };
      switch (alignment) {
        case Alignment.LEFT:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const width = Math.abs(pdfObject.line.x1 - pdfObject.line.x2);
            const line = {
              x1: pdfObject.line.x1 > pdfObject.line.x2 ? width : 0,
              y1: pdfObject.line.y1,
              x2: pdfObject.line.x1 > pdfObject.line.x2 ? 0 : width,
              y2: pdfObject.line.y2,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.x = 0;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        case Alignment.RIGHT:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const width = Math.abs(pdfObject.line.x1 - pdfObject.line.x2);
            const line = {
              x1: pdfObject.line.x1 > pdfObject.line.x2 ? page.size.width : page.size.width - width,
              y1: pdfObject.line.y1,
              x2: pdfObject.line.x1 > pdfObject.line.x2 ? page.size.width - width : page.size.width,
              y2: pdfObject.line.y2,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.x = page.size.width - width;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        case Alignment.TOP:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const height = Math.abs(pdfObject.line.y1 - pdfObject.line.y2);
            const line = {
              x1: pdfObject.line.x1,
              y1: pdfObject.line.y1 > pdfObject.line.y2 ? height : 0,
              x2: pdfObject.line.x2,
              y2: pdfObject.line.y1 > pdfObject.line.y2 ? 0 : height,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.y = 0;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        case Alignment.BOTTOM:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const height = Math.abs(pdfObject.line.y1 - pdfObject.line.y2);
            const line = {
              x1: pdfObject.line.x1,
              y1:
                pdfObject.line.y1 > pdfObject.line.y2
                  ? page.size.height
                  : page.size.height - height,
              x2: pdfObject.line.x2,
              y2:
                pdfObject.line.y1 > pdfObject.line.y2
                  ? page.size.height - height
                  : page.size.height,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.y = page.size.height - height;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        case Alignment.VCENTER:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const height = Math.abs(pdfObject.line.y1 - pdfObject.line.y2);
            const line = {
              x1: pdfObject.line.x1,
              y1:
                pdfObject.line.y1 > pdfObject.line.y2
                  ? (page.size.height + height) / 2
                  : (page.size.height - height) / 2,
              x2: pdfObject.line.x2,
              y2:
                pdfObject.line.y1 > pdfObject.line.y2
                  ? (page.size.height - height) / 2
                  : (page.size.height + height) / 2,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.y = (page.size.height - height) / 2;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        case Alignment.HCENTER:
          if (pdfObject.type === PDFObjectType.LINE_SVG) {
            const width = Math.abs(pdfObject.line.x1 - pdfObject.line.x2);
            const line = {
              x1:
                pdfObject.line.x1 > pdfObject.line.x2
                  ? (page.size.width + width) / 2
                  : (page.size.width - width) / 2,
              y1: pdfObject.line.y1,
              x2:
                pdfObject.line.x1 > pdfObject.line.x2
                  ? (page.size.width - width) / 2
                  : (page.size.width + width) / 2,
              y2: pdfObject.line.y2,
            };
            updatePDFObject(pdfObject.id, { line });
          } else {
            newPosition.x = (page.size.width - width) / 2;
            updatePDFObject(pdfObject.id, { position: newPosition });
          }
          break;
        default:
          updatePDFObject(pdfObject.id, { position: newPosition });
          break;
      }
    },
    [page.size.height, page.size.width, updatePDFObject]
  );

  const getContentJSON = useCallback(() => {
    const textBoxes = pdfObjects.filter(obj => obj.type === PDFObjectType.TEXTBOX);
    const tables = pdfObjects.filter(obj => obj.type === PDFObjectType.TABLE);
    const svgs = pdfObjects.filter(obj => obj.type === PDFObjectType.SVG);
    const lineSVGs = pdfObjects.filter(obj => obj.type === PDFObjectType.LINE_SVG);
    const rectSVGs = pdfObjects.filter(obj => obj.type === PDFObjectType.RECT_SVG);
    const iconSVGs = pdfObjects.filter(obj => obj.type === PDFObjectType.ICON_SVG);
    const qrCodes = pdfObjects.filter(obj => obj.type === PDFObjectType.QR_CODE);
    const images = pdfObjects.filter(obj => obj.type === PDFObjectType.IMAGE);

    const state = {
      pageSize: settings.page.size,
      pageOrientation: settings.page.orientation,
      textBoxes,
      tables,
      svgs,
      lineSVGs,
      rectSVGs,
      iconSVGs,
      qrCodes,
      images,
    };

    return JSON.stringify(state);
  }, [pdfObjects, settings.page.orientation, settings.page.size]);

  //get selected only return if 1 object is selected
  const getSelected = useCallback(() => {
    const selected = pdfObjects.filter(obj => obj.isSelected);
    return selected.length === 1 ? selected[0] : null;
  }, [pdfObjects]);

  const addImage = useCallback(
    (src: string, fileId: string) => {
      const newImage: ImageData = {
        type: PDFObjectType.IMAGE,
        displayName: 'Image',
        id: uuidv4(),
        fileId,
        src,
        position: { x: 200, y: 200 },
        size: { width: 100, height: 100 },
        zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
        isSelected: false,
        isDynamic: false,
        jsonKey: '',
        page: currentPage,
        lastUpdated: Date.now(),
        localLastUpdated: Date.now(),
        acknowledged: false,
        acknowledgementId: null,
      };
      addPDFObject(newImage);
      setScrollToId(newImage.id);
      setCurrentSelections([newImage.id]);
    },
    [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]
  );

  const addQRCode = useCallback(
    (content: string) => {
      const newQRCode: QRCodeData = {
        type: PDFObjectType.QR_CODE,
        displayName: 'QRCode',
        value: 'https://scribepdf.com', //default value
        jsonKey: '',
        isDynamic: false,
        content: content,
        id: uuidv4(),
        position: { x: 100, y: 100 },
        size: { width: 100, height: 100 },
        zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
        isSelected: false,
        page: currentPage,
        lastUpdated: Date.now(),
        localLastUpdated: Date.now(),
        acknowledged: false,
        acknowledgementId: null,
      };
      addPDFObject(newQRCode);
      setScrollToId(newQRCode.id);
      setCurrentSelections([newQRCode.id]);
    },
    [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]
  );

  const addTextBox = useCallback(() => {
    const newTextBox: TextBoxData = {
      type: PDFObjectType.TEXTBOX,
      displayName: 'TextBox',
      fontColor: settings.text.defaultFontColor,
      id: uuidv4(),
      content: 'Lorem ipsum dorem.',
      position: { x: 100, y: 100 },
      size: { width: 200, height: 100 },
      fontFamily: settings.text.defaultFontFamily,
      fontSize: settings.text.defaultFontSize,
      fontWeight: 'normal',
      align: settings.text.defaultAlignment,
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
      isDynamic: false,
      isSelected: false,
      page: currentPage,
      lastUpdated: Date.now(),
      localLastUpdated: Date.now(),
      acknowledged: false,
      acknowledgementId: null,
    };
    addPDFObject(newTextBox);
    setScrollToId(newTextBox.id);
    setCurrentSelections([newTextBox.id]);
  }, [addPDFObject, pdfObjects, setCurrentSelections, settings.text, currentPage, setScrollToId]);

  const addLineSVG = useCallback(() => {
    const newLineSVG: LineSVGData = {
      type: PDFObjectType.LINE_SVG,
      displayName: 'LineSVG',
      caps: LineCaps.NONE,
      id: uuidv4(),
      position: { x: 0, y: 0 },
      size: { width: 900, height: 900 },
      zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
      lastUpdated: Date.now(),
      localLastUpdated: Date.now(),
      isSelected: false,
      line: {
        x1: 2,
        y1: 0,
        x2: 2,
        y2: 100,
      },
      stroke: {
        color: '#000000',
        width: 1,
      },
      rotation: 0,
      page: currentPage,
      acknowledged: false,
      acknowledgementId: null,
    };
    setScrollToId(newLineSVG.id);
    addPDFObject(newLineSVG);
    setCurrentSelections([newLineSVG.id]);
  }, [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]);

  const addRectSVG = useCallback(() => {
    const newRectSVG: RectSVGData = {
      type: PDFObjectType.RECT_SVG,
      displayName: PDFObjectType.RECT_SVG,
      id: uuidv4(),
      position: { x: 0, y: 0 },
      size: { width: 900, height: 900 },
      zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
      isSelected: false,
      rect: {
        x: 0,
        y: 0,
        width: 100,
        height: 100,
      },
      //black in h
      fill: '#000000',
      stroke: {
        color: '#000000',
        width: 1,
      },
      rotation: 0,
      page: currentPage,
      lastUpdated: Date.now(),
      localLastUpdated: Date.now(),
      acknowledged: false,
      acknowledgementId: null,
    };
    setScrollToId(newRectSVG.id);
    addPDFObject(newRectSVG);
    setCurrentSelections([newRectSVG.id]);
  }, [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]);

  const addSVG = useCallback(
    (svg: string) => {
      const { viewBox, height, width } = parseSVGSize(svg);
      const newSVG: SVGData = {
        type: PDFObjectType.SVG,
        displayName: 'SVG',
        id: uuidv4(),
        content: svg,
        position: { x: 200, y: 200 },
        size: { width: Number(width), height: Number(height) },
        viewBox: { x: 0, y: 0, width: Number(width), height: Number(height) },
        aspectRatio: Number(width) / Number(height),
        zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
        isSelected: false,
        page: currentPage,
        lastUpdated: Date.now(),
        localLastUpdated: Date.now(),
        acknowledged: false,
        acknowledgementId: null,
      };

      if (viewBox) {
        const [x, y, width, height] = viewBox.split(' ').map(parseFloat);
        newSVG.viewBox = { x, y, width, height };
        newSVG.size = { width, height };
        newSVG.aspectRatio = width / height;
      }
      addPDFObject(newSVG);
      setScrollToId(newSVG.id);
      setCurrentSelections([newSVG.id]);
    },
    [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]
  );

  const addIconSVG = useCallback(
    (svgIconId: string, name: string, content: string) => {
      const newIconSVG: IconSVGData = {
        type: PDFObjectType.ICON_SVG,
        displayName: 'IconSVG',
        id: uuidv4(),
        content: content,
        position: { x: 200, y: 200 },
        size: { width: 100, height: 100 },
        aspectRatio: 1,
        zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
        isSelected: false,
        svgIcon: { svgIconId, name },
        color: '#000000',
        page: currentPage,
        lastUpdated: Date.now(),
        localLastUpdated: Date.now(),
        acknowledged: false,
        acknowledgementId: null,
      };
      addPDFObject(newIconSVG);
      setScrollToId(newIconSVG.id);
      setCurrentSelections([newIconSVG.id]);
    },
    [addPDFObject, pdfObjects, setCurrentSelections, currentPage, setScrollToId]
  );

  const addTable = useCallback(() => {
    const newTable: TableData = {
      type: 'Table',
      displayName: 'Table',
      id: uuidv4(),
      position: { x: 100, y: 100 },
      size: { width: 300, height: 181 },
      headersHeight: 30,
      headersStyle: {
        fontSize: settings.text.defaultFontSize,
        fontFamily: settings.text.defaultFontFamily,
        fontWeight: 'bold',
        lineSpacing: 1.2,
        align: 'center',
        fontStyle: 'normal',
        underlined: false,
      },
      rowsStyle: {
        fontSize: settings.text.defaultFontSize,
        fontFamily: settings.text.defaultFontFamily,
        fontWeight: 'normal',
        lineSpacing: 1.2,
        align: 'left',
        minHeight: 150,
        fontStyle: 'normal',
        underlined: false,
      },
      headers: [{ content: 'Header' }, { content: 'Header' }, { content: 'Header' }],
      rows: [
        { cells: [{ content: '' }, { content: '' }, { content: '' }] },
        { cells: [{ content: '' }, { content: '' }, { content: '' }] },
        { cells: [{ content: '' }, { content: '' }, { content: '' }] },
      ],
      rowHeights: [50, 50, 50],
      zIndex: pdfObjects.filter(obj => obj.page === currentPage).length,
      isDynamic: false,
      jsonKey: '',
      headerWidths: [100, 100, 100],
      page: currentPage,
      lastUpdated: Date.now(),
      localLastUpdated: Date.now(),
      acknowledged: false,
      acknowledgementId: null,
    };

    addPDFObject(newTable);
    setCurrentSelections([newTable.id]);
    setScrollToId(newTable.id);
  }, [addPDFObject, pdfObjects, setCurrentSelections, settings.text, currentPage, setScrollToId]);

  const addRow = useCallback(
    (tableData: TableData) => {
      const newCells: TableCellData[] = [];
      for (let i = 0; i < tableData.headers.length; i++) {
        newCells.push({
          content: '',
        });
      }
      //create new html row and measure its height

      const newRow: TableRowData = {
        cells: newCells,
      };
      const updatedRows = [...tableData.rows, newRow];
      updatePDFObject<TableData>(tableData.id, {
        ...tableData,
        rows: updatedRows,
        rowHeights: tableData.rowHeights.concat([tableData.rowsStyle.minHeight]),
      });
    },
    [updatePDFObject]
  );

  const removeRow = useCallback(
    (tableData: TableData) => {
      if (tableData.rows.length > 0) {
        const updatedRows = tableData.rows.slice(0, -1); // Remove the last row
        updatePDFObject<TableData>(tableData.id, {
          ...tableData,
          rows: updatedRows,
          rowHeights: tableData.rowHeights.slice(0, -1),
        });
      }
    },
    [updatePDFObject]
  );

  const addColumn = useCallback(
    (tableData: TableData) => {
      const updatedHeaders = tableData.headers.concat({ content: 'Header' });
      const updatedRows = tableData.rows.map(row => {
        return {
          ...row,
          cells: row.cells.concat({ content: '' }),
        };
      });
      updatePDFObject<TableData>(tableData.id, {
        ...tableData,
        headers: updatedHeaders,
        rows: updatedRows,
        headerWidths: tableData.headerWidths.concat([100]),
        size: {
          width: tableData.size.width + 100,
          height: tableData.size.height,
        },
      });
    },
    [updatePDFObject]
  );

  const removeColumn = useCallback(
    (tableData: TableData) => {
      if (tableData.headers.length > 0) {
        const updatedHeaders = tableData.headers.slice(0, -1); // Remove the last header
        const updatedRows = tableData.rows.map(row => {
          return {
            ...row,
            cells: row.cells.slice(0, -1),
          };
        });
        updatePDFObject<TableData>(tableData.id, {
          ...tableData,
          headers: updatedHeaders,
          rows: updatedRows,
          headerWidths: tableData.headerWidths.slice(0, -1),
        });
      }
    },
    [updatePDFObject]
  );
  return useMemo(
    () => ({
      pdfObjects,
      addTextBox,
      addSVG,
      addTable,
      setCurrentSelections,
      clearCurrentSelections,
      updatePDFObject,
      updatePDFObjects,
      removePDFObjects,
      getSelected,
      getContentJSON,
      alignObject,
      addRow,
      removeRow,
      addColumn,
      removeColumn,
      addLineSVG,
      addRectSVG,
      setCopyObjects,
      pasteCopiedObjects,
      addIconSVG,
      copyObjects,
      duplicateObjects,
      addQRCode,
      addImage,
    }),
    [
      pdfObjects,
      addTextBox,
      addSVG,
      addTable,
      setCurrentSelections,
      clearCurrentSelections,
      updatePDFObject,
      updatePDFObjects,
      removePDFObjects,
      getSelected,
      getContentJSON,
      alignObject,
      addRow,
      removeRow,
      addColumn,
      removeColumn,
      addLineSVG,
      addRectSVG,
      setCopyObjects,
      pasteCopiedObjects,
      addIconSVG,
      copyObjects,
      duplicateObjects,
      addQRCode,
      addImage,
    ]
  );
};
export default usePDFObjectsManager;
