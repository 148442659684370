import { Button, Typography } from '@material-tailwind/react';
import { SideNav } from '../layout/SideNav';
import { useEffect, useState } from 'react';
import { useCreateTemplate } from '../../hooks/api/templates/useCreateTemplate';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { useNavigate } from 'react-router-dom';
import InputText from '../ui/InputText';
import InputTextArea from '../ui/InputTextArea';
import ShareTemplate from './ShareTemplate';
import { resumeTemplate, ticketTemplate } from '../../helpers/templates';

const CreateTemplatePage = () => {
  const [templateName, setTemplateName] = useState('');
  const [templateDescription, setTemplateDescription] = useState('');
  const [error, setError] = useState('');
  const [saved, setSaved] = useState(false);
  const { jwt } = useUserContext();
  const navigate = useNavigate();

  const templates = [
    { id: 0, src: '/ticket-template.png', name: 'Ticket' },
    { id: 1, src: '/resume-template.png', name: 'Resume' },
  ];

  const { createTemplate, response, isLoading } = useCreateTemplate();

  useEffect(() => {
    if (response) {
      setSaved(true);
    }
  }, [response]);

  const validate = () => {
    let error = '';
    if (!templateName) {
      error = 'Template Name is required';
      setError(error);
      return false;
    }

    return true;
  };

  const handleSubmit = () => {
    if (validate()) {
      const data = {
        name: templateName,
        description: templateDescription,
      };
      createTemplate(data, jwt!);
    }
  };

  const handleTemplateNameChange = (value: string) => {
    setError('');
    setTemplateName(value);
  };

  const createFromTemplate = (id: number) => () => {
    const template = id === 0 ? ticketTemplate : resumeTemplate;
    const data = {
      name: templates[id].name,
      description: '',
      content: template,
    };
    createTemplate(data, jwt!);
  };

  if (response && saved) {
    return (
      <div className='flex h-screen bg-gray-100 overflow-auto'>
        <SideNav />
        <div className='flex flex-col items-center flex-grow overflow-auto p-5 w-full gap-5'>
          <div className='flex flex-col w-full text-center slide-in-from-left'>
            <Typography variant='h2' color='blue-gray' className='font-bold p-2'>
              Your Template is Ready
            </Typography>
            <Typography variant='h6' color='blue-gray' className='p-2'>
              Let's setup sharing and collaboration settings for your template or you can skip
            </Typography>
          </div>
          <div className='flex flex-col items-center xl:w-4/6 lg:w-3/4  md:w-full sm:w-full bg-white rounded-lg shadow-sm p-5 slide-in-from-bottom'>
            <ShareTemplate templateId={response.templateId} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className='flex h-screen bg-gray-100 overflow-auto'
      style={{
        minHeight: '100%',
      }}
    >
      <SideNav />
      <div className='flex flex-col flex-grow overflow-auto p-5 w-full'>
        <div className='flex flex-col w-full text-center slide-in-from-top'>
          <Typography variant='h2' color='blue-gray' className='font-bold p-2'>
            Let's Start Your Template
          </Typography>
          <Typography variant='h6' color='blue-gray' className='p-2'>
            Fill in the details below to create a new template or use one of our pre-built templates
            to get started.
          </Typography>
        </div>
        <div className='flex lg:flex-row sm:flex-col md:flex-col gap-10 pt-10'>
          <div className='flex flex-col justify-center items-center w-full bg-white rounded-lg shadow-sm p-3 slide-in-from-left'>
            <Typography variant='h4' color='blue-gray' className='font-bold'>
              Create Your Own
            </Typography>
            <div className='w-full px-5 '>
              <div className='mt-5 w-full'>
                <div className='flex flex-col'>
                  <div className='text-l p-1'>Title</div>
                  <div className='w-full'>
                    <InputText
                      value={templateName}
                      handleChange={handleTemplateNameChange}
                      placeholder='Enter Template Name'
                    />
                  </div>
                </div>
                {
                  <span color='red' className='mt-2'>
                    {error}
                  </span>
                }
              </div>
              <div className='flex flex-col my-10'>
                <div className='text-l p-1'>Description</div>
                <div className='w-full h-[100px]'>
                  <InputTextArea
                    value={templateDescription}
                    handleChange={value => setTemplateDescription(value)}
                    placeholder='Enter Template Description'
                  />
                </div>
              </div>

              <div className='flex w-full justify-end'>
                <div>
                  <Button
                    variant='outlined'
                    color='red' // Use an appropriate color for cancel
                    className='mt-4 mr-2' // Adjust spacing as needed
                    onClick={() => navigate(-1)} // Navigates back to the previous page
                  >
                    Cancel
                  </Button>
                  <Button
                    color='indigo'
                    className='mt-4'
                    onClick={handleSubmit}
                    disabled={isLoading}
                  >
                    {isLoading ? 'Saving...' : 'Save'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className='flex flex-col justify-center items-center w-full bg-white rounded-lg shadow-sm p-5 slide-in-from-right'>
            <Typography variant='h4' color='blue-gray' className='font-bold'>
              Choose Template
            </Typography>
            <div className='grid grid-cols-2 gap-4 mt-5'>
              {templates.map(template => (
                <div key={template.id} className='flex flex-col justify-center items-center'>
                  <Typography variant='h6' color='blue-gray' className='font-bold'>
                    {template.name}
                  </Typography>
                  <button
                    key={template.id}
                    className={`w-full p-2 hover:border-2 `}
                    onClick={createFromTemplate(template.id)}
                    disabled={isLoading}
                  >
                    <img src={template.src} alt={template.name} className='w-full h-auto' />
                  </button>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateTemplatePage;
