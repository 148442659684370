import { create } from 'zustand';
import { PDFObject } from '../types/PDFObjects';
import { FontFamilies } from '../types/FontFamilies';
import { isEqual } from 'lodash';
import { Orientation, PageSize, PageSizeDimensions } from '../enums/PDF';
import { v4 as uuidv4 } from 'uuid';

export interface State {
  template: {
    templateId: string | null;
    isLoaded: boolean;
    name: string;
  };
  pdfObjects: PDFObject[];
  deleteObjects: Map<string, PDFObject>;
  copyObjects: PDFObject[];
  isDragging: boolean;
  lastSaved: number;
  currentPage: number;
  pages: number[];
  settings: {
    scale: number;
    page: {
      orientation: Orientation;
      size: {
        name: PageSize;
        width: number;
        height: number;
      };
    };
    gridSnap: {
      isEnabled: boolean;
      snapInterval: number;
    };
    autoSave: {
      isEnabled: boolean;
      frequencyLimit: number;
    };
    text: {
      defaultFontFamily: FontFamilies;
      defaultFontSize: number;
      defaultFontColor: string;
      defaultAlignment: 'left' | 'center' | 'right';
    };
  };
  scrollToId: string | null;
}
export interface PDFStore extends State {
  setTemplate: (templateId: string, templateName: string, pdfObjects: PDFObject[]) => void;
  updateLastSaved: (lastSabed: number) => void;
  updatePDFObjects: (pdfObjects: PDFObject[]) => void;
  updatePDFObject: <T extends PDFObject>(id: string, pdfObject: Partial<T>) => void;
  addPDFObject: (pdfObject: PDFObject) => void;
  removePDFObjects: (ids: string[]) => void;
  clearCurrenSelections: () => void;
  setCurrentSelections: (ids: string[]) => void;
  toggleAutoSave: () => void;
  reset: () => void;
  setCopyObjects: (pdfObjects: PDFObject[]) => void;
  updateScale: (scale: number) => void;
  setCurrentPage: (page: number) => void;
  addPage: () => void;
  removePage: (pageNumber: number) => void;
  duplicatePage: (pageNumber: number) => void;
  setPages: (pages: number[]) => void;
  setScrollToId: (scrollToId: string | null) => void;
  updateRemotePDFObjects: (remotePdfObjects: PDFObject[], acknowledgementIds: string[]) => void;
  updatePDFObjectsAcknowledgmentId: (
    acknowledgements: { id: string; acknowledgementId: string; lastUpdated: number }[]
  ) => void;
  setIsDragging: (isDragging: boolean) => void;
}

const initialState: State = {
  template: {
    templateId: null,
    isLoaded: false,
    name: '',
  },
  currentPage: 1,
  pages: [1],
  lastSaved: Date.now(),
  isDragging: false,
  pdfObjects: [],
  deleteObjects: new Map(),
  copyObjects: [],
  settings: {
    scale: 0.7,
    page: {
      orientation: Orientation.PORTRAIT,
      size: {
        name: PageSize.A4,
        width: PageSizeDimensions.A4.width,
        height: PageSizeDimensions.A4.height,
      },
    },
    gridSnap: {
      isEnabled: true,
      snapInterval: 10,
    },
    autoSave: {
      isEnabled: true,
      frequencyLimit: 3000,
    },
    text: {
      defaultFontFamily: FontFamilies.Roboto,
      defaultFontSize: 12,
      defaultFontColor: '#000000',
      defaultAlignment: 'left',
    },
  },
  scrollToId: null,
};

const usePDFStore = create<PDFStore>(set => ({
  setTemplate: (templateId, templateName, pdfObjects) => {
    set({ template: { templateId, isLoaded: true, name: templateName }, pdfObjects });
  },
  toggleAutoSave: () => {
    set(state => {
      return {
        settings: {
          ...state.settings,
          autoSave: { ...state.settings.autoSave, isEnabled: !state.settings.autoSave.isEnabled },
        },
      };
    });
  },
  updateScale: scale => {
    set(state => {
      return { settings: { ...state.settings, scale } };
    });
  },
  updateLastSaved: lastSaved => set({ lastSaved }),
  //shallow compare
  updatePDFObjects: newPdfObjects => {
    set({ pdfObjects: newPdfObjects });
  },
  updatePDFObject: (id, updatedObject) => {
    set(state => {
      const newObjects = state.pdfObjects.map(obj => {
        if (obj.id === id) {
          //if there is no difference between the new object and the old object return the old object

          if (isEqual(obj, { ...obj, ...updatedObject })) {
            return obj;
          }

          return {
            ...obj,
            ...updatedObject,
            acknowledged: false,
            acknowledgementId: null,
            localLastUpdated: Date.now(),
          } as PDFObject;
        }
        return obj;
      });

      return { pdfObjects: newObjects };
    });
  },
  // updatePDFObjectAcknowledgmentId: (id: string, acknowledgementId: string, lastUpdated: number) => {
  //   set(state => {
  //     const newObjects = state.pdfObjects.map(obj => {
  //       if (obj.id === id) {
  //         if (obj.lastUpdated === lastUpdated) {
  //           return { ...obj, acknowledgementId, lastUpdated } as PDFObject;
  //         }
  //       }
  //       return obj;
  //     });
  //     return { pdfObjects: newObjects };
  //   });
  // },
  updatePDFObjectsAcknowledgmentId: acknowledgements => {
    set(state => {
      const newObjects = state.pdfObjects.map(obj => {
        const ack = acknowledgements.find(ack => ack.id === obj.id);
        if (ack && ack.lastUpdated === obj.localLastUpdated) {
          return { ...obj, acknowledgementId: ack.acknowledgementId };
        }
        return obj;
      });
      return { pdfObjects: newObjects };
    });
  },
  addPDFObject: pdfObject => {
    set(state => {
      return { pdfObjects: [...state.pdfObjects, pdfObject] };
    });
  },
  removePDFObjects: ids => {
    set(state => {
      const newObjects = state.pdfObjects.filter(obj => !ids.includes(obj.id));
      const newDeleteObjects = new Map(state.deleteObjects);
      ids.forEach(id => {
        newDeleteObjects.set(id, state.pdfObjects.find(obj => obj.id === id)!);
      });

      if (!isEqual(newObjects, state.pdfObjects)) {
        return { pdfObjects: newObjects, deleteObjects: newDeleteObjects };
      } else {
        return state;
      }
    });
  },
  clearCurrenSelections: () => {
    const activeElement = document.activeElement as HTMLElement;
    activeElement?.blur();
    set(state => {
      const newObjects = state.pdfObjects.map(obj => {
        if (obj.isSelected) {
          return { ...obj, isSelected: false };
        }
        return obj;
      });

      if (!isEqual(newObjects, state.pdfObjects)) {
        return { pdfObjects: newObjects };
      } else {
        return state;
      }
    });
  },
  //set current selections and find the page of the selected objects
  setCurrentSelections: ids => {
    const activeElement = document.activeElement as HTMLElement;
    activeElement?.blur();
    set(state => {
      const page = state.pdfObjects.find(obj => ids.includes(obj.id))?.page;
      const newObjects = state.pdfObjects.map(obj => {
        // Only update the object if its isSelected state changes
        const isSelected = ids.includes(obj.id);
        if (isSelected === obj.isSelected) {
          return obj; // Return the original object if no change
        } else {
          return { ...obj, isSelected }; // Create a new object if change is necessary
        }
      });
      return { pdfObjects: newObjects, currentPage: page || state.currentPage };
    });
  },
  ...initialState,
  reset: () => set(initialState),
  setCopyObjects: copyObjects => set({ copyObjects }),
  setCurrentPage: page => set({ currentPage: page }),
  addPage: () =>
    set(state => ({
      pages: [...state.pages, state.pages[state.pages.length - 1] + 1],
    })),
  //also remove pdfObjects where pageNumber is equal to the pageNumber
  //set(state => ({ pages: state.pages.filter(p => p !== pageNumber) }))
  removePage: pageNumber => {
    console.log('pageNumber being removed', pageNumber);
    console.log('current page', usePDFStore.getState().currentPage);
    const deletedObjects = usePDFStore.getState().pdfObjects.filter(obj => obj.page === pageNumber);
    const newDeleteObjects = new Map(usePDFStore.getState().deleteObjects);
    deletedObjects.forEach(obj => {
      newDeleteObjects.set(obj.id, obj);
    });
    set(state => ({
      pages: state.pages.filter(p => p !== pageNumber),
      pdfObjects: state.pdfObjects.filter(obj => obj.page !== pageNumber),
      deleteObjects: newDeleteObjects,
      currentPage: state.currentPage === pageNumber ? state.pages[0] : state.currentPage,
    }));
  },
  //duplicate all pdfObjects where pageNumber is equal to the pageNumber but generate new ids
  duplicatePage: pageNumber => {
    set(state => {
      const newObjects = state.pdfObjects
        .filter(obj => obj.page === pageNumber)
        .map(obj => {
          return { ...obj, id: uuidv4(), page: state.pages[state.pages.length - 1] + 1 };
        });
      return {
        pdfObjects: [...state.pdfObjects, ...newObjects],
        pages: [...state.pages, state.pages[state.pages.length - 1] + 1],
      };
    });
  },
  setPages: pages => set({ pages }),
  setScrollToId: (scrollToId: string | null) => set({ scrollToId }),
  updateRemotePDFObjects: (remotePdfObjects: PDFObject[], acknowledgementIds: string[]) => {
    set(state => {
      // Combine local and remote objects, preferring the most recent updates
      const combinedObjects: PDFObject[] = remotePdfObjects.map(remoteObj => {
        const localObj = state.pdfObjects.find(obj => obj.id === remoteObj.id);
        if (localObj) {
          const acknowledged =
            localObj.acknowledged || acknowledgementIds.includes(localObj.acknowledgementId!);
          let isRemoteNewer = remoteObj.lastUpdated > localObj.lastUpdated && acknowledged;

          //if remote timestamp is greater by 5 seconds remote is newer
          if (
            remoteObj.lastUpdated - localObj.lastUpdated > 5000 &&
            Date.now() - localObj.localLastUpdated > 5000
          ) {
            isRemoteNewer = true;
          }
          remoteObj.acknowledged = true;
          remoteObj.acknowledgementId = localObj.acknowledgementId;

          return isRemoteNewer ? remoteObj : localObj;
        } else {
          return remoteObj;
        }
      });

      // Include local objects that are not present in the remote data
      const updatedObjects = state.pdfObjects.filter(
        localObj => !remotePdfObjects.some(remoteObj => remoteObj.id === localObj.id)
      );

      return { pdfObjects: [...updatedObjects, ...combinedObjects] };
    });
  },
  setIsDragging: isDragging => set({ isDragging }),
}));

export default usePDFStore;
