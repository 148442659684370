import React from 'react';

const resizeHandleStyle: React.CSSProperties = {
  cursor: 'nwse-resize',
  width: '16px',
  height: '16px',
  backgroundColor: '#ffffff', // White background for the button
  borderRadius: '50%', // Circle shape
  position: 'absolute',
  border: '1px solid blue', // Slightly blurred outline with low opacity for a soft edge
  outline: '1px solid rgba(255,255,255,0.5)', // Adding a subtle outer glow for a blurred effect
  outlineOffset: '-4px', // Extend the outline into the button for a blurred effect
};

interface ResizeHandleProps {
  position:
    | 'top'
    | 'right'
    | 'bottom'
    | 'left'
    | 'top-left'
    | 'top-right'
    | 'bottom-right'
    | 'bottom-left';
  onStartResize: (e: React.MouseEvent<HTMLDivElement, MouseEvent>, position: string) => void;
}

const ResizeHandle = React.memo(({ position, onStartResize }: ResizeHandleProps) => {
  return (
    <div
      data-testid={`resize-handle-${position}`}
      style={{
        ...resizeHandleStyle,
        position: 'absolute',
        right: position.includes('right') ? '-8px' : undefined,
        left: position.includes('left') ? '-8px' : undefined,
        top: position.includes('top') ? '-8px' : undefined,
        bottom: position.includes('bottom') ? '-8px' : undefined,
      }}
      onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => onStartResize(e, position)}
    />
  );
});

ResizeHandle.displayName = 'ResizeHandle';

export default ResizeHandle;
