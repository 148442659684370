import { useState, useEffect, useRef } from 'react';
import {
  MdFormatAlignCenter,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatLineSpacing,
  MdFormatUnderlined,
} from 'react-icons/md';
import { IconButton, Option, Select, Slider, Tooltip } from '@material-tailwind/react';
import { TextBoxData } from '../../../types/PDFObjects';
import { FontFamilies } from '../../../types/FontFamilies';
import usePDFStore from '../../../stores/usePDFStore';

interface TextBoxToolsProps {
  textBoxData: TextBoxData;
}

const TextboxTools = ({ textBoxData }: TextBoxToolsProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const [showLineSpacingPopup, setShowLineSpacingPopup] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null); // Ref for the wrapper div
  const [color, setColor] = useState<string>(textBoxData.fontColor);
  const lastUpdate = useRef(Date.now());

  const toggleLineSpacingPopup = () => {
    setShowLineSpacingPopup(!showLineSpacingPopup);
  };

  const handleLineSpacingChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newLineSpacing = parseFloat(e.target.value);
    updatePDFObject<TextBoxData>(textBoxData.id, { lineSpacing: newLineSpacing });
  };

  // Handlers for each property change
  const toggleBold = () => {
    const newWeight = textBoxData.fontWeight === 'bold' ? 'normal' : 'bold';
    updatePDFObject<TextBoxData>(textBoxData.id, { fontWeight: newWeight });
  };

  const toggleItalic = () => {
    const newStyle = textBoxData.fontStyle === 'italic' ? 'normal' : 'italic';
    updatePDFObject<TextBoxData>(textBoxData.id, { fontStyle: newStyle });
  };

  const toggleUnderline = () => {
    updatePDFObject<TextBoxData>(textBoxData.id, { underline: !textBoxData.underline });
  };

  const handleTextAlignChange = (value: 'left' | 'right' | 'center') => {
    updatePDFObject<TextBoxData>(textBoxData.id, { align: value });
  };
  const handleFontSizeChange = (newSize: string) => {
    if (Number(newSize) < 1) return; // Prevent negative font sizes
    if (Number(newSize) > 2000) return; // Prevent font sizes that are too large
    updatePDFObject<TextBoxData>(textBoxData.id, {
      fontSize: Number(newSize),
      acknowledged: false,
      acknowledgementId: null,
      localLastUpdated: Date.now(),
    });
  };

  const handleFontFamilyChange = (newFamily: string | undefined) => {
    if (!newFamily) return;
    if (!Object.values(FontFamilies).includes(newFamily as FontFamilies)) return;
    updatePDFObject<TextBoxData>(textBoxData.id, { fontFamily: newFamily as FontFamilies });
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setShowLineSpacingPopup(false); // Close popup if click is outside
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]); // Ensure the effect runs only once

  const handleUpdateColor = (color: string) => {
    if (Date.now() - lastUpdate.current < 100) return;
    setColor(color);
    lastUpdate.current = Date.now();
    console.log('update color');
    updatePDFObject<TextBoxData>(textBoxData.id, { fontColor: color });
  };

  return (
    <div className='flex items-center py-5' data-testid='textbox-tools'>
      <Tooltip placement='top' content='Font Family'>
        <div className='text-sm'>
          <Select
            value={textBoxData.fontFamily}
            label='Font Family'
            aria-label='Font Family'
            onChange={e => handleFontFamilyChange(e)}
            size='md'
          >
            {Object.entries(FontFamilies).map(([key, value]) => (
              <Option
                key={key}
                value={value}
                style={{
                  fontFamily: value,
                }}
              >
                {value}
              </Option>
            ))}
          </Select>
        </div>
      </Tooltip>
      <Tooltip placement='top' content='Font Size'>
        <div className='px-2'>
          <input
            aria-label='Font Size'
            type='number'
            className='w-20 h-9 text-center border rounded-md text-sm'
            value={textBoxData.fontSize}
            onChange={e => handleFontSizeChange(e.target.value)}
            min={1}
            max={2000}
          />
        </div>
      </Tooltip>
      <Tooltip placement='top' content='Bold'>
        <IconButton
          aria-label='Bold'
          variant='text'
          onClick={toggleBold}
          className={textBoxData.fontWeight === 'bold' ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatBold size={24} />
        </IconButton>
      </Tooltip>

      {/* Italic Button */}
      <Tooltip placement='top' content='Italic'>
        <IconButton
          aria-label='Italic'
          variant='text'
          onClick={toggleItalic}
          className={textBoxData.fontStyle === 'italic' ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatItalic size={24} />
        </IconButton>
      </Tooltip>

      {/* Underline Button */}
      <Tooltip placement='top' content='Underline'>
        <IconButton
          aria-label='Underline'
          variant='text'
          onClick={toggleUnderline}
          className={textBoxData.underline ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatUnderlined size={24} />
        </IconButton>
      </Tooltip>

      {/* Align Left Button */}
      <Tooltip placement='top' content='Left Align'>
        <IconButton
          aria-label='Left Align'
          variant='text'
          onClick={() => handleTextAlignChange('left')}
          className={textBoxData.align === 'left' ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatAlignLeft size={24} />
        </IconButton>
      </Tooltip>

      {/* Align Center Button */}
      <Tooltip placement='top' content='Center Align'>
        <IconButton
          aria-label='Center Align'
          variant='text'
          onClick={() => handleTextAlignChange('center')}
          className={textBoxData.align === 'center' ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatAlignCenter size={24} />
        </IconButton>
      </Tooltip>

      {/* Align Right Button */}
      <Tooltip placement='top' content='Right Align'>
        <IconButton
          aria-label='Right Align'
          variant='text'
          onClick={() => handleTextAlignChange('right')}
          className={textBoxData.align === 'right' ? 'text-blue-700' : 'text-black'}
        >
          <MdFormatAlignRight size={24} />
        </IconButton>
      </Tooltip>
      <div className='relative' ref={wrapperRef}>
        <Tooltip placement='top' content='Line Spacing'>
          <IconButton
            aria-label='Line Spacing'
            variant='text'
            onClick={toggleLineSpacingPopup}
            className='text-black'
          >
            <MdFormatLineSpacing size={24} />
          </IconButton>
        </Tooltip>
        {showLineSpacingPopup && (
          <div
            className='absolute right-0 mt-3 p-2 bg-white rounded-lg shadow-lg'
            style={{ zIndex: 10 }}
          >
            <div className='flex items-center mb-3'>
              {' '}
              {/* Use flex to align items in a row */}
              <div className='text-center mr-2 text-sm mx-5'>Line Spacing</div>
              <input
                type='number'
                step='0.1'
                min='0.5'
                max='3.0'
                value={textBoxData.lineSpacing}
                onChange={handleLineSpacingChange}
                className='w-16 text-center text-sm mx-1'
                aria-label='Line Spacing Value'
              />
            </div>
            <Slider
              min={1.2}
              max={3.0}
              step={0.1}
              value={textBoxData.lineSpacing}
              onChange={event => handleLineSpacingChange(event)}
              style={{ width: '100px' }} // Adjust the width of the slider to fit in the popup
            />
          </div>
        )}
      </div>
      <input
        type='color'
        className='px-2 h-8 w-14 block bg-white cursor-pointer disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700'
        id='hs-color-input'
        value={color}
        title='Choose your color'
        onChange={e => handleUpdateColor(e.target.value)}
      ></input>
    </div>
  );
};

export default TextboxTools;
