import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import SVG from './Objects/SVG';
import TextBox from './Objects/TextBox';
import {
  IconSVGData,
  ImageData,
  LineSVGData,
  PDFObject,
  QRCodeData,
  RectSVGData,
  SVGData,
  TableData,
  TextBoxData,
} from '../../types/PDFObjects';
import Table from './Objects/Table/Table';
import PDFObjectWrapper from './Objects/PDFObjectWrapper';
import MultiSelectPreviewBox from './Objects/MultiSelectPreviewBox';
import MultiSelectBox from './Objects/MultiSelectBox';
import { PDFObjectType } from '../../enums/PDFObjects';
import usePDFSettings from '../../hooks/pdf/usePDFSettings';
import { Orientation } from '../../enums/PDF';
import LineSVG from './Objects/LineSVG';
import RectSVG from './Objects/RectSVG';
import IconSVG from './Objects/IconSVG';
import usePDFStore from '../../stores/usePDFStore';
import useMultiSelectPreviewBox from '../../hooks/pdf/useMultiSelectPreviewBox';
import { Tooltip } from '@material-tailwind/react';
import { GoDuplicate, GoFileDiff, GoTrash } from 'react-icons/go';
import ContextMenu from './ContextMenu/ContextMenu';
import { Socket } from 'socket.io-client';
import PlayerCursor from './Multiplayer/PlayerCursor';
import ObjectMenuBar from './Objects/ObjectMenuBar';
import { CursorInfo } from '../../hooks/pdf/useRemoteCursors';
import QRCode from './Objects/QRCode';
import Image from './Objects/Image';

interface PDFPageProps {
  isTemplateLoaded: boolean;
  pdfObjects: PDFObject[];
  pageNumber: number;
  currentSocket: Socket | null;
  cursors: CursorInfo[];
  onMouseMove: (event: React.MouseEvent, pageNumber: number) => void;
}

const PDFPage = memo(
  ({ isTemplateLoaded, pdfObjects, pageNumber, cursors, onMouseMove }: PDFPageProps) => {
    const updatePDFObject = usePDFStore(state => state.updatePDFObject);
    const { page } = usePDFSettings();
    const scale = usePDFStore(state => state.settings.scale);
    const pdfContent = useRef<HTMLDivElement>(null);
    const pdfEditorRef = useRef<HTMLDivElement>(null);
    const pdfPageRef = useRef<HTMLDivElement>(null);
    const scaleRef = useRef<number>(scale);
    const [menuVisible, setMenuVisible] = useState(false);
    const [menuPosition, setMenuPosition] = useState({ x: 0, y: 0 });
    const addPage = usePDFStore(state => state.addPage);
    const removePage = usePDFStore(state => state.removePage);
    const pages = usePDFStore(state => state.pages);
    const duplicatePage = usePDFStore(state => state.duplicatePage);
    const currentPage = usePDFStore(state => state.currentPage);
    const clearCurrentSelections = usePDFStore(state => state.clearCurrenSelections);
    // const { isDragging: isDragging2 } = usePDFStore(state => state.isDragging);
    //fix above is dragging
    const isDraggingObject = usePDFStore(state => state.isDragging);
    const {
      unscaledStartPos,
      unscaledEndPos,
      isDragging,
      handleMouseDown,
      setOffset,
      handleMouseMove,
      offset,
    } = useMultiSelectPreviewBox({ pdfObjects });

    useEffect(() => {
      if (pdfEditorRef.current && pdfPageRef.current) {
        const editorRect = pdfEditorRef.current.getBoundingClientRect();
        const pageRect = pdfPageRef.current.getBoundingClientRect();

        const offsetX = pageRect.left - editorRect.left;
        const offsetY = pageRect.top - editorRect.top;
        setOffset({ x: offsetX, y: offsetY });
      }
    }, [scale, setOffset]); // Depend on specific props if necessar

    useEffect(() => {
      if (pdfEditorRef.current && pdfPageRef.current) {
        const editorRect = pdfEditorRef.current.getBoundingClientRect();
        const pageRect = pdfPageRef.current.getBoundingClientRect();

        const offsetX = pageRect.left - editorRect.left;
        const offsetY = pageRect.top - editorRect.top;
        setOffset({ x: offsetX, y: offsetY });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isTemplateLoaded]);

    useEffect(() => {
      scaleRef.current = scale;
    }, [scale]);

    useEffect(() => {
      const handleResize = () => {
        if (pdfEditorRef.current && pdfPageRef.current && scaleRef.current < 1) {
          const editorRect = pdfEditorRef.current.getBoundingClientRect();
          const pageRect = pdfPageRef.current.getBoundingClientRect();

          const offsetX = pageRect.left - editorRect.left;
          const offsetY = pageRect.top - editorRect.top;
          setOffset({ x: offsetX, y: offsetY });
        }
      };
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [setOffset]);

    useEffect(() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const handleResize = (entries: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        for (const _entry of entries) {
          if (pdfEditorRef.current && pdfPageRef.current && scaleRef.current < 1) {
            const editorRect = pdfEditorRef.current.getBoundingClientRect();
            const pageRect = pdfPageRef.current.getBoundingClientRect();

            console.log('resize observer triggered');
            const offsetX = pageRect.left - editorRect.left;
            const offsetY = pageRect.top - editorRect.top;
            setOffset({ x: offsetX, y: offsetY });
          }
        }
      };

      const observer = new ResizeObserver(handleResize);

      // You can observe multiple elements, here we assume observing the page
      if (pdfEditorRef.current) {
        observer.observe(pdfEditorRef.current);
      }

      return () => {
        if (pdfEditorRef.current) {
          observer.unobserve(pdfEditorRef.current);
        }
        observer.disconnect();
      };
    }, [setOffset]);

    const handleContextMenu = (e: React.MouseEvent) => {
      e.preventDefault();
      setMenuVisible(true);
      const rect = e.currentTarget.getBoundingClientRect();

      const x = (e.clientX - rect.left - offset.x) / scale;
      const y = (e.clientY - rect.top - offset.y) / scale;
      setMenuPosition({ x: x, y: y });
    };

    const adjustedPageSize =
      page.orientation === Orientation.LANDSCAPE
        ? { width: page.size.height, height: page.size.width }
        : page.size;

    const contentWrapperStyle: React.CSSProperties = {
      //relative
      transform: `scale(${scale}, ${scale})`,
      transformOrigin: 'top left',
      width: `${adjustedPageSize.width}px`,
      minHeight: `${adjustedPageSize.height}px`,
      backgroundColor: 'white',
      //blue outline
      outline: `${pageNumber === currentPage ? '2px solid blue' : ''}`,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
      overflow: 'visible',
    };

    const pageContainerStyle: React.CSSProperties = {
      width: `${adjustedPageSize.width * scale}px`,
      height: `${adjustedPageSize.height * scale}px`,
      display: 'flex',
      flexDirection: 'column',
    };

    const pdfEdtiorStyle: React.CSSProperties = useMemo(() => {
      return {
        overflow: 'visible',
        minWidth: `${page.size.width * scale + 200 * scale}px`,
        minHeight: `${page.size.height * scale + 200 * scale}px`,
        maxHeight: `${page.size.height * scale + 400 * scale}px`,
      };
    }, [page.size.height, page.size.width, scale]);

    const onMouseDown = (e: React.MouseEvent) => {
      clearCurrentSelections();
      handleMouseDown(e);
    };

    const handleMouseDownCapture = () => {
      usePDFStore.setState({ currentPage: pageNumber });
    };
    const handleDuplicatePage = () => {
      duplicatePage(pageNumber);
    };
    const handleRemovePage = () => {
      removePage(pageNumber);
    };

    const showMultiSelectBox = useMemo(() => {
      const currentSelectionCount = pdfObjects.filter(p => p.isSelected).length;
      return currentSelectionCount > 1 && !isDragging;
    }, [pdfObjects, isDragging]);

    return (
      <div
        id={`pdf-page-container-${pageNumber}`}
        className='flex flex-col w-full h-full bg-gray-200 border-b border-dashed border-gray-400'
        ref={pdfEditorRef}
        style={pdfEdtiorStyle}
        onMouseDownCapture={handleMouseDownCapture}
        onContextMenu={handleContextMenu}
        onMouseDown={onMouseDown}
        onMouseMove={handleMouseMove}
      >
        <div className='flex flex-row justify-center my-2'>
          <Tooltip content='Duplicate Page' placement='bottom'>
            <button className='hover:bg-gray-300 py-2 px-2 rounded' onClick={handleDuplicatePage}>
              <GoDuplicate size={25} />
            </button>
          </Tooltip>
          {pages.length > 1 && (
            <Tooltip content='Delete Page' placement='bottom'>
              <button
                className='hover:bg-gray-300 py-2 px-2 rounded'
                onClick={handleRemovePage}
                disabled={pages.length === 1}
              >
                <GoTrash size={25} />
              </button>
            </Tooltip>
          )}
          <Tooltip content='Add Page' placement='bottom'>
            <button className='hover:bg-gray-300 py-2 px-2 rounded' onClick={addPage}>
              <GoFileDiff size={25} />
            </button>
          </Tooltip>
        </div>
        <div className='flex justify-center bg-gray-200' id='pdf=page'>
          <div
            ref={pdfPageRef}
            style={pageContainerStyle}
            className='bg-gray-200 flex'
            onMouseMoveCapture={e => onMouseMove(e, pageNumber)}
          >
            <div style={contentWrapperStyle} id='pdf-page-content' ref={pdfContent}>
              {menuVisible && (
                <ContextMenu
                  currentPage={pageNumber}
                  scale={scale}
                  visible={menuVisible}
                  x={menuPosition.x}
                  y={menuPosition.y}
                  onClose={() => setMenuVisible(false)}
                />
              )}
              <MultiSelectPreviewBox
                startPosition={unscaledStartPos}
                endPosition={unscaledEndPos}
                isSelecting={isDragging}
              />
              {cursors.map((cursor, key) => (
                <PlayerCursor
                  key={key}
                  x={cursor.x}
                  y={cursor.y}
                  playerName={cursor.playerName}
                  scale={scale}
                />
              ))}
              {pdfObjects.filter(p => p.isSelected).length == 1 &&
                !isDragging &&
                !isDraggingObject && (
                  <ObjectMenuBar
                    //get first selected object
                    x={pdfObjects.find(p => p.isSelected)?.position.x || 0}
                    y={pdfObjects.find(p => p.isSelected)?.position.y || 0}
                    scale={scale}
                    height={pdfObjects.find(p => p.isSelected)?.size.height || 0}
                    width={pdfObjects.find(p => p.isSelected)?.size.width || 0}
                  />
                )}

              {showMultiSelectBox && (
                <MultiSelectBox key={pdfObjects.length} pdfObjectsData={pdfObjects} />
              )}

              <div
                style={{
                  position: 'relative',
                  width: `${adjustedPageSize.width}px`,
                  height: `${adjustedPageSize.height}px`,
                }}
              >
                {!showMultiSelectBox &&
                  pdfObjects.map(pdfObject => {
                    if (pdfObject.type === PDFObjectType.SVG) {
                      return <SVG key={pdfObject.id} svgData={pdfObject as SVGData} />;
                    } else if (pdfObject.type === PDFObjectType.TEXTBOX) {
                      return <TextBox key={pdfObject.id} textBoxData={pdfObject as TextBoxData} />;
                    } else if (pdfObject.type === PDFObjectType.TABLE) {
                      return (
                        <PDFObjectWrapper key={pdfObject.id} pdfObjectData={pdfObject}>
                          <Table
                            tableData={pdfObject as TableData}
                            updatePDFObject={updatePDFObject}
                          />
                        </PDFObjectWrapper>
                      );
                    } else if (pdfObject.type === PDFObjectType.LINE_SVG) {
                      return <LineSVG key={pdfObject.id} lineSVGData={pdfObject as LineSVGData} />;
                    } else if (pdfObject.type === PDFObjectType.RECT_SVG) {
                      return <RectSVG key={pdfObject.id} rectSVGData={pdfObject as RectSVGData} />;
                    } else if (pdfObject.type === PDFObjectType.ICON_SVG) {
                      return <IconSVG key={pdfObject.id} iconSVGData={pdfObject as IconSVGData} />;
                    } else if (pdfObject.type === PDFObjectType.QR_CODE) {
                      return <QRCode key={pdfObject.id} qrCodeData={pdfObject as QRCodeData} />;
                    } else if (pdfObject.type === PDFObjectType.IMAGE) {
                      return <Image key={pdfObject.id} imageData={pdfObject as ImageData} />;
                    }
                    return null;
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

PDFPage.displayName = 'PDFPage';

export default PDFPage;
