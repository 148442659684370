import { AxiosError } from 'axios';
import { APIException } from './exceptions/APIException';
import { AbortedException } from './exceptions/AbortedExceptions';

function handleAxiosError(error: unknown): never {
  if (error instanceof AxiosError) {
    if (error.code === 'ERR_CANCELED') {
      throw new AbortedException('Request was aborted');
    }
    throw new APIException(error.message, error.response?.data, error.response?.status);
  }
  throw error;
}

export { handleAxiosError };
