import { ReactNode, createContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { IStorageService } from '../infrastructure/LocalStorage';
import { JWTService } from '../services/JWTService';

export interface User {
  userId: string;
  name: string | null;
  email: string;
  organizationId: string;
}

export const UserContext = createContext<
  | {
      user: User | null;
      jwt: string | null;
      setSession: (userData: User, jwtToken: string) => void;
      clearSession: () => void;
    }
  | undefined
>(undefined);

interface UserProviderProps {
  children: ReactNode;
  storageService: IStorageService;
  jwtService: JWTService;
}

export const UserProvider = ({ children, storageService, jwtService }: UserProviderProps) => {
  const [user, setUser] = useState<User | null>(() => {
    const savedUser = storageService.getItem('user');
    return savedUser ? JSON.parse(savedUser) : null;
  });
  const [jwt, setJwt] = useState<string | null>(() => storageService.getItem('authToken'));

  const setSession = (userData: User, jwtToken: string) => {
    Sentry.setUser({
      email: userData.email,
    });
    Sentry.setContext('user', {
      userId: userData.userId,
      email: userData.email,
      name: userData.name,
      organizationId: userData.organizationId,
    });
    storageService.setItem('user', JSON.stringify(userData));
    storageService.setItem('authToken', jwtToken);
    setUser(userData);
    setJwt(jwtToken);
  };

  const clearSession = () => {
    storageService.removeItem('user');
    storageService.removeItem('authToken');
    setUser(null);
    setJwt(null);
  };

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (jwt) {
        const isExpired = jwtService.isExpired(jwt);
        if (isExpired) {
          clearSession();
        }
      }
    };

    checkTokenExpiration();
  }, [jwt]);

  return (
    <UserContext.Provider value={{ user, jwt, setSession, clearSession }}>
      {children}
    </UserContext.Provider>
  );
};
