import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetPassword } from '../../../hooks/api/users/useResetPassword';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';

const ResetPasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { resetPassword, response, error, isLoading } = useResetPassword();
  const [isSuccess, setIsSuccess] = useState(false);
  const [errors, setErrors] = useState({
    passwordMismatch: false,
    passwordShort: false,
    passwordEmpty: false, // New error state for empty password
    confirmPasswordEmpty: false, // New error state for empty confirmPassword
  });
  const { setError } = useAPIErrorContext();

  useEffect(() => {
    if (error) {
      setError(error);
    }
  }, [error, setError]);

  useEffect(() => {
    if (response) {
      setIsSuccess(true);
    }
  }, [response, navigate]);

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      emailExists: false,
      passwordMismatch: password !== confirmPassword,
      passwordShort: password.length < 8,
      passwordEmpty: !password,
      confirmPasswordEmpty: !confirmPassword,
    };

    // Check if any error is true to set isValid to false
    isValid = !Object.values(newErrors).includes(true);

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        navigate('/login');
        return;
      }
      resetPassword(token, password); // Pass the token and new password to the reset function
    }
  };

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    setErrors({
      passwordMismatch: false,
      passwordShort: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
    });
    const { name, value } = e.target as HTMLInputElement;

    if (name === 'password') {
      setPassword(value);
    } else {
      setConfirmPassword(value);
    }
  };

  if (isSuccess) {
    // New success message display block
    return (
      <AuthPageContainer>
        <AuthContentCard>
          <div className='mb-6'>
            <AuthContentCard.Logo />
            <AuthContentCard.Title>Password Reset Successfully</AuthContentCard.Title>
            <p className='text-sm text-center text-gray-400 pt-5'>
              <a
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => navigate('/login')}
                className='text-indigo-600 focus:outline-none focus:underline hover:underline'
              >
                Back To Login
              </a>
            </p>
          </div>
        </AuthContentCard>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      <AuthContentCard>
        <AuthContentCard.Logo />
        <AuthContentCard.Title>Set New Password</AuthContentCard.Title>

        <form onSubmit={handleSubmit}>
          <div className='mb-5'>
            <label htmlFor='password' className='block mb-2 text-sm text-gray-600'>
              New Password
            </label>
            <input
              type='password'
              id='password'
              name='password'
              placeholder='Enter new password'
              value={password}
              onChange={handleInputChange}
              className='w-full px-4 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none focus:ring focus:ring-opacity-40'
            />
          </div>
          {errors.passwordShort && (
            <p className='text-red-500 text-xs my-1'>
              Password needs to be longer than 8 characters
            </p>
          )}
          {errors.passwordEmpty && (
            <p className='text-red-500 text-xs my-1'>Please fill out this field</p>
          )}
          <div className='mb-5'>
            <label htmlFor='confirmPassword' className='block mb-2 text-sm text-gray-600'>
              Confirm New Password
            </label>
            <input
              type='password'
              name='confirmPassword'
              id='confirmPassword'
              placeholder='Confirm new password'
              value={confirmPassword}
              onChange={handleInputChange}
              className='w-full px-4 py-2 text-gray-700 bg-gray-50 border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-indigo-500 focus:outline-none focus:ring focus:ring-opacity-40'
            />
          </div>
          {errors.passwordMismatch && (
            <p className='text-red-500 text-xs my-1'>Passwords do not match</p>
          )}
          {errors.confirmPasswordEmpty && (
            <p className='text-red-500 text-xs my-1'>Please fill out this field</p>
          )}
          <div className='mb-6'>
            <button
              type='submit'
              disabled={isLoading}
              className={`w-full px-3 py-4 text-white rounded-md ${isLoading ? 'bg-indigo-400' : 'bg-indigo-600 hover:bg-indigo-700'} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50`}
            >
              {isLoading ? 'Setting New Password...' : 'Set New Password'}
            </button>
          </div>
        </form>
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default ResetPasswordPage;
