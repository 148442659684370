import { Alert } from '@material-tailwind/react';
import { useEffect, useState } from 'react';

interface NotificationProps {
  message: string;
  isVisible: boolean;
}

const Notification = ({ message, isVisible }: NotificationProps) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    if (isVisible) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isVisible]);

  return (
    <div className={`fixed top-5 right-5 z-50 transition-opacity duration-300 ease-out`}>
      <Alert
        variant='gradient'
        className='w-72 shadow-lg rounded-lg flex justify-between items-start'
        open={open}
        onClose={() => setOpen(false)}
      >
        <div className='flex items-center'>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            strokeWidth={2}
            stroke='currentColor'
            className='h-6 w-6'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              d='M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z'
            />
          </svg>
          <span className='text-sm font-medium ml-2'>{message}</span>
        </div>
      </Alert>
    </div>
  );
};

export default Notification;
