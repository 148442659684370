import { UserDTO } from '../../../api/user/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useGetAllUsers = () => {
  const { makeCall, response, isLoading, error } = useApiCall<UserDTO[]>();
  const { userService } = useAPIContext();

  const getAllUsers = async (token: string) => {
    await makeCall(signal => {
      return userService.getAll(token, signal);
    });
  };

  return { getAllUsers, response, isLoading, error };
};
