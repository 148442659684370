import { UserDTO } from '../../../api/user/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

// The useUserRegister hook definition
export const useRegisterUser = () => {
  const { makeCall, response, isLoading, error } = useApiCall<UserDTO>();
  const { userService } = useAPIContext();

  const registerUser = async (
    email: string,
    password: string,
    name: string,
    companyName: string
  ) => {
    await makeCall(signal => userService.register(email, password, name, companyName, signal));
  };
  return { registerUser, registerResponse: response, isLoading, error };
};
