import { useCallback, useRef, useState } from 'react';
import usePDFStore from '../../stores/usePDFStore';

interface UseResizableProps {
  width: number;
  height: number;
  updateSize: (size: { width: number; height: number }) => void;
  onResizeEnd: (size: { width: number; height: number }) => void;
  aspectRatio?: number;
}

const useResizable = ({
  width,
  height,
  updateSize,
  aspectRatio,
  onResizeEnd,
}: UseResizableProps) => {
  const [resizing, setResizing] = useState(false);
  const sizeRef = useRef({ width, height });
  const lastUpdate = useRef(Date.now());
  const scale = usePDFStore(state => state.settings.scale);
  const setIsDragging = usePDFStore(state => state.setIsDragging);

  const startResize = useCallback(
    (e: React.MouseEvent, handle: string) => {
      e.stopPropagation();
      setIsDragging(true);
      e.preventDefault();
      setResizing(true);
      const startX = e.clientX;
      const startY = e.clientY;
      const startWidth = width;
      const startHeight = height;

      const handleMouseMove = (moveEvent: MouseEvent) => {
        //prevent default to avoid selecting text
        moveEvent.preventDefault();
        let newWidth = startWidth;
        let newHeight = startHeight;
        const movementX = moveEvent.clientX / scale - startX / scale;
        const movementY = moveEvent.clientY / scale - startY / scale;

        if (handle.includes('right')) {
          newWidth = startWidth + movementX;
        }
        if (handle.includes('bottom')) {
          newHeight = startHeight + movementY;
        }
        if (handle.includes('left')) {
          newWidth = startWidth - movementX;
        }
        if (handle.includes('top')) {
          newHeight = startHeight - movementY;
        }

        if (aspectRatio && handle.includes('right')) {
          newHeight = newWidth / aspectRatio;
        } else if (aspectRatio && handle.includes('bottom')) {
          newWidth = newHeight * aspectRatio;
        }

        newWidth = Math.max(10, newWidth);
        newHeight = Math.max(10, newHeight);
        sizeRef.current = { width: newWidth, height: newHeight };
        if (Date.now() - lastUpdate.current < 17) return;
        lastUpdate.current = Date.now();
        updateSize({
          width: sizeRef.current.width,
          height: sizeRef.current.height,
        });
      };

      const handleMouseUp = () => {
        setResizing(false);
        setIsDragging(false);
        onResizeEnd({
          width: sizeRef.current.width,
          height: sizeRef.current.height,
        });
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
      };

      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    },
    [aspectRatio, height, updateSize, width, onResizeEnd, scale]
  );

  return { startResize, resizing };
};

export default useResizable;
