import { useContext } from 'react';
import { User, UserContext } from '../UserContext';

export interface UseUserContextReturn {
  user: User | null;
  jwt: string | null;
  setSession: (userData: User, jwtToken: string) => void;
  clearSession: () => void;
}

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUserContext must be used within a UserProvider');
  }
  return context;
};
