import { useEffect, useRef, useState } from 'react';
import { Socket } from 'socket.io-client';
import { User } from '../../contexts/UserContext';

interface useRemoteCursorsProps {
  socket: Socket | null;
  scale: number;
  user: User | null;
}

export interface CursorInfo {
  x: number;
  y: number;
  playerName: string;
  id: string;
  pageNumber: number;
}

const useRemoteCursors = ({ socket, scale, user }: useRemoteCursorsProps) => {
  const [cursors, setCursors] = useState<CursorInfo[]>([]);
  const lastUpdateSent = useRef(Date.now());
  const UPDATE_INTERVAL_MS = 50;

  useEffect(() => {
    if (socket) {
      socket.on('remote-cursor-update', (cursorData: CursorInfo) => {
        //ignore if the cursor is from the same user
        if (cursorData.id === socket.id) {
          return;
        }

        setCursors(prevCursors => {
          const cursorIndex = prevCursors.findIndex(cursor => cursor.id === cursorData.id);
          if (cursorIndex === -1) {
            return [...prevCursors, cursorData];
          } else {
            const newCursors = [...prevCursors];
            newCursors[cursorIndex] = cursorData;
            return newCursors;
          }
        });
      });

      return () => {
        socket.off('cursor-update');
      };
    }
  }, [socket]);

  const onMouseMove = (event: React.MouseEvent, pageNumber: number) => {
    if (socket) {
      if (Date.now() - lastUpdateSent.current < UPDATE_INTERVAL_MS) {
        return;
      }
      lastUpdateSent.current = Date.now();

      // Get the scrollable container
      const editorContainer = document.getElementById(`pdf-page-container-${pageNumber}`);

      if (!editorContainer) {
        return;
      }
      const rect = event.currentTarget.getBoundingClientRect();

      const x = (event.clientX - rect.left) / scale;
      const y = (event.clientY - rect.top) / scale;

      socket.emit('cursor-update', {
        x: x,
        y: y,
        id: socket.id,
        playerName: user?.name || 'Unknown',
        pageNumber: pageNumber,
      });
    }
  };

  return { cursors, onMouseMove };
};

export default useRemoteCursors;
