import { SideNav } from '../layout/SideNav';
import Table from './Templates';

const TemplatesPage = () => {
  return (
    <div
      className='flex h-screen bg-gray-200 overflow-auto'
      style={{
        minHeight: '100%',
      }}
    >
      <SideNav />
      <div className='flex flex-col flex-grow overflow-auto px-5 py-10'>
        <Table />
      </div>
    </div>
  );
};

export default TemplatesPage;
