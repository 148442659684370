import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';

const modernColor = '#00796B';

//vertical line
const verticalLine = {
  type: 'line',
  content: `
  <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
    <line x1="0" y1="50" x2="90" y2="50" stroke="black" stroke-width="2"/>
  </svg>`,
};

const square = {
  type: 'rect',
  content: `
  <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
    <rect x="5" y="15" width="70" height="70" fill="${modernColor}"/>
  </svg>`,
};

// const circle = `
// <svg width="100" height="100" xmlns="http://www.w3.org/2000/svg">
//   <circle cx="40" cy="50" r="30" fill="${modernColor}"/>
// </svg>`;

// Note: For components that already start at 0,0, no changes are necessary.
// However, for components that had elements positioned with some padding (e.g., x="10"

// Updated SVG components with wrapper function

// Adding all SVG components to the array
const svgComponents = [verticalLine, square];

interface CollectionGalleryProps {
  toggleCollectionGallery: () => void;
}

const ShapesGallery = ({ toggleCollectionGallery }: CollectionGalleryProps) => {
  const { addLineSVG, addRectSVG } = usePDFObjectsManager();

  const handleSvgClick = (type: string) => {
    if (type === 'line') {
      addLineSVG();
    } else if (type === 'rect') {
      addRectSVG();
    }
    toggleCollectionGallery();
  };

  return (
    <div
      className='bg-gray-800 border-r border-gray-300 flex flex-col h-full items-center py-20 px-0'
      style={{
        minWidth: '19rem',
        maxWidth: '19rem',
        height: 'calc(100vh - 4rem)',
        top: 0,
        overflowY: 'auto',
      }}
    >
      <div className='overflow-auto' style={{ maxHeight: 'calc(100vh - 4rem)' }}>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-1' style={{ padding: 0, margin: 0 }}>
          {svgComponents.map((svgMarkup, index) => (
            <button
              key={index}
              onClick={() => handleSvgClick(svgMarkup.type)}
              className='focus:outline-none'
              style={{ padding: 0, margin: 0 }}
            >
              <div
                className='cursor-pointer flex justify-center items-center'
                style={{
                  minHeight: '80px',
                  minWidth: '80px',
                  maxWidth: '80px',
                  maxHeight: '80px',
                  overflow: 'visible',
                  padding: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                dangerouslySetInnerHTML={{ __html: svgMarkup.content }}
              >
                {/* SVG is inserted here */}
              </div>
            </button>
          ))}
        </div>
      </div>
      <button
        className='mt-4 bg-blue-500 text-white py-2 px-4 rounded-full'
        onClick={toggleCollectionGallery}
      >
        Close
      </button>
    </div>
  );
};

export default ShapesGallery;
