enum PageSize {
  A4 = 'A4',
  LETTER = 'Letter',
  LEGAL = 'Legal',
  CUSTOM = 'Custom',
}
//in px
const PageSizeDimensions = {
  [PageSize.A4]: {
    width: 794,
    height: 1123,
  },
  [PageSize.LETTER]: {
    width: 612,
    height: 792,
  },
  [PageSize.LEGAL]: {
    width: 612,
    height: 1008,
  },
  [PageSize.CUSTOM]: {
    width: 612,
    height: 792,
  },
};

enum Orientation {
  PORTRAIT = 'Portrait',
  LANDSCAPE = 'Landscape',
}

export { PageSize, PageSizeDimensions, Orientation };
