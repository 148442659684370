import { RectSVGData } from '../../../types/PDFObjects';
import usePDFStore from '../../../stores/usePDFStore';

interface RectSVGToolsProps {
  rectSVGData: RectSVGData;
}

const RectSVGTools = ({ rectSVGData }: RectSVGToolsProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);

  return (
    <div className='flex items-center py-5' data-testid='textbox-tools'>
      <input
        type='color'
        className='px-2 h-8 w-14 block bg-white cursor-pointer disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700'
        id='hs-color-input'
        value={rectSVGData.fill}
        title='Choose your color'
        onChange={e =>
          updatePDFObject<RectSVGData>(rectSVGData.id, {
            fill: e.target.value,
          })
        }
      ></input>
    </div>
  );
};

export default RectSVGTools;
