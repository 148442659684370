import { Alert } from '@material-tailwind/react';
import { MdWarning } from 'react-icons/md';
import { useAPIErrorContext } from '../../contexts/hooks/useAPIErrorContex';

interface ErrorNotificationProps {
  message: string;
  isVisible: boolean;
}

const ErrorNotification = ({ message, isVisible }: ErrorNotificationProps) => {
  const { setError } = useAPIErrorContext();

  return (
    <div className={`fixed top-5 right-5 z-[99999] transition-opacity duration-300 ease-out`}>
      <Alert
        color='red'
        className='w-72 shadow-lg rounded-lg flex justify-between items-start'
        open={isVisible}
        onClose={() => setError(null)}
      >
        <div className='flex items-center'>
          <div className='flex-row mx-1'>
            <MdWarning size={30} />
          </div>
          <span className='text-sm font-medium ml-2'>{message}</span>
        </div>
      </Alert>
    </div>
  );
};

export default ErrorNotification;
