import React, { useCallback, useEffect, useRef, useState } from 'react'; // Adjust this import based on your actual type definitions
import { PDFObject } from '../../../types/PDFObjects';
import useDraggable from '../../../hooks/pdf/useDraggable';
import usePDFStore from '../../../stores/usePDFStore';

interface PDFObjectWrapperProps {
  children: React.ReactNode;
  pdfObjectData: PDFObject;
}

const PDFObjectWrapper = ({ children, pdfObjectData }: PDFObjectWrapperProps) => {
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);

  const [position, setPosition] = useState({
    x: pdfObjectData.position.x,
    y: pdfObjectData.position.y,
  });
  const objectRef = useRef<HTMLDivElement>(null);

  const handleDragEnd = useCallback(
    (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      updatePDFObject(id, {
        position,
        acknowledged: false,
        acknowledgementId: null,
        localLastUpdated: Date.now(),
      });
    },
    [updatePDFObject]
  );

  const { dragging, startDrag } = useDraggable({
    x: pdfObjectData.position.x,
    y: pdfObjectData.position.y,
    id: pdfObjectData.id,
    updatePosition: position => {
      setPosition(position);
    },
    onDragEnd: (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      handleDragEnd(id, position);
    },
  });

  useEffect(() => {
    setPosition(pdfObjectData.position);
  }, [pdfObjectData.position]);

  const positionStyle = {
    transform: `translate(${position.x}px, ${position.y}px)`,
    width: `${pdfObjectData.size.width}px`,
    maxWidth: `${pdfObjectData.size.width}px`,
    height: `${pdfObjectData.size.height}px`,
    maxHeight: `${pdfObjectData.size.height}px`,
  };

  return (
    <div
      ref={objectRef}
      onMouseDown={e => {
        e.stopPropagation();
        setCurrentSelections([pdfObjectData.id]);
        startDrag(e);
      }}
      className={pdfObjectData.isSelected ? '' : 'pdfComponent'}
      style={{
        ...positionStyle,
        cursor: dragging ? 'grabbing' : 'grab',
        position: 'absolute',
        display: 'inline-block',
        overflow: 'visible',
        outline: `${pdfObjectData.isSelected ? '2px dashed blue' : ''}`,
        zIndex: `${pdfObjectData.zIndex}`,
      }}
    >
      {children}
    </div>
  );
};

export default PDFObjectWrapper;
