// hooks/useGoogleAuth.ts
import { useGoogleLogin } from '@react-oauth/google';
import { useState } from 'react';

export interface UseGoogleAuthReturn {
  initiateGoogleLogin: () => void;
  codeResponse: string | null;
  error: string | null;
  setCodeResponse: (code: string | null) => void;
}

export const useGoogleAuth = (): UseGoogleAuthReturn => {
  const [codeResponse, setCodeResponse] = useState<string | null>(null); // State to store the codeResponse
  const [error, setError] = useState<string | null>(null); // State to store any error

  const initiateGoogleLogin = useGoogleLogin({
    onSuccess: async codeResponse => {
      setCodeResponse(codeResponse.code); // Store the entire response or just the code as needed
    },
    onError: error => {
      setError(
        error.error_description ??
          'There was an error with Google. Please try again. If the problem persists, contact customer support.'
      );
    },
    flow: 'auth-code',
  });

  return { initiateGoogleLogin, codeResponse, error, setCodeResponse };
};
