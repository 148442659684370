import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useResetPassword = () => {
  const { makeCall, response, isLoading, error } = useApiCall<object>();
  const { userService } = useAPIContext();
  const resetPassword = async (token: string, password: string) => {
    await makeCall(signal => userService.resetPassword(token, password, signal));
  };
  return { isLoading, error, response, resetPassword };
};
