import { AnalyticsService, IAnalyticsService } from './analytics/AnalyticService';
import { ApiKeyService, IApiKeyService } from './apiKey/ApiKeyService';
import { AuthService, IAuthService } from './auth/AuthService';
import { IPDFService, PDFService } from './pdfs/PDFService';
import { IQRCodesService, QRCodesService } from './qrCodes/QRCodesService';
import { ISVGIconsService, SVGIconsService } from './svgIcons/SVGIconsService';
import { ITemplateService, TemplateService } from './templates/TemplateService';
import { IUploadsService, UploadsService } from './uploads/UploadsService';
import { IUserService, UserService } from './user/UserService';

const analyticsService: IAnalyticsService = AnalyticsService.getInstance();
const authService: IAuthService = AuthService.getInstance();
const pdfService: IPDFService = PDFService.getInstance();
const qrCodeService: IQRCodesService = QRCodesService.getInstance();
const svgIconService: ISVGIconsService = SVGIconsService.getInstance();
const templateService: ITemplateService = TemplateService.getInstance();
const uploadService: IUploadsService = UploadsService.getInstance();
const userService: IUserService = UserService.getInstance();
const apiKeyService: IApiKeyService = ApiKeyService.getInstance();

export default {
  analyticsService,
  authService,
  pdfService,
  qrCodeService,
  svgIconService,
  templateService,
  uploadService,
  userService,
  apiKeyService,
};
