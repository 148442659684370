import React, { useEffect, useState } from 'react';
import { PDFObjectType } from '../../../enums/PDFObjects';
import usePDFStore from '../../../stores/usePDFStore';
import {
  MdOutlineEdit,
  MdOutlineHorizontalRule,
  MdOutlineQrCode,
  MdOutlineSquare,
  MdOutlineTableRestaurant,
  MdOutlineTableRows,
  MdOutlineTextFields,
} from 'react-icons/md';
import {
  IconSVGData,
  ImageData,
  PDFObject,
  QRCodeData,
  TableData,
  TextBoxData,
} from '../../../types/PDFObjects';

const LayersPanel = () => {
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const updatePDFObjects = usePDFStore(state => state.updatePDFObjects);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);

  const currentPage = usePDFStore(state => state.currentPage);
  const [draggingId, setDraggingId] = useState<string | null>(null);
  const onDragStart = (event: React.DragEvent<HTMLLIElement>, id: string) => {
    if (event.target === event.currentTarget) {
      setDraggingId(id);
      event.dataTransfer.effectAllowed = 'move';
    } else {
      event.preventDefault(); // Prevent dragging if not the intended target
    }
    setDraggingId(id);
  };

  const [currentPDFObjects, setCurrentPDFObjects] = useState(pdfObjects);
  const [currentHoverId, setCurrentHoverId] = useState<string | null>(null);
  const [editableId, setEditableId] = useState<string | null>(null); // State to track which item is being edited
  const [editableName, setEditableName] = useState(''); // State to track the editable name value
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);

  useEffect(() => {
    let currentPDFObjects = pdfObjects.filter(obj => obj.page === currentPage);
    currentPDFObjects = currentPDFObjects.sort((a, b) => a.zIndex - b.zIndex);
    setCurrentPDFObjects(currentPDFObjects);
  }, [pdfObjects, currentPage]);

  const onDragEnd = (event: React.DragEvent<HTMLLIElement>) => {
    event.currentTarget.style.opacity = '1';

    if (currentHoverId === draggingId) {
      setDraggingId(null);
      setCurrentHoverId(null);
      return;
    }

    // Find the current and target objects based on their IDs
    const currentObj = pdfObjects.find(obj => obj.id === draggingId);
    const targetObj = pdfObjects.find(obj => obj.id === currentHoverId);

    // Early exit if either object is not found
    if (!currentObj || !targetObj) {
      setDraggingId(null);
      setCurrentHoverId(null);
      return;
    }

    // Calculate the new zIndex for the current object
    const currentZIndex = currentObj.zIndex;
    const targetZIndex = targetObj.zIndex;

    const moveUp = currentZIndex > targetZIndex;

    if (moveUp) {
      if (currentZIndex - targetZIndex === 1) {
        setDraggingId(null);
        setCurrentHoverId(null);
        return;
      }
    }

    // Update zIndex for all objects
    const updatedObjects = pdfObjects.map(obj => {
      if (moveUp) {
        // Moving up: Increase zIndex for objects between target and current (exclusive)
        if (obj.zIndex > targetZIndex && obj.page === currentPage && obj.id !== draggingId) {
          return { ...obj, zIndex: obj.zIndex + 1 };
        }
        if (obj.id === draggingId) {
          return { ...obj, zIndex: targetZIndex + 1 };
        }
      } else {
        // Moving down: Decrease zIndex for objects between current and target (exclusive)
        if (
          obj.zIndex <= targetZIndex &&
          obj.zIndex > currentZIndex &&
          obj.page === currentPage &&
          obj.id !== draggingId
        ) {
          return { ...obj, zIndex: obj.zIndex - 1 };
        }
        if (obj.id === draggingId) {
          return { ...obj, zIndex: targetZIndex };
        }
      }
      return obj;
    });

    updatePDFObjects(updatedObjects); // Make sure you implement this method in your context

    setDraggingId(null);
    setCurrentHoverId(null);
  };

  const getIcon = (type: PDFObjectType, pdfObject: PDFObject) => {
    switch (type) {
      case PDFObjectType.TABLE:
        const tableData = pdfObject as TableData;
        if (tableData.isDynamic) {
          return <MdOutlineTableRestaurant size={20} />;
        }
        return <MdOutlineTableRows size={20} />;
      case PDFObjectType.TEXTBOX:
        const textBoxData = pdfObject as TextBoxData;
        if (textBoxData.isDynamic) {
          return <MdOutlineTextFields size={20} color='blue' />;
        }
        return <MdOutlineTextFields size={20} />;
      case PDFObjectType.ICON_SVG:
        const iconSVGData = pdfObject as IconSVGData;
        return (
          <div
            style={{
              width: '20px',
              height: '20px',
            }}
            dangerouslySetInnerHTML={{
              __html: iconSVGData.content.replace(/\$\$\$\$\$/g, iconSVGData.color),
            }}
          />
        );
      case PDFObjectType.RECT_SVG:
        return <MdOutlineSquare size={20} />;
      case PDFObjectType.LINE_SVG:
        return <MdOutlineHorizontalRule size={20} />;
      case PDFObjectType.QR_CODE:
        const qrCodeData = pdfObject as QRCodeData;
        if (qrCodeData.isDynamic) {
          return <MdOutlineQrCode size={20} color='blue' />;
        }
        return <MdOutlineQrCode size={20} />;
      case PDFObjectType.IMAGE:
        const imageObject = pdfObject as ImageData;
        return (
          <div
            style={{
              width: '28px',
              height: '28px',
            }}
          >
            <img
              src={imageObject.src}
              alt='img'
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditableName(event.target.value);
  };

  const handleNameEdit = (obj: PDFObject) => {
    setEditableId(obj.id);
    setEditableName(obj.displayName);
  };

  const handleNameSubmit = (id: string) => {
    updatePDFObject(id, { displayName: editableName });
    setEditableId(null);
  };

  const handleKeyPress = (event: React.KeyboardEvent, id: string) => {
    if (event.key === 'Enter') {
      handleNameSubmit(id);
    } else if (event.key === 'Escape') {
      setEditableName(''); // Clear any changes
      setEditableId(null); // Exit edit mode without saving
    }
  };

  return (
    <div className='w-full mx-auto mt-10 px-2'>
      <ul className='space-y-1'>
        {currentPDFObjects.map((item, index) => (
          <React.Fragment key={`${item.id}-fragment`}>
            <li
              key={item.id}
              onClick={() => setCurrentSelections([item.id])}
              draggable
              onDragStart={e => onDragStart(e, item.id)}
              onDragEnd={e => onDragEnd(e)}
              className={`flex justify-left cursor-move text-sm li-vertical-move ${item.isSelected ? 'bg-blue-100' : ''}`}
              style={{
                maxWidth: '250px',
                minWidth: '250px',
                minHeight: '30px',
                overflow: 'hidden',
                maxHeight: '30px',
                marginTop: currentHoverId === currentPDFObjects[index - 1]?.id ? '20px' : '0', // Move right if previous item is hovered
                marginBottom: currentHoverId === currentPDFObjects[index + 1]?.id ? '20px' : '0', // Move left if next item is hovered
              }}
            >
              <div className='flex items-center w-full mx-5'>
                <div>{getIcon(item.type as PDFObjectType, item)}</div>
                {editableId === item.id ? (
                  <input
                    type='text'
                    value={editableName}
                    onChange={handleNameChange}
                    onBlur={() => handleNameSubmit(item.id)}
                    onKeyDown={event => handleKeyPress(event, item.id)}
                    className='flex-1 border-b-2 border-gray-500 bg-gray-100 focus:outline-none focus:border-blue-500'
                    autoFocus
                  />
                ) : (
                  <>
                    <div
                      className='ml-5 flex-1 text-[13px]'
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: '160px',
                        minWidth: '160px',
                      }}
                    >
                      {item.displayName}
                    </div>
                    <div
                      onClick={() => handleNameEdit(item)}
                      className='hover:bg-gray-300 cursor-pointer w-8 h-8 rounded-full flex items-center justify-center'
                    >
                      <MdOutlineEdit />
                    </div>
                  </>
                )}
              </div>
            </li>
            <li key={`${item.id}-drop`}>
              <div
                onDragOver={() => {
                  setCurrentHoverId(item.id);
                }}
                style={{
                  minHeight: '5px',
                }}
                className={`flex w-full ${currentHoverId === item.id ? 'bg-indigo-400' : 'white'}`}
              >
                {' '}
              </div>
            </li>
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default LayersPanel;
