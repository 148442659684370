const parseSVGSize = (svgContent: string) => {
  const parser = new DOMParser();
  const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
  const svgElement = svgDoc.documentElement;

  // Attempt to get width and height from attributes
  const width = svgElement.getAttribute('width'); // Default to 200 if not specified
  const height = svgElement.getAttribute('height'); // Default to 200 if not specified
  const viewBox = svgElement.getAttribute('viewBox');

  // If width or height are percentages, fallback or additional handling may be required

  return { width, height, viewBox };
};
export { parseSVGSize };
