import Navbar from '../layout/NavBar';
import LeftToolbar from './LeftToolbar/LeftToolbar';
import TopToolbar from './TopToolbar/TopToolbar';
import ObjectEditorPanel from './ObjectEditorPanel/ObjectEditorPanel';
import PDFPage from './PDFPage';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { useParams } from 'react-router-dom';
import { useGetTemplate } from '../../hooks/api/templates/useGetByIdTemplate';
import PageOverlayLoader from '../ui/PageOverlayLoader';
import { useAutoSave } from '../../hooks/pdf/useAutoSave';
import useKeyBindings from '../../hooks/pdf/useKeyBindings';
import ErrorPage from '../ui/ErrorPage';
import usePDFStore from '../../stores/usePDFStore';
import { PDFObject } from '../../types/PDFObjects';
import { Socket, io } from 'socket.io-client';
import { v4 } from 'uuid';
import useRemoteCursors from '../../hooks/pdf/useRemoteCursors';
import useAutoScroll from '../../hooks/pdf/useAutoScroll';
import useConfirmExit from '../../hooks/pdf/useConfirmExit';

const PDFEditor = () => {
  const { jwt, user } = useUserContext();
  const { templateId: paramTemplateId } = useParams();
  const { getTemplate, response, error } = useGetTemplate();

  const {
    template,
    setTemplate,
    reset,
    updateScale,
    scale,
    pdfObjects,
    currentPage,
    pages,
    setPages,
    setCurrentPage,
    removePDFObjects,
    updateRemotePDFObjects,
    updatePDFObjectsAcknowledgmentId,
    deletedObjects,
  } = usePDFStore(state => ({
    template: state.template,
    setTemplate: state.setTemplate,
    reset: state.reset,
    updateScale: state.updateScale,
    scale: state.settings.scale,
    pdfObjects: state.pdfObjects,
    currentPage: state.currentPage,
    pages: state.pages,
    setPages: state.setPages,
    setCurrentPage: state.setCurrentPage,
    removePDFObjects: state.removePDFObjects,
    updateRemotePDFObjects: state.updateRemotePDFObjects,
    updatePDFObjectsAcknowledgmentId: state.updatePDFObjectsAcknowledgmentId,
    deletedObjects: state.deleteObjects,
  }));

  const [currentSocket, setCurrentSocket] = useState<Socket | null>(null);
  const lastUpdateSent = useRef(Date.now());
  const pdfObjectsRef = useRef(pdfObjects);

  useAutoScroll();
  useConfirmExit();
  useAutoSave();
  useKeyBindings();

  const { cursors, onMouseMove } = useRemoteCursors({
    socket: currentSocket,
    scale,
    user,
  });

  useEffect(() => {
    pdfObjectsRef.current = pdfObjects;
  }, [pdfObjects]);

  useEffect(() => {
    if (currentSocket) {
      if (Date.now() - lastUpdateSent.current > 50) {
        lastUpdateSent.current = Date.now();

        const acknowledgements: {
          id: string;
          lastUpdated: number;
          acknowledgementId: string;
        }[] = [];
        const acknowledgementId = v4();
        let newData = false;
        pdfObjects.map(obj => {
          if (obj.acknowledged === false && obj.acknowledgementId === null) {
            newData = true;
            acknowledgements.push({
              id: obj.id,
              lastUpdated: obj.localLastUpdated,
              acknowledgementId,
            });
          }
          return obj;
        });
        if (newData) {
          updatePDFObjectsAcknowledgmentId(acknowledgements);
          currentSocket.emit('update-pdf-objects', {
            pdfObjects,
            acknowledgementId,
          });
        }
      }
    }
  }, [pdfObjects, currentSocket, updatePDFObjectsAcknowledgmentId]);

  useEffect(() => {
    if (currentSocket) {
      if (deletedObjects.size > 0) {
        console.log('sending delete');
        currentSocket.emit('delete-pdf-objects-update', Array.from(deletedObjects.keys()));
      }
    }
  }, [deletedObjects, currentSocket]);

  useEffect(() => {
    const newSocket = io(import.meta.env.VITE_API_BASE_URL + '/rooms', {
      query: {
        templateId: paramTemplateId,
      },
      auth: {
        token: jwt,
      },
    });
    setCurrentSocket(newSocket);
    console.log(newSocket);

    newSocket.on('connected', () => {
      console.log('my socket id is', newSocket.id);

      newSocket.on('chat-message', ({ message, name }) => {
        console.log('chat-message', message, name);
      });
      newSocket.on(
        'pdf-objects',
        (data: { pdfObjects: PDFObject[]; acknowledgementIds: string[] }) => {
          const updatedObjects = data.pdfObjects.map(obj => {
            const existingObject = pdfObjectsRef.current.find(o => o.id === obj.id);

            obj.isSelected = existingObject?.isSelected;
            return obj;
          });
          updateRemotePDFObjects(updatedObjects, data.acknowledgementIds);
        }
      );

      newSocket.on('delete-pdf-objects', (data: { deletedPDFObjectIds: string[] }) => {
        console.log('delete-pdf-objects', data.deletedPDFObjectIds);
        removePDFObjects(data.deletedPDFObjectIds);
      });

      newSocket.on('lobby-joined', ({ id }) => {
        console.log(`Joined lobby with ID: ${id}`);
      });

      newSocket.on('player-kicked', (socketId: string) => {
        console.log(`Player with socket ID ${socketId} was kicked`);
      });

      newSocket.on('kicked-from-lobby', () => {
        newSocket.close();
      });

      newSocket.on('lobby-full', () => {
        newSocket.close();
      });

      newSocket.on('no-lobby', () => {
        newSocket.close();
      });
    });

    return () => {
      newSocket.close();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getTemplate(paramTemplateId!, jwt!);
    return () => {
      reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response && response.content) {
      const pdfData = JSON.parse(response.content);
      const pdfObjects = [
        ...pdfData.textBoxes,
        ...pdfData.svgs,
        ...pdfData.tables,
        ...pdfData.lineSVGs,
        ...pdfData.rectSVGs,
        ...pdfData.iconSVGs,
        ...(pdfData.qrCodes ?? []),
        ...(pdfData.images ?? []),
      ];

      //filter through the pages and get the unique pages
      const uniquePages = pdfObjects.reduce((acc: number[], obj) => {
        if (!acc.includes(obj.page)) {
          acc.push(obj.page);
        }
        return acc;
      }, []);
      setPages(uniquePages);
      setCurrentPage(uniquePages[0]);
      setTemplate(response.templateId, response.name, pdfObjects);
    } else if (response) {
      setTemplate(response.templateId, response.name, []);
    }
  }, [response, setTemplate, setPages, setCurrentPage]);

  const handleUpdateScale = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10) / 10;
    updateScale(value);
    event.target.blur();
  };

  const memoizedPdfObjectsByPage = useMemo(() => {
    return pages.map(page => pdfObjects.filter(obj => obj.page === page));
  }, [pdfObjects, pages]);

  return (
    <>
      <div className='flex flex-col'>
        <Navbar />
        {!template.isLoaded && !error && <PageOverlayLoader message='Loading PDF' />}
        {error && <ErrorPage />}
        {template.isLoaded && (
          <div className='flex flex-row h-full'>
            <LeftToolbar />
            <div className='w-full overflow-auto'>
              <TopToolbar />
              <div
                className='flex flex-row w-full h-full'
                style={{
                  maxHeight: 'calc(100vh - 163px)',
                  minHeight: 'calc(100vh - 163px)',
                  maxWidth: 'calc(100vw - 64px)',
                }}
              >
                {' '}
                <div
                  id='pdf-editor'
                  className='flex-col overflow-auto bg-gray-200'
                  style={{
                    flex: '1',
                    maxHeight: 'calc(100vh - 163px)',
                    minHeight: 'calc(100vh - 163px)',
                    overflow: 'auto',
                    maxWidth: 'calc(100vw - 64px)',
                  }}
                >
                  {pages.map((page, index) => (
                    <PDFPage
                      key={page}
                      isTemplateLoaded={template.isLoaded}
                      pdfObjects={memoizedPdfObjectsByPage[index]}
                      pageNumber={page}
                      currentSocket={currentSocket}
                      cursors={cursors.filter(cursor => cursor.pageNumber === page)}
                      onMouseMove={onMouseMove}
                    />
                  ))}
                </div>
                <ObjectEditorPanel />
              </div>
              <div
                className='bg-white w-full flex justify-between shadow-xl border-t border-gray-300'
                style={{
                  height: '35px',
                }}
              >
                <div className='flex items-center pl-5'>
                  <input
                    type='range'
                    min='2'
                    max='40'
                    step='1'
                    onChange={handleUpdateScale}
                    defaultValue={scale * 10}
                  />
                  <span className='px-2'> {(scale * 100).toFixed(0)}% </span>
                </div>
                <div className='flex items-center pr-5'>
                  <button className='hover:bg-gray-200 rounded'>
                    <span className='text-md font-bold text-gray-800 px-5'>
                      {currentPage &&
                        `Page ${pages.findIndex(p => p === currentPage) + 1} / ${pages.length}`}
                    </span>
                  </button>
                </div>
                <div className='flex items-center pr-5'>
                  <span className='text-sm text-gray-800 px-5'>{''}</span>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PDFEditor;
