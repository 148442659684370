import React, { createContext } from 'react';

export interface IAPIErrorContext {
  error: string | null;
  setError: (error: string | null) => void;
}

export const APIErrorContext = createContext<IAPIErrorContext | null>(null);

interface APIErrorProviderProps {
  children: React.ReactNode;
}

export const APIErrorProvider = ({ children }: APIErrorProviderProps) => {
  const [error, setError] = React.useState<string | null>(null);

  return (
    <APIErrorContext.Provider value={{ error, setError }}>{children}</APIErrorContext.Provider>
  );
};
