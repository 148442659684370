import { GoogleOAuthProvider } from '@react-oauth/google';
import { UserProvider } from './contexts/UserContext';
import Router from './Router';
import { LocalStorage } from './infrastructure/LocalStorage';
import { JWTService } from './services/JWTService';
import ErrorNotification from './components/ui/ErrorNotifcation';
import { useAPIErrorContext } from './contexts/hooks/useAPIErrorContex';

const localStorage = new LocalStorage();
const jwtService = new JWTService();

function App() {
  const { error } = useAPIErrorContext();
  return (
    <UserProvider storageService={localStorage} jwtService={jwtService}>
      <GoogleOAuthProvider clientId='731434345096-9qe9lpl1sueckf6hru244ma4fpfrnjrj.apps.googleusercontent.com'>
        <ErrorNotification message={error ?? ''} isVisible={error !== null ? true : false} />
        <Router />
      </GoogleOAuthProvider>
    </UserProvider>
  );
}

export default App;
