import { FcGoogle } from 'react-icons/fc';

interface GoogleLoginButtonProps {
  onClick: () => void;
}

const GoogleLoginButton = ({ onClick }: GoogleLoginButtonProps) => {
  return (
    <div className='mb-4'>
      <button
        onClick={onClick}
        data-testid='google-login-button'
        className='flex items-center justify-center w-full px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
      >
        <FcGoogle className='mr-2 text-2xl' />
        <span>Continue with Google</span>
      </button>
    </div>
  );
};

export default GoogleLoginButton;
