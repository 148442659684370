import { AnalyticsEvent } from '../../../api/analytics/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useGetAll = () => {
  const { makeCall, response, isLoading, error } = useApiCall<AnalyticsEvent[]>();
  const { analyticsService } = useAPIContext();

  const getAll = async (jwt: string) => {
    await makeCall(signal => {
      return analyticsService.getAll(jwt, signal);
    });
  };

  return { getAll, response, isLoading, error };
};
