import { Tooltip } from '@material-tailwind/react';
import { GoDuplicate, GoKebabHorizontal, GoTrash } from 'react-icons/go';

interface ObjectMenuBarProps {
  x: number;
  y: number;
  scale: number;
  height: number;
  width: number;
}

const ObjectMenuBar = ({ x, y, scale, height, width }: ObjectMenuBarProps) => {
  return (
    <div
      style={{
        position: 'absolute',
        transform: `translate(${x + width / 2 - (60 * 1) / scale}px, ${y + height + 20}px) scale(${1 / scale})`,
        transformOrigin: 'top left',
        height: '50px',
        width: '120px',
        zIndex: 9999,
      }}
      className=' bg-white p-2 rounded shadow-lg border-2 border-gray-200'
    >
      <div className='flex flex-row justify-center'>
        <Tooltip content='Duplicate' placement='bottom'>
          <button className='hover:bg-gray-300 py-2 px-2 rounded'>
            <GoDuplicate size={20} />
          </button>
        </Tooltip>
        <Tooltip content='Delete' placement='bottom'>
          <button className='hover:bg-gray-300 py-2 px-2 rounded'>
            <GoTrash size={20} />
          </button>
        </Tooltip>
        <Tooltip content='More' placement='bottom'>
          <button className='hover:bg-gray-300 py-2 px-2 rounded'>
            <GoKebabHorizontal size={20} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default ObjectMenuBar;
