import api from '../api';
import { CreateTemplateRequest, Template, TemplateMember } from './types';
import { handleAxiosError } from '../helpers';
import { PermissionType } from './enums';

export interface ITemplateService {
  create(request: CreateTemplateRequest, jwt: string, signal: AbortSignal): Promise<Template>;
  get(jwt: string, signal: AbortSignal): Promise<Template[]>;
  updateContent(
    templateId: string,
    content: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<Template>;
  getById(templateId: string, jwt: string, signal: AbortSignal): Promise<Template>;
  delete(templateId: string, jwt: string, signal: AbortSignal): Promise<boolean>;
  bulkDelete(templateIds: string[], jwt: string, signal: AbortSignal): Promise<boolean>;
  addMember(
    templateId: string,
    userId: string,
    permissionType: PermissionType,
    jwt: string,
    signal: AbortSignal
  ): Promise<TemplateMember>;
  removeMember(
    templateId: string,
    templateMemberId: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<boolean>;
  updateMember(
    templateId: string,
    templateMemberId: string,
    permissionType: PermissionType,
    jwt: string,
    signal: AbortSignal
  ): Promise<TemplateMember>;
}

export class TemplateService implements ITemplateService {
  private static instance: TemplateService;

  public static getInstance(): TemplateService {
    if (!TemplateService.instance) {
      TemplateService.instance = new TemplateService();
    }
    return TemplateService.instance;
  }

  public async create(
    request: CreateTemplateRequest,
    jwt: string,
    signal: AbortSignal
  ): Promise<Template> {
    try {
      const response = await api.post('/templates', request, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwt}`,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async get(jwt: string, signal: AbortSignal): Promise<Template[]> {
    try {
      const response = await api.get('/templates', {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async updateContent(
    templateId: string,
    content: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<Template> {
    try {
      const response = await api.put(`/templates/${templateId}/content`, content, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${jwt}`,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async getById(templateId: string, jwt: string, signal: AbortSignal): Promise<Template> {
    try {
      const response = await api.get(`/templates/${templateId}`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async delete(templateId: string, jwt: string, signal: AbortSignal): Promise<boolean> {
    try {
      await api.delete(`/templates/${templateId}`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });

      return true;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async bulkDelete(
    templateIds: string[],
    jwt: string,
    signal: AbortSignal
  ): Promise<boolean> {
    try {
      await api.post(
        `/templates/bulk-delete`,
        { templateIds },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${jwt}`,
          },
          signal,
        }
      );

      return true;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async addMember(
    templateId: string,
    userId: string,
    permissionType: PermissionType,
    jwt: string,
    signal: AbortSignal
  ): Promise<TemplateMember> {
    try {
      const member = await api.post(
        `/templates/${templateId}/members`,
        { userId, permission: permissionType },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${jwt}`,
          },
          signal,
        }
      );

      return member.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async removeMember(
    templateId: string,
    templateMemberId: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<boolean> {
    try {
      await api.delete(`/templates/${templateId}/members/${templateMemberId}`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });

      return true;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async updateMember(
    templateId: string,
    templateMemberId: string,
    permissionType: PermissionType,
    jwt: string,
    signal: AbortSignal
  ): Promise<TemplateMember> {
    try {
      const result = await api.put(
        `/templates/${templateId}/members/${templateMemberId}`,
        { permission: permissionType },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `${jwt}`,
          },
          signal,
        }
      );

      return result.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
