interface AuthPageContainerProps {
  children: React.ReactNode;
}

const AuthPageContainer = ({ children }: AuthPageContainerProps) => {
  return (
    <div className='flex items-center justify-center min-h-screen bg-indigo-900 p-2'>
      {' '}
      {children}
    </div>
  );
};

export default AuthPageContainer;
