import { Template } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useGetTemplate = () => {
  const { makeCall, response, isLoading, error } = useApiCall<Template>();
  const { templateService } = useAPIContext();

  const getTemplate = async (templateId: string, jwt: string) => {
    await makeCall(signal => {
      return templateService.getById(templateId, jwt, signal);
    });
  };

  return { getTemplate, response, isLoading, error };
};
