import { useAPIContext } from '../../../contexts/hooks/useAPIContext';

import { useApiCall } from '../helpers/useApiCall';

export const useUploadFiles = () => {
  const { makeCall, response, isLoading, error } = useApiCall<{
    qrCode: string;
    text: string;
  }>();
  const { uploadService } = useAPIContext();

  const uploadFiles = async (token: string, fileUploads: Blob[]) => {
    await makeCall(signal => {
      return uploadService.upload(token, fileUploads, signal);
    });
  };

  return { uploadFiles, response, isLoading, error };
};
