// src/contexts/ServiceContext.js
import React, { createContext } from 'react';
import { IAnalyticsService } from '../api/analytics/AnalyticService';
import { IAuthService } from '../api/auth/AuthService';
import { IQRCodesService } from '../api/qrCodes/QRCodesService';
import { IPDFService } from '../api/pdfs/PDFService';
import { ISVGIconsService } from '../api/svgIcons/SVGIconsService';
import { ITemplateService } from '../api/templates/TemplateService';
import { IUserService } from '../api/user/UserService';
import { IUploadsService } from '../api/uploads/UploadsService';
import { IApiKeyService } from '../api/apiKey/ApiKeyService';

export interface IAPIContext {
  analyticsService: IAnalyticsService;
  authService: IAuthService;
  pdfService: IPDFService;
  qrCodeService: IQRCodesService;
  svgIconService: ISVGIconsService;
  templateService: ITemplateService;
  uploadService: IUploadsService;
  userService: IUserService;
  apiKeyService: IApiKeyService;
}

export const APIContext = createContext<IAPIContext | null>(null);

interface APIProviderProps {
  children: React.ReactNode;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  apis: IAPIContext;
}

export const APIProvider = ({ children, apis }: APIProviderProps) => {
  return <APIContext.Provider value={apis}>{children}</APIContext.Provider>;
};
