import { useCallback, useEffect, useRef, useState } from 'react';
import { PDFObjectType } from '../../enums/PDFObjects';
import usePDFStore from '../../stores/usePDFStore';
import { PDFObject } from '../../types/PDFObjects';

function lineIntersects(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  x3: number,
  y3: number,
  x4: number,
  y4: number
) {
  // Calculate the direction of the lines
  const uA =
    ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) /
    ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));
  const uB =
    ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) /
    ((y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1));

  // If uA and uB are between 0-1, lines are colliding
  return uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1;
}

function checkLineRectangleIntersection(
  line: {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
  },
  rect: {
    left: number;
    top: number;
    widthSelect: number;
    heightSelect: number;
  }
) {
  const { x1, y1, x2, y2 } = line; // Line coordinates
  const { left, top, widthSelect, heightSelect } = rect; // Rectangle coordinates
  const right = left + widthSelect;
  const bottom = top + heightSelect;

  const isLineCompletelyInside =
    x1 >= left &&
    x1 <= right &&
    y1 >= top &&
    y1 <= bottom &&
    x2 >= left &&
    x2 <= right &&
    y2 >= top &&
    y2 <= bottom;

  if (isLineCompletelyInside) {
    return true;
  }

  // Check each edge of the rectangle for intersection with the line
  if (
    lineIntersects(x1, y1, x2, y2, left, top, left + widthSelect, top) || // Top edge
    lineIntersects(
      x1,
      y1,
      x2,
      y2,
      left,
      top + heightSelect,
      left + widthSelect,
      top + heightSelect
    ) || // Bottom edge
    lineIntersects(x1, y1, x2, y2, left, top, left, top + heightSelect) || // Left edge
    lineIntersects(x1, y1, x2, y2, left + widthSelect, top, left + widthSelect, top + heightSelect) // Right edge
  ) {
    return true;
  }

  return false;
}
interface UseMultiSelectPreviewBoxProps {
  pdfObjects: PDFObject[];
}
const useMultiSelectPreviewBox = ({ pdfObjects }: UseMultiSelectPreviewBoxProps) => {
  const [startPos, setStartPos] = useState({ x: 0, y: 0 });
  const [endPos, setEndPos] = useState({ x: 0, y: 0 });
  const [isSelecting, setIsSelecting] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const startPosRef = useRef({ x: 0, y: 0 });
  const endPosRef = useRef({ x: 0, y: 0 });
  const lastUpdate = useRef(Date.now());
  const scale = usePDFStore(state => state.settings.scale);
  const [unscaledStartPos, setUnscaledStartPos] = useState({ x: 0, y: 0 });
  const [unscaledEndPos, setUnscaledEndPos] = useState({ x: 0, y: 0 });
  const numberCalledRef = useRef(0);
  const selectedRef = useRef<string[]>([]);

  const handleMouseDown = (e: React.MouseEvent) => {
    //use clientX and clientY to get the position of the mouse
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    setStartPos({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    setEndPos({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    });
    startPosRef.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    endPosRef.current = {
      x: e.clientX - rect.left,
      y: e.clientY - rect.top,
    };
    setUnscaledStartPos({
      x: (e.clientX - rect.left - offset.x) / scale,
      y: (e.clientY - rect.top - offset.y) / scale,
    });
    setUnscaledEndPos({
      x: (e.clientX - rect.left - offset.x) / scale,
      y: (e.clientY - rect.top - offset.y) / scale,
    });
    setIsSelecting(true);
    setIsDragging(true);
  };

  const onDrag = useCallback(
    (e: React.MouseEvent) => {
      if (!isSelecting) return;
      e.preventDefault();
      e.stopPropagation();
      const rect = e.currentTarget.getBoundingClientRect();
      endPosRef.current = {
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      };
      if (Date.now() - lastUpdate.current < 15) return;
      lastUpdate.current = Date.now();
      setEndPos({
        x: e.clientX - rect.left,
        y: e.clientY - rect.top,
      });
      setUnscaledEndPos({
        x: (e.clientX - rect.left - offset.x) / scale,
        y: (e.clientY - rect.top - offset.y) / scale,
      });
    },
    [isSelecting, offset, scale]
  );

  useEffect(() => {
    if (!isDragging) return;

    numberCalledRef.current += 1;
    const left = startPos.x > endPos.x ? endPos.x : startPos.x;
    const top = startPos.y > endPos.y ? endPos.y : startPos.y;
    const widthSelect = Math.abs(endPos.x - startPos.x);
    const heightSelect = Math.abs(endPos.y - startPos.y);

    const scaledRect = {
      x: (left - offset.x) / scale,
      y: (top - offset.y) / scale,
      width: widthSelect / scale,
      height: heightSelect / scale,
    };
    const newSelections = pdfObjects.filter(obj => {
      const { position, size } = obj;
      if (obj.type === PDFObjectType.LINE_SVG) {
        // Define the line from your object
        const line = {
          x1: obj.line.x1 + obj.position.x,
          y1: obj.line.y1 + obj.position.y,
          x2: obj.line.x2 + obj.position.x,
          y2: obj.line.y2 + obj.position.y,
        };

        // Check if the line intersects with the selection rectangle
        return checkLineRectangleIntersection(line, {
          left: scaledRect.x,
          top: scaledRect.y,
          widthSelect: scaledRect.width,
          heightSelect: scaledRect.height,
        });
      }

      if (obj.type === PDFObjectType.RECT_SVG) {
        // Define the rectangle from your object
        const rect = {
          left: obj.rect.x + obj.position.x,
          top: obj.rect.y + obj.position.y,
          widthSelect: obj.rect.width,
          heightSelect: obj.rect.height,
        };

        // Check if the rectangle intersects with the selection rectangle
        return (
          rect.left + rect.widthSelect > scaledRect.x &&
          rect.left < scaledRect.x + scaledRect.width &&
          rect.top + rect.heightSelect > scaledRect.y &&
          rect.top < scaledRect.y + scaledRect.height
        );
      }
      return (
        position.x < scaledRect.x + scaledRect.width &&
        position.x + size.width > scaledRect.x &&
        position.y < scaledRect.y + scaledRect.height &&
        position.y + size.height > scaledRect.y
      );
    });
    //check if the new selections are the same as the old selections
    const newIds = newSelections.map(obj => obj.id);
    const oldIds = selectedRef.current;
    //compare the two arrays
    if (newIds.length === oldIds.length && newIds.every((v, i) => v === oldIds[i])) {
      return;
    }
    if (newSelections.length === 1) {
      setCurrentSelections([newSelections[0].id]);
      selectedRef.current = [newSelections[0].id];
    } else {
      setCurrentSelections(newSelections.map(obj => obj.id));
      selectedRef.current = newSelections.map(obj => obj.id);
    }
  }, [endPos, offset, startPos, isDragging, pdfObjects, setCurrentSelections, scale]);

  const handleMouseMove = useCallback((e: React.MouseEvent) => onDrag(e), [onDrag]);

  useEffect(() => {
    if (!isDragging) return;

    // Define the global event listeners
    const handleMouseUpGlobal = () => {
      //TODO: Add logic to update the position one final time since it is rate limited
      setIsSelecting(false);
      setIsDragging(false);
    };

    const handleMouseLeaveGlobal = () => {
      //TODO: Add logic to update the position one final time since it is rate limited
      setIsSelecting(false);
      setIsDragging(false);
    };

    document.addEventListener('mouseup', handleMouseUpGlobal);
    document.addEventListener('mouseleave', handleMouseLeaveGlobal);

    // Cleanup global event listeners on component unmount
    return () => {
      document.removeEventListener('mouseup', handleMouseUpGlobal);
      document.removeEventListener('mouseleave', handleMouseLeaveGlobal);
    };
  }, [isDragging, onDrag, handleMouseMove]);

  return {
    startPos,
    endPos,
    isDragging,
    handleMouseDown,
    setOffset,
    handleMouseMove,
    unscaledEndPos,
    unscaledStartPos,
    offset,
  };
};

export default useMultiSelectPreviewBox;
