import { useEffect } from 'react';
import usePDFStore from '../../stores/usePDFStore';

const useAutoScroll = () => {
  const scrollToId = usePDFStore(state => state.scrollToId);
  const setScrollToId = usePDFStore(state => state.setScrollToId);

  useEffect(() => {
    if (scrollToId) {
      const observer = new MutationObserver((_mutations, obs) => {
        const element = document.getElementById(scrollToId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          setScrollToId(null);
          obs.disconnect(); // Stop observing once the element is found
        }
      });

      observer.observe(document.body, {
        childList: true, // observe direct children
        subtree: true, // and lower descendants too
        attributes: false,
      });

      return () => observer.disconnect(); // Clean up the observer when the component unmounts or ID changes
    }
  }, [scrollToId, setScrollToId]);
};

export default useAutoScroll;
