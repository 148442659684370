import { useEffect, useState } from 'react';
import { FcEngineering, FcGlobe, FcSupport } from 'react-icons/fc';

interface PageOverlayLoaderProps {
  message: string;
}

const FlashLoader = () => {
  return (
    <div className='mb-8 motion-safe:animate-spin'>
      <FcGlobe className='text-6xl text-indigo-600' />
    </div>
  );
};

const EngineeringLoader = () => {
  return (
    <div className='mb-8 motion-safe:animate-spin'>
      <FcEngineering className='text-6xl text-red-600' />
    </div>
  );
};

const ChargeLoader = () => {
  return (
    <div className='mb-8 motion-safe:animate-pulse'>
      <FcSupport className='text-6xl text-indigo-600' />
    </div>
  );
};

const PageOverlayLoader = ({ message }: PageOverlayLoaderProps) => {
  const loaders = [FlashLoader, ChargeLoader, EngineeringLoader];
  const [dots, setDots] = useState('.');
  const [currentLoader, setCurrentLoader] = useState(0);

  useEffect(() => {
    const loader = Math.floor(Math.random() * 3);
    setCurrentLoader(loader);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setDots(currentDots => {
        if (currentDots.length === 3) {
          return '.';
        }
        return currentDots + '.';
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={`absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center 
      justify-center bg-gray-100 bg-opacity-50`}
      style={{ backdropFilter: 'blur(8px)', zIndex: 300 }}
    >
      <div className='mb-4 text-lg font-semibold text-indigo-600'>
        {message ?? 'Preparing your PDF'}
        {dots}
      </div>
      {loaders[currentLoader]()}
    </div>
  );
};

export default PageOverlayLoader;
