import { useEffect, useState } from 'react';
import { useGetAll } from '../../../hooks/api/svgIcons/useGetAll';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import { SVGIcon } from '../../../api/svgIcons/types';

interface CollectionGalleryProps {
  toggleCollectionGallery: () => void;
}

const CollectionGallery = ({ toggleCollectionGallery }: CollectionGalleryProps) => {
  const { addIconSVG } = usePDFObjectsManager();
  const { getAll, response } = useGetAll();
  const [originalComponents, setOriginalComponents] = useState<SVGIcon[]>([]);
  const [svgComponents, setSVGComponents] = useState<SVGIcon[]>([]);
  const { jwt } = useUserContext();

  useEffect(() => {
    getAll(jwt!);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      //map throuch components and replace $$$$$ with the black hexcode retain original component besides replacing the color
      const updatedComponents = response.map(component => {
        return {
          ...component,
          content: component.content.replace(/\$\$\$\$\$/g, '#FFFFFF'),
        };
      });
      setOriginalComponents(response);
      setSVGComponents(updatedComponents);
    }
  }, [response]);

  const handleSvgClick = (icon: SVGIcon) => {
    //add original svg component content to the pdf
    addIconSVG(
      icon.name,
      icon.svgIconId,
      originalComponents.find(component => component.svgIconId === icon.svgIconId)!.content
    );

    toggleCollectionGallery();
  };

  return (
    <div
      className='bg-gray-800 border-r border-gray-300 flex flex-col h-full items-center py-20 px-0'
      style={{
        minWidth: '19rem',
        maxWidth: '19rem',
        height: 'calc(100vh - 4rem)',
        top: 0,
        overflowY: 'auto',
      }}
    >
      <div className='overflow-auto' style={{ maxHeight: 'calc(100vh - 4rem)' }}>
        <div className='grid grid-cols-2 md:grid-cols-3 gap-1' style={{ padding: 0, margin: 0 }}>
          {svgComponents.map((svgIcon, index) => (
            <button
              key={index}
              onClick={() => handleSvgClick(svgIcon)}
              className='focus:outline-none'
              style={{ padding: 0, margin: 0 }}
            >
              <div
                className='rounded-md hover:border-blue-500 cursor-pointer flex justify-center items-center'
                style={{
                  minHeight: '80px',
                  minWidth: '80px',
                  maxWidth: '80px',
                  maxHeight: '80px',
                  overflow: 'visible',
                  padding: '0px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                dangerouslySetInnerHTML={{ __html: svgIcon.content }}
              >
                {/* SVG is inserted here */}
              </div>
            </button>
          ))}
        </div>
      </div>
      <button
        className='mt-4 bg-blue-500 text-white py-2 px-4 rounded-full'
        onClick={toggleCollectionGallery}
      >
        Close
      </button>
    </div>
  );
};

export default CollectionGallery;
