import { useContext } from 'react';
import { APIErrorContext, IAPIErrorContext } from '../APIErrorContext';

export const useAPIErrorContext = (): IAPIErrorContext => {
  const context = useContext(APIErrorContext);
  if (context === null) {
    throw new Error('useAPIErrorContext must be used within a APIProvider');
  }
  return context;
};
