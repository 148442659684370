import { useContext } from 'react';
import { APIContext, IAPIContext } from '../APIContext';

export const useAPIContext = (): IAPIContext => {
  const context = useContext(APIContext);
  if (context === null) {
    throw new Error('useAPIContext must be used within a APIProvider');
  }
  return context;
};
