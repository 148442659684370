import { PDFObject } from '../../../types/PDFObjects';

interface ObjectPositionProps {
  pdfObject: PDFObject;
  handlePositionChange: (axis: 'x' | 'y', value: string) => void;
}

const ObjectPosition = ({ pdfObject, handlePositionChange }: ObjectPositionProps) => {
  return (
    <>
      <div className='flex items-center space-x-2'>
        {/* X Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            X
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(pdfObject.position.x)}
            onChange={e => handlePositionChange('x', e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Y
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(pdfObject.position.y)}
            onChange={e => handlePositionChange('y', e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ObjectPosition;
