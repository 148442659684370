import { Typography } from '@material-tailwind/react';

const TableLoading = () => {
  // Simulate several rows of data being loaded
  return (
    <>
      {Array.from({ length: 7 }, (_, index) => (
        <tr key={index} className='animate-pulse'>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='h6' className='h-6 w-6 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='paragraph' className='h-4 w-64 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='paragraph' className='h-4 w-20 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='paragraph' className='h-4 w-16 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='paragraph' className='h-4 w-16 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
          <td className='p-4 border-b border-blue-gray-50'>
            <Typography as='div' variant='paragraph' className='h-4 w-16 rounded-full bg-gray-300'>
              &nbsp;
            </Typography>
          </td>
        </tr>
      ))}
    </>
  );
};

export default TableLoading;
