import { PermissionType } from '../../../api/templates/enums';
import { TemplateMember } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useUpdateMember = () => {
  const { makeCall, response, isLoading, error } = useApiCall<TemplateMember>();
  const { templateService } = useAPIContext();

  const updateMember = async (
    templateId: string,
    templateMemberId: string,
    permissionType: PermissionType,
    jwt: string
  ) => {
    await makeCall(signal => {
      return templateService.updateMember(
        templateId,
        templateMemberId,
        permissionType,
        jwt,
        signal
      );
    });
  };

  return { updateMember, response, isLoading, error };
};
