import { IconButton, Tooltip } from '@material-tailwind/react';
import {
  MdOutlineAlignHorizontalCenter,
  MdOutlineAlignHorizontalLeft,
  MdOutlineAlignHorizontalRight,
  MdOutlineAlignVerticalBottom,
  MdOutlineAlignVerticalCenter,
  MdOutlineAlignVerticalTop,
} from 'react-icons/md';
import { PDFObject } from '../../../types/PDFObjects';
import { Alignment } from '../../../enums/PDFObjects';

interface ObjectAlignmentProps {
  pdfObject: PDFObject;
  alignObject: (pdfObject: PDFObject, alignment: Alignment) => void;
}

const ObjectAlignment = ({ pdfObject, alignObject }: ObjectAlignmentProps) => {
  return (
    <div className='flex flex-row justify-around items-center w-full p-2'>
      <Tooltip content='Align left'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.LEFT)}
          >
            <MdOutlineAlignHorizontalLeft size={24} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content='Align center'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.HCENTER)}
          >
            <MdOutlineAlignHorizontalCenter size={24} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content='Align right'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.RIGHT)}
          >
            <MdOutlineAlignHorizontalRight size={24} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content='Align top'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.TOP)}
          >
            <MdOutlineAlignVerticalTop size={24} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content='Align center'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.VCENTER)}
          >
            <MdOutlineAlignVerticalCenter size={24} />
          </IconButton>
        </div>
      </Tooltip>
      <Tooltip content='Align bottom'>
        <div className='flex flex-col items-center mb-2 text-center'>
          {' '}
          {/* Same adjustments for other buttons */}
          <IconButton
            variant='text'
            className='mx-auto text-black'
            onClick={() => alignObject(pdfObject, Alignment.BOTTOM)}
          >
            <MdOutlineAlignVerticalBottom size={24} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  );
};

export default ObjectAlignment;
