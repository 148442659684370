import api from '../api';
import { handleAxiosError } from '../helpers';
import { File } from './types';

export interface IUploadsService {
  getUploads(jwt: string, signal: AbortSignal): Promise<File[]>;
  upload(
    jwt: string,
    fileUploads: Blob[],
    signal: AbortSignal
  ): Promise<{
    qrCode: string;
    text: string;
  }>;
}

export class UploadsService implements IUploadsService {
  private static instance: UploadsService;

  public static getInstance(): UploadsService {
    if (!UploadsService.instance) {
      UploadsService.instance = new UploadsService();
    }
    return UploadsService.instance;
  }

  public async getUploads(jwt: string, signal: AbortSignal): Promise<File[]> {
    try {
      const response = await api.get(`uploads`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async upload(
    jwt: string,
    fileUploads: Blob[],
    signal: AbortSignal
  ): Promise<{
    qrCode: string;
    text: string;
  }> {
    try {
      const formData = new FormData();
      fileUploads.forEach(file => formData.append('files', file)); // 'files' is the field name

      const response = await api.post(`uploads`, formData, {
        headers: {
          Authorization: `${jwt}`,
          'Content-Type': 'multipart/form-data',
        },
        signal,
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
