import ObjectAlignment from './ObjectAlignment';
import { SVGData } from '../../../types/PDFObjects';
import ObjectPosition from './ObjectPosition';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import ObjectSize from './ObjectSize';
import usePDFStore from '../../../stores/usePDFStore';

export interface SVGToolsPanelPropsProps {
  svgData: SVGData;
}

const SVGToolsPanel = ({ svgData }: SVGToolsPanelPropsProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const { alignObject } = usePDFObjectsManager();

  const handlePositionChange = (axis: 'x' | 'y', value: string) => {
    const newPosition = { ...svgData.position, [axis]: parseFloat(value) || 0 };
    updatePDFObject(svgData.id, { position: newPosition });
  };

  const handleSizeChange = (axis: 'width' | 'height', value: string) => {
    const newSize = { ...svgData.size, [axis]: parseFloat(value) || 0 };
    updatePDFObject(svgData.id, { size: newSize });
  };

  return (
    <div
      data-testid='right-side-toolbar'
      className='flex flex-col items-center p-4'
      style={{ width: '16rem' }}
    >
      <ObjectAlignment pdfObject={svgData} alignObject={alignObject} />
      <ObjectPosition pdfObject={svgData} handlePositionChange={handlePositionChange} />
      <ObjectSize pdfObject={svgData} handleSizeChange={handleSizeChange} />
    </div>
  );
};

export default SVGToolsPanel;
