import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

// The useUserRegister hook definition
export const useResetPasswordRequest = () => {
  const { makeCall, response, isLoading, error } = useApiCall<object>();
  const { userService } = useAPIContext();

  const resetPasswordRequest = async (email: string) => {
    await makeCall(signal => userService.resetPasswordRequest(email, signal));
  };
  return { isLoading, error, response, resetPasswordRequest };
};
