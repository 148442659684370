import { AxiosError } from 'axios';
import api from '../api';
import { UserDTO } from './types';
import { APIException } from '../exceptions/APIException';
import { handleAxiosError } from '../helpers';

export interface IUserService {
  register(
    email: string,
    password: string,
    name: string,
    companyName: string,
    signal: AbortSignal
  ): Promise<UserDTO>;
  confirmEmail(token: string, signal: AbortSignal): Promise<UserDTO>;
  resetPasswordRequest(email: string, signal: AbortSignal): Promise<object>;
  resetPassword(token: string, password: string, signal: AbortSignal): Promise<object>;
  getAll(jwt: string, signal: AbortSignal): Promise<UserDTO[]>;
  deleteUsers(userIds: string[], jwt: string, signal: AbortSignal): Promise<boolean>;
  createUser(
    email: string,
    name: string,
    roleName: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<UserDTO>;
  setupAccount(
    token: string,
    password: string,
    name: string,
    signal: AbortSignal
  ): Promise<UserDTO>;
}

export class UserService implements IUserService {
  private static instance: UserService;

  public static getInstance(): UserService {
    if (!UserService.instance) {
      UserService.instance = new UserService();
    }
    return UserService.instance;
  }

  public async getAll(jwt: string, signal: AbortSignal): Promise<UserDTO[]> {
    try {
      const response = await api.get(`users`, {
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });

      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async register(
    email: string,
    password: string,
    name: string,
    companyName: string,
    signal: AbortSignal
  ): Promise<UserDTO> {
    try {
      const response = await api.post(
        '/users/register',
        {
          email,
          password,
          name,
          companyName,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async confirmEmail(token: string, signal: AbortSignal): Promise<UserDTO> {
    try {
      const response = await api.post(
        '/users/confirm-email',
        {
          token,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );

      return response.data ?? 'Success';
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async resetPasswordRequest(email: string, signal: AbortSignal): Promise<object> {
    try {
      const response = await api.post(
        '/users/reset-password-request',
        {
          email,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async resetPassword(token: string, password: string): Promise<object> {
    try {
      const response = await api.post(
        '/users/reset-password',
        {
          token,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async deleteUsers(userIds: string[], jwt: string, signal: AbortSignal): Promise<boolean> {
    try {
      await api.delete(`users`, {
        data: {
          userIds,
        },
        headers: {
          Authorization: `${jwt}`,
        },
        signal,
      });
      return true;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async createUser(
    email: string,
    name: string,
    roleName: string,
    jwt: string,
    signal: AbortSignal
  ): Promise<UserDTO> {
    try {
      const response = await api.post(
        '/users',
        {
          email,
          name,
          roleName,
        },
        {
          headers: {
            Authorization: `${jwt}`,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }

  public async setupAccount(
    token: string,
    password: string,
    name: string,
    signal: AbortSignal
  ): Promise<UserDTO> {
    try {
      const response = await api.post(
        '/users/setup-account',
        {
          token,
          password,
          name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      handleAxiosError(error);
    }
  }
}
