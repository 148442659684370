import { SideNav } from './SideNav';

interface PageContainerProps {
  children: React.ReactNode;
}

const PageContainer = ({ children }: PageContainerProps) => {
  return (
    <div className='flex h-screen bg-gray-100 overflow-auto'>
      <SideNav />
      <div className='flex flex-col flex-grow'>{children}</div>
    </div>
  );
};

export default PageContainer;
