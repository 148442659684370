export function setTextEditCursorToFront(node: Node | null): void {
  if (!node) return;
  const range = document.createRange();
  const sel = window.getSelection();
  if (sel) {
    range.selectNodeContents(node);
    range.collapse(false); // Collapse range to the end
    sel.removeAllRanges(); // Remove all existing ranges
    sel.addRange(range); // Add the new range
  }
}

export const disableTextSelection = () => {
  document.body.classList.add('no-select');
};

export const enableTextSelection = () => {
  document.body.classList.remove('no-select');
};
