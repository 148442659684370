import { useEffect, useState } from 'react';
import { useRegisterUser } from '../../../hooks/api/users/useRegisterUser';
import { useNavigate } from 'react-router-dom';
import { useGoogleAuth } from '../../../features/auth/hooks/useGoogleAuth';
import { useExchangeGoogleAuthCode } from '../../../features/auth/hooks/useExchangeGoogleAuthCode';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import PageOverlayLoader from '../../../components/ui/PageOverlayLoader';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import GoogleLoginButton from '../../../features/auth/components/GoogleLoginButton';
import LabeledDivider from '../../../components/ui/LabeledDivider';
import InputText from '../../../components/ui/InputText';
import FlatButton from '../../../components/ui/FlatButton';
import PasswordInput from '../../../components/ui/PasswordInput';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';

const RegistrationPage = () => {
  const [userDetails, setUserDetails] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    companyName: '',
  });
  const { initiateGoogleLogin, codeResponse, setCodeResponse } = useGoogleAuth();
  const {
    isLoading: googleIsLoading,
    exchangeAuthCode,
    authResponse,
    error: googleError,
    sentRequest,
    setSentRequest,
  } = useExchangeGoogleAuthCode();
  const { registerUser, isLoading, error, registerResponse } = useRegisterUser();
  const { setSession } = useUserContext();
  const [errors, setErrors] = useState({
    emailExists: false,
    passwordMismatch: false,
    passwordShort: false,
    emailEmpty: false, // New error state for empty email
    passwordEmpty: false, // New error state for empty password
    confirmPasswordEmpty: false, // New error state for empty confirmPassword
    nameEmpty: false, // New error state for empty name
    companyNameEmpty: false, // New error state for empty companyName
  });
  const navigate = useNavigate();
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
  const { setError } = useAPIErrorContext();

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  //googleError  set error

  useEffect(() => {
    if (googleError) {
      setError(googleError);
    }
  }, [googleError, setError]);

  useEffect(() => {
    if (error) {
      setCodeResponse(null);
    }
  }, [error, setCodeResponse]);

  useEffect(() => {
    if (codeResponse && !sentRequest) {
      exchangeAuthCode(codeResponse);
      setCodeResponse(null);
    }
  }, [codeResponse, exchangeAuthCode, sentRequest, setCodeResponse]);

  useEffect(() => {
    if (authResponse) {
      setSession(authResponse.user, authResponse.jwt);
      navigate('/dashboard');
    }
  }, [authResponse, setSession, navigate]);

  const handleLoginWithGoogle = () => {
    setSentRequest(false);
    initiateGoogleLogin();
  };
  const handleEmailChange = (value: string) => {
    setErrors({
      emailExists: false,
      passwordMismatch: false,
      passwordShort: false,
      emailEmpty: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
      nameEmpty: false,
      companyNameEmpty: false,
    });
    setUserDetails({ ...userDetails, email: value });
  };

  const handlePasswordChange = (value: string) => {
    setErrors({
      emailExists: false,
      passwordMismatch: false,
      passwordShort: false,
      emailEmpty: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
      nameEmpty: false,
      companyNameEmpty: false,
    });
    setUserDetails({ ...userDetails, password: value });
  };

  const handleConfirmPasswordChange = (value: string) => {
    setErrors({
      emailExists: false,
      passwordMismatch: false,
      passwordShort: false,
      emailEmpty: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
      nameEmpty: false,
      companyNameEmpty: false,
    });
    setUserDetails({ ...userDetails, confirmPassword: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      emailExists: false,
      passwordMismatch: userDetails.password !== userDetails.confirmPassword,
      passwordShort: userDetails.password.length < 8,
      emailEmpty: !userDetails.email,
      passwordEmpty: !userDetails.password,
      confirmPasswordEmpty: !userDetails.confirmPassword,
      nameEmpty: !userDetails.name,
      companyNameEmpty: !userDetails.companyName,
    };

    // Check if any error is true to set isValid to false
    isValid = !Object.values(newErrors).includes(true);

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      registerUser(
        userDetails.email,
        userDetails.password,
        userDetails.name,
        userDetails.companyName
      );
    }
  };

  useEffect(() => {
    if (registerResponse) {
      setRegistrationSuccessful(true);
    }
  }, [registerResponse, navigate]);

  const handleChangeName = (value: string) => {
    setUserDetails({ ...userDetails, name: value });
  };

  const handleChangeCompanyName = (value: string) => {
    setUserDetails({ ...userDetails, companyName: value });
  };

  if (registrationSuccessful) {
    // Show confirmation screen if registration is successful
    return (
      <AuthPageContainer>
        <AuthContentCard>
          <AuthContentCard.Logo />
          <AuthContentCard.Title>Registration Successful</AuthContentCard.Title>
          <p className='mt-4 text-center'>
            We have sent an email verification to{' '}
            <span className='font-medium'>{userDetails.email}</span>. Please confirm your email to
            login.
          </p>
          <div className='mt-6 text-center'>
            <a href='/login' className='text-indigo-600 hover:underline'>
              Go to Login
            </a>
          </div>
        </AuthContentCard>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      {googleIsLoading && <PageOverlayLoader message='Logging you in...' />}
      <AuthContentCard>
        <AuthContentCard.Logo />
        <AuthContentCard.Title>Join ScribePDF</AuthContentCard.Title>
        {/* Continue with Google Button */}

        <p className='text-center text-lg text-gray-800 pt-2'>Try Free</p>
        <br />
        <GoogleLoginButton onClick={handleLoginWithGoogle} />
        <LabeledDivider label='OR' />

        <div className='mb-4'>
          <label htmlFor='email' className='block mb-2 text-sm text-gray-600'>
            Email
          </label>
          <InputText
            handleChange={handleEmailChange}
            value={userDetails.email}
            placeholder='Enter your email'
            id='email'
          />
          {errors.emailExists && <p className='text-red-500 text-xs mt-1'>Email already exists</p>}
          {errors.emailEmpty && (
            <p className='text-red-500 text-xs mt-1'>Please fill out this field</p>
          )}
        </div>
        <div className='mb-4'>
          <label htmlFor='name' className='block mb-2 text-sm text-gray-600'>
            Name
          </label>
          <InputText
            handleChange={handleChangeName}
            value={userDetails.name}
            placeholder='Enter your name'
            id='name'
          />
          {errors.nameEmpty && (
            <p className='text-red-500 text-xs mt-1'>Please fill out this field</p>
          )}
        </div>
        <div className='mb-4'>
          <label htmlFor='companyName' className='block mb-2 text-sm text-gray-600'>
            Company Name
          </label>
          <InputText
            handleChange={handleChangeCompanyName}
            value={userDetails.companyName}
            placeholder='Enter your company name'
            id='companyName'
          />
          {errors.companyNameEmpty && (
            <p className='text-red-500 text-xs mt-1'>Please fill out this field</p>
          )}
        </div>

        <div className='mb-4 relative'>
          <label htmlFor='password' className='block mb-2 text-sm text-gray-600'>
            Password
          </label>
          <PasswordInput
            value={userDetails.password}
            onChange={handlePasswordChange}
            placeholder='Enter your password'
            id='password'
          />
          {errors.passwordShort && (
            <p className='text-red-500 text-xs mt-1'>
              Password needs to be longer than 8 characters
            </p>
          )}
        </div>

        <div className='mb-4 relative'>
          <label htmlFor='confirmPassword' className='block mb-2 text-sm text-gray-600'>
            Confirm Password
          </label>
          <PasswordInput
            value={userDetails.confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder='Confirm your password'
            id='confirmPassword'
          />
          {errors.passwordMismatch && (
            <p className='text-red-500 text-xs mt-1'>Passwords do not match</p>
          )}
        </div>
        <FlatButton text='Sign Up' onClick={handleSubmit} disabled={isLoading} className='my-5' />
        <p className='text-sm text-center text-gray-500'>
          Already have an account?{' '}
          <a
            href='/login'
            className='text-indigo-600 focus:outline-none focus:underline hover:underline'
          >
            Log in
          </a>
          .
        </p>
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default RegistrationPage;
