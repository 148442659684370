// src/services/auth/JWTService.ts
export class JWTService {
  /**
   * Decodes the JWT token to extract the payload without verification.
   * @param token The JWT token.
   */
  public decode(token: string) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join('')
      );

      return JSON.parse(jsonPayload);
    } catch (error) {
      console.error('Failed to decode token:', error);
      return null;
    }
  }

  /**
   * Checks if the token is expired.
   * @param token The JWT token.
   */
  public isExpired(token: string): boolean {
    const payload = this.decode(token);
    return payload ? Date.now() >= payload.exp * 1000 : true;
  }
}
