import { Dialog, DialogBody, DialogFooter } from '@material-tailwind/react';

interface AlertConfirmProps {
  message: string;
  dialogOpen: boolean;
  handleOpen: () => void;
  handleConfirm: () => void;
  handleCancel: () => void;
  buttonLabel: string;
}
const AlertConfirm = ({
  message,
  dialogOpen,
  handleOpen,
  handleConfirm,
  handleCancel,
  buttonLabel,
}: AlertConfirmProps) => {
  return (
    <Dialog open={dialogOpen} handler={handleOpen} size='sm'>
      <DialogBody>
        <div className='flex flex-row justify-center'>
          <div className='text-center mt-2'>
            <h1 className='text-2xl text-black'>{message}</h1>
          </div>
        </div>
      </DialogBody>
      <DialogFooter>
        <button
          className='bg-blue-500 text-white py-2 px-4  mx-5 rounded font-bold hover:bg-blue-700'
          onClick={handleCancel}
        >
          <span>Cancel</span>
        </button>
        <button
          className='bg-red-500 text-white py-2 px-4 rounded font-bold hover:bg-red-700'
          onClick={handleConfirm}
        >
          <span>{buttonLabel}</span>
        </button>
      </DialogFooter>
    </Dialog>
  );
};

export default AlertConfirm;
