import { useEffect } from 'react';
import { File } from '../../../api/uploads/types';

import { useApiCall } from '../helpers/useApiCall';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';

export const useGetUploads = () => {
  const { makeCall, response, isLoading, error } = useApiCall<File[]>();
  const { uploadService } = useAPIContext();

  const getUploads = async (token: string) => {
    await makeCall(async signal => {
      return uploadService.getUploads(token, signal);
    });
  };

  useEffect(() => {
    console.log('isLoading in useGetUploads:', isLoading);
  }, [isLoading]);

  return { getUploads, response, isLoading, error };
};
