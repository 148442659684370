import { TableData } from '../../../../types/PDFObjects';
import EditableTextDiv from '../core/EditableTextDiv';
import { useRef, useState } from 'react';
import usePDFStore from '../../../../stores/usePDFStore';

interface TableCellProps {
  tableData: TableData;
  cellIndex: number;
  rowIndex: number;
}

const TableCell = ({ tableData, cellIndex, rowIndex }: TableCellProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const value = useRef<string>(tableData.rows[rowIndex].cells[cellIndex].content);
  const [isEditing, setIsEditing] = useState(false);

  const onContentChange = (content: string, rowIndex: number, cellIndex: number) => {
    const updatedRows = tableData.rows.map((row, i) => {
      if (i === rowIndex) {
        return {
          ...row,
          cells: row.cells.map((c, j) => {
            if (j === cellIndex) {
              return { ...c, content: content };
            } else {
              return c;
            }
          }),
        };
      } else {
        return row;
      }
    });

    updatePDFObject<TableData>(tableData.id, {
      ...tableData,
      rows: updatedRows,
    });
  };
  const handleOnInput = (e: React.FormEvent<HTMLDivElement>) => {
    const updatedContent = e.currentTarget.innerText.replace(/\n{3,}/g, '\n\n');
    onContentChange(updatedContent, rowIndex, cellIndex);
  };

  return (
    <td
      key={cellIndex}
      style={{
        width: `${tableData.headerWidths[cellIndex]}px`,
        maxWidth: `${tableData.headerWidths[cellIndex]}px`,
        border: '1px solid black',
        height: `${tableData.rowsStyle.minHeight}px`,
        overflow: 'hidden',
        fontFamily: tableData.rowsStyle.fontFamily,
        fontSize: `${tableData.rowsStyle.fontSize}px`,
        fontWeight: tableData.rowsStyle.fontWeight,
        fontStyle: tableData.rowsStyle.fontStyle,
        textDecoration: tableData.rowsStyle.underlined ? 'underline' : 'none',
      }}
    >
      <EditableTextDiv
        value={value.current}
        onContentChange={handleOnInput}
        fontSize={tableData.rowsStyle.fontSize}
        lineSpacing={tableData.rowsStyle.lineSpacing}
        fontFamily={tableData.rowsStyle.fontFamily}
        content={tableData.rows[rowIndex].cells[cellIndex].content}
        isEditing={isEditing}
        setIsEditing={setIsEditing}
      />
    </td>
  );
};

export default TableCell;
