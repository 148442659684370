import PageContainer from '../../components/layout/PageContainer';
import UserManagement from '../../features/users/components/UserManagement';

const UserManagementPage = () => {
  return (
    <PageContainer>
      <div className='flex flex-col flex-grow overflow-auto px-5 py-10'>
        <UserManagement />
      </div>
    </PageContainer>
  );
};

export default UserManagementPage;
