import { memo, useEffect, useRef } from 'react';
import { PDFObject, TableData } from '../../../../types/PDFObjects';
import TableCell from './TableCell';
import TableHeader from './TableHeader';
import { IconButton } from '@material-tailwind/react';
import { MdOutlineAdd, MdOutlineRemove } from 'react-icons/md';
import usePDFObjectsManager from '../../../../hooks/pdf/usePDFObjectsManager';
import usePDFStore from '../../../../stores/usePDFStore';

interface TableProps {
  tableData: TableData;
  updatePDFObject: <T extends PDFObject>(id: string, updates: Partial<T>) => void;
}

const Table = memo(({ tableData }: TableProps) => {
  const { addRow, removeRow } = usePDFObjectsManager();
  const tableRef = useRef<HTMLTableElement>(null);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);

  useEffect(() => {
    if (tableRef.current) {
      console.log('recalculating table size');
      const headersHeight = tableRef.current.rows[0].offsetHeight;
      const tableHeight = tableRef.current.offsetHeight;
      const tableWidth = tableRef.current.offsetWidth;
      const rows = tableRef.current.rows;
      const heights = Array.from(rows).map(row => row.offsetHeight);
      //remove first element as it is the header
      heights.shift();
      updatePDFObject<TableData>(tableData.id, {
        rowHeights: heights,
        size: {
          height: tableHeight,
          width: tableWidth,
        },
        headersHeight: headersHeight,
      });
    }
  }, [
    tableData.rowsStyle,
    tableData.headersStyle,
    tableData.id,
    updatePDFObject,
    tableData.rows,
    tableData.headers,
    tableData.headersHeight,
    tableData.headerWidths,
  ]);

  useEffect(() => {
    //update header widths
    if (tableRef.current) {
      console.log('recalculating header widths');
      const widths = Array.from(tableRef.current.rows[0].children).map(
        cell => (cell as HTMLElement).offsetWidth!
      );
      updatePDFObject<TableData>(tableData.id, {
        headerWidths: widths,
      });
    }
  }, [tableData.size.width, tableData.id, updatePDFObject]);

  const handleAddRow = () => {
    addRow(tableData);
  };

  const handleRemoveRow = () => {
    removeRow(tableData);
  };

  return (
    <div
      className='overflow-visible px-0'
      style={{
        maxWidth: `${tableData.size.width}px`,
        minWidth: `${tableData.size.width}px`,
        height: `${tableData.size.height}px`,
      }}
    >
      <table
        className='w-full border-collapse'
        ref={tableRef}
        style={{
          lineHeight: tableData.rowsStyle.lineSpacing,
        }}
      >
        <thead className='bg-gray-500'></thead>
        <tbody>
          <tr
            style={{
              padding: '0px',
              width: '100%',
              boxSizing: 'border-box', // Ensure padding does not affect the size
              position: 'relative', // Ensure child absolute positioning is relative to this div
              fontSize: `${tableData.headersStyle.fontSize}px`,
              fontFamily: tableData.headersStyle.fontFamily,
              fontWeight: tableData.headersStyle.fontWeight,
              fontStyle: tableData.headersStyle.fontStyle,
              textDecoration: tableData.headersStyle.underlined ? 'underline' : 'none',
              textAlign: tableData.headersStyle.align,
              height: `${tableData.headersHeight}px`,
              maxHeight: `${tableData.headersHeight}px`,
              lineHeight: `${Math.round(tableData.headersStyle.fontSize * tableData.headersStyle.lineSpacing)}px`,
            }}
          >
            {tableData.headers.map((_header, index) => (
              <TableHeader key={index} index={index} tableData={tableData} tableRef={tableRef} />
            ))}
          </tr>
          {tableData.rows.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              style={{
                fontSize: `${tableData.rowsStyle.fontSize}px`,
                fontFamily: tableData.rowsStyle.fontFamily,
                fontWeight: tableData.rowsStyle.fontWeight,
                textAlign: tableData.rowsStyle.align,
                minHeight: `${tableData.rowsStyle.minHeight}px`,
                height: `${tableData.rowsStyle.minHeight}px`,
                lineHeight: `${Math.round(tableData.rowsStyle.fontSize * tableData.rowsStyle.lineSpacing)}px`,
              }}
            >
              {row.cells.map((_cell, cellIndex) => (
                <TableCell
                  tableData={tableData}
                  key={cellIndex}
                  rowIndex={rowIndex}
                  cellIndex={cellIndex}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      {tableData.isSelected && (
        <div className='absolute bottom-[-30px] left-1/2 transform -translate-x-1/2 flex items-center space-x-2'>
          <IconButton onClick={handleAddRow} className='bg-indigo-200 max-w-[25px] max-h-[25px]'>
            <MdOutlineAdd color='white' size={18} />
          </IconButton>
          <IconButton onClick={handleRemoveRow} className='bg-red-300 max-w-[25px] max-h-[25px]'>
            <MdOutlineRemove color='white' size={18} />
          </IconButton>
        </div>
      )}
    </div>
  );
});

Table.displayName = 'Table';

export default Table;
