import ObjectAlignment from './ObjectAlignment';
import { QRCodeData } from '../../../types/PDFObjects';
import ObjectPositioning from './ObjectPosition';
import { MdInfo } from 'react-icons/md';
import React, { useEffect } from 'react';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import ObjectSize from './ObjectSize';
import usePDFStore from '../../../stores/usePDFStore';
import { useGenerateQRCode } from '../../../hooks/api/qrCodes/useGenerateQRCode';
import { useUserContext } from '../../../contexts/hooks/useUserContext';

export interface QRCodeToolsPanelProps {
  qrCodeData: QRCodeData;
}

const QRCodeToolsPanel = ({ qrCodeData }: QRCodeToolsPanelProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const { generateQRCode, response, error } = useGenerateQRCode();
  const { jwt } = useUserContext();
  const { alignObject } = usePDFObjectsManager();
  const [value, setValue] = React.useState<string>(qrCodeData.value);

  useEffect(() => {
    setValue(qrCodeData.value);
  }, [qrCodeData.value]);

  useEffect(() => {
    if (response) {
      updatePDFObject(qrCodeData.id, { content: response.qrCode, value: response.text });
    }
  }, [response, updatePDFObject, qrCodeData.id]);

  useEffect(() => {
    if (error) {
      setValue(qrCodeData.value);
    }
  }, [error, qrCodeData.value]);

  const handlePositionChange = (axis: 'x' | 'y', value: string) => {
    const newPosition = { ...qrCodeData.position, [axis]: Math.round(parseFloat(value)) || 0 };
    updatePDFObject(qrCodeData.id, { position: newPosition });
  };

  const handleSizeChange = (axis: 'width' | 'height', value: string) => {
    console.log('parsed value', parseFloat(value));
    //round the value to 2 decimal places

    const newSize = { ...qrCodeData.size, [axis]: Math.round(parseFloat(value)) || 0 };
    updatePDFObject(qrCodeData.id, {
      size: newSize,
    });
  };

  const handleDynamicTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePDFObject<QRCodeData>(qrCodeData.id, { isDynamic: e.target.checked });
  };
  const onBlurHandler = () => {
    if (!qrCodeData.isDynamic) {
      generateQRCode(jwt!, value);
    }
  };

  return (
    <div
      data-testid='right-side-toolbar'
      className='flex flex-col items-start rounded-lg bg-white p-4 space-y-2'
      style={{ width: '16rem' }}
    >
      <ObjectAlignment pdfObject={qrCodeData} alignObject={alignObject} />
      <div className='flex items-center space-x-2 mt-5'>
        <div className='flex items-center'>
          <input
            id='dynamicText'
            type='checkbox'
            className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
            onChange={handleDynamicTextChange}
            checked={qrCodeData.isDynamic}
          />
          <label htmlFor='dynamicText' className='ml-2 block text-sm text-gray-900'>
            Dynamic QR Code
          </label>
          <div className='tooltip'>
            <MdInfo size={18} className='text-indigo-500 ml-1' />
            <span className='tooltiptext mx-2'>
              Generate dynamic QR Codes via API using its JSON key below.
            </span>
          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='mt-0 flex rounded shadow-sm w-full mb-2'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Key
          </span>
          <input
            type='text'
            className={`block w-full min-w-0 flex-1 rounded-r border-0 py-1 ${qrCodeData.isDynamic ? 'text-gray-900 bg-white' : 'text-gray-400 bg-gray-200'} ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none`}
            value={qrCodeData.jsonKey}
            onChange={e => updatePDFObject(qrCodeData.id, { jsonKey: e.target.value })}
            disabled={!qrCodeData.isDynamic}
          />
        </div>
        <div className='mt-0 flex rounded shadow-sm w-full mb-2'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Data
          </span>
          <textarea
            onBlur={onBlurHandler}
            className={`block w-full min-w-0 flex-1 rounded-r border-0 py-1 ${!qrCodeData.isDynamic ? 'text-gray-900 bg-white' : 'text-gray-400 bg-gray-200'} ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none`}
            value={value}
            onChange={e => setValue(e.target.value)}
            disabled={qrCodeData.isDynamic}
          />
        </div>
        <ObjectPositioning pdfObject={qrCodeData} handlePositionChange={handlePositionChange} />
        <ObjectSize pdfObject={qrCodeData} handleSizeChange={handleSizeChange} />
      </div>
    </div>
  );
};

export default QRCodeToolsPanel;
