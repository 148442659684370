import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import DocumenationCTA from './DocumentationCTA';
import CreatePDFCTA from './CreatePDFCTA';
import { SideNav } from '../layout/SideNav';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { useEffect, useState } from 'react';
import { useGetAll } from '../../hooks/api/analytics/useGetAll';

export function DashboardPage() {
  const { jwt } = useUserContext();
  const { getAll, response } = useGetAll();

  const [analyticsEvents, setAnalyticsEvents] = useState<
    {
      createdAt: string;
      count: number;
    }[]
  >([]);

  useEffect(() => {
    if (!jwt) return;
    getAll(jwt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      const transformedData = response.reduce(
        (
          acc: {
            [key: string]: { createdAt: string; count: number };
          },
          event
        ) => {
          const date = new Date(event.createdAt).toDateString();
          if (!acc[date]) {
            acc[date] = { createdAt: date, count: 1 };
          } else {
            acc[date].count += 1;
          }
          return acc;
        },
        {}
      );

      // Convert the transformed data object into an array
      setAnalyticsEvents(Object.values(transformedData));
    }
  }, [response]);

  return (
    <div className='flex h-screen bg-gray-100 overflow-auto'>
      <SideNav />
      <div className='flex flex-col flex-grow'>
        <div className='p-5'>
          <div className='grid grid-cols-1 lg:grid-cols-4'></div>
          <div className='grid grid-cols-1 lg:grid-cols-4 gap-6'>
            <div className='col-span-2'>
              <CreatePDFCTA />
            </div>
            <div className='col-span-2'>
              <DocumenationCTA />
            </div>
            <div className='col-span-2 p-4'>
              <h2 className='font-semibold text-xl mb-4 text-center my-first-step'>API Usage</h2>
              <ResponsiveContainer width='100%' height={300}>
                <LineChart data={analyticsEvents}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='createdAt' />
                  <YAxis dataKey='count' allowDecimals={false} />
                  <Tooltip />
                  <Legend />
                  <Line type='monotone' dataKey='count' stroke='#8884d8' activeDot={{ r: 8 }} />
                </LineChart>
              </ResponsiveContainer>
            </div>

            {/* Top Used Templates - Modern Responsive Table */}
            {/* <div className='flex col-span-1 md:col-span-2 lg:col-span-2 my-other-step'>
              <GridExample />
            </div> */}

            {/* Additional Widgets/Components */}
          </div>
        </div>
      </div>
    </div>
  );
}
