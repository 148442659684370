import { AxiosError } from 'axios';
import { APIException } from '../exceptions/APIException';
import api from '../api';
import { ApiKeyDTO } from './types';

export interface IApiKeyService {
  createApiKey(jwt: string, signal: AbortSignal): Promise<ApiKeyDTO>;
  deleteApiKey(jwt: string, apiKeyId: string, signal: AbortSignal): Promise<boolean>;
  getApiKeys(jwt: string, signal: AbortSignal): Promise<ApiKeyDTO[]>;
  reactivateApiKey(jwt: string, apiKeyId: string, signal: AbortSignal): Promise<ApiKeyDTO>;
  deactivateApiKey(jwt: string, apiKeyId: string, signal: AbortSignal): Promise<ApiKeyDTO>;
}

export class ApiKeyService implements IApiKeyService {
  public static instance: ApiKeyService;

  public static getInstance(): ApiKeyService {
    if (!ApiKeyService.instance) {
      ApiKeyService.instance = new ApiKeyService();
    }
    return ApiKeyService.instance;
  }

  private constructor() {}

  public async createApiKey(jwt: string, signal: AbortSignal): Promise<ApiKeyDTO> {
    try {
      const response = await api.post(
        '/api-keys',
        {
          name: 'Secret Key',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwt,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async reactivateApiKey(
    jwt: string,
    apiKeyId: string,
    signal: AbortSignal
  ): Promise<ApiKeyDTO> {
    try {
      const response = await api.put(
        `/api-keys/${apiKeyId}/activate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwt,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async deactivateApiKey(
    jwt: string,
    apiKeyId: string,
    signal: AbortSignal
  ): Promise<ApiKeyDTO> {
    try {
      const response = await api.put(
        `/api-keys/${apiKeyId}/deactivate`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: jwt,
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async deleteApiKey(jwt: string, apiKeyId: string, signal: AbortSignal): Promise<boolean> {
    try {
      const response = await api.delete(`/api-keys/${apiKeyId}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt,
        },
        signal,
      });
      return response.status === 200;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async getApiKeys(jwt: string, signal: AbortSignal): Promise<ApiKeyDTO[]> {
    try {
      const response = await api.get('/api-keys', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: jwt,
        },
        signal,
      });
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }
}
