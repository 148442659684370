interface CheckBoxProps {
  checked: boolean;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const CheckBox = ({ checked, handleChange }: CheckBoxProps) => {
  return (
    <input
      type='checkbox'
      checked={checked}
      onChange={handleChange}
      className='hover:bg-blue-100 cursor-pointer py-2 px-2 rounded'
    />
  );
};

export default CheckBox;
