import TextboxTools from './TextboxTools';
import { IconSVGData, LineSVGData, RectSVGData, TextBoxData } from '../../../types/PDFObjects';
import { IconButton, Tooltip } from '@material-tailwind/react';
import { MdOutlineDownload, MdOutlineSaveAlt } from 'react-icons/md';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import { usePdfDownload } from '../../../hooks/usePdfDownload';
import { useEffect } from 'react';
import Notification from '../../ui/Notification';
import usePDFStore from '../../../stores/usePDFStore';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import { useUpdateTemplateContent } from '../../../hooks/api/templates/useUpdateTemplateContent';
import { PDFObjectType } from '../../../enums/PDFObjects';
import LineSVGTools from './LineSVGTools';
import RectSVGTools from './RectSVGTools';
import IconSVGTools from './IconSVGTools';

const TopToolbar = () => {
  const lastSaved = usePDFStore(state => state.lastSaved);
  const updateLastSaved = usePDFStore(state => state.updateLastSaved);
  const templateId = usePDFStore(state => state.template.templateId);
  const autoSave = usePDFStore(state => state.settings.autoSave);
  const toggleAutosave = usePDFStore(state => state.toggleAutoSave);

  const {
    updateTemplateContent,
    response: saveResponse,
    isLoading: isSaving,
  } = useUpdateTemplateContent();
  const { getContentJSON } = usePDFObjectsManager();
  const { getSelected } = usePDFObjectsManager();
  const selected = getSelected();
  const { jwt } = useUserContext();
  const { downloadPdf, pdfBlob, isLoading } = usePdfDownload();

  const handleSaveTemplate = async () => {
    if (!jwt) return;
    if (!templateId) return;

    const pdfData = getContentJSON();
    updateTemplateContent(templateId, pdfData, jwt);
  };

  const handleDownloadPdf = async () => {
    if (!jwt) return;
    if (!templateId) return;
    downloadPdf(templateId, jwt);
  };

  useEffect(() => {
    if (pdfBlob) {
      const url = window.URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'downloadedFile.pdf'; // Name the file
      document.body.appendChild(a); // Required for this to work in FireFox
      a.click();
      a.remove(); // Remove the element
      window.URL.revokeObjectURL(url); // Clean up
    }
  }, [pdfBlob]);

  useEffect(() => {
    if (saveResponse) {
      updateLastSaved(Date.now());
    }
  }, [saveResponse, updateLastSaved]);

  return (
    <div
      data-testid='top-toolbar'
      className='flex bg-white px-1 border-b border-gray-300'
      style={{ height: '64px' }}
    >
      {<Notification message={'Creating PDF....'} isVisible={isLoading} />}
      <div className='flex items-center justify-between w-full'>
        <div className='flex items-center pl-20'></div>
        {selected?.type === 'TextBox' && <TextboxTools textBoxData={selected as TextBoxData} />}
        {selected?.type === PDFObjectType.LINE_SVG && (
          <LineSVGTools lineSVGData={selected as LineSVGData} />
        )}
        {selected?.type === PDFObjectType.RECT_SVG && (
          <RectSVGTools rectSVGData={selected as RectSVGData} />
        )}
        {selected?.type === PDFObjectType.ICON_SVG && (
          <IconSVGTools iconSVGData={selected as IconSVGData} />
        )}
        <div className='flex items-center pr-20'>
          <div className='text-sm text-gray-500 px-5'>
            {lastSaved && `Last saved ${new Date(lastSaved).toLocaleTimeString()} `}
          </div>
          <Tooltip content='Save' placement='bottom'>
            <IconButton
              aria-label='Save'
              variant='text'
              onClick={handleSaveTemplate}
              disabled={isSaving ? true : false}
            >
              <MdOutlineSaveAlt size={24} color='black' />
            </IconButton>
          </Tooltip>
          <Tooltip content='Download' placement='bottom'>
            <IconButton aria-label='Download' variant='text' onClick={handleDownloadPdf}>
              <MdOutlineDownload size={24} />
            </IconButton>
          </Tooltip>
          <Tooltip content='Toggle Autosave' placement='bottom'>
            <div className='flex items-center px-2'>
              <label htmlFor='toggle' className='flex items-center cursor-pointer ml-auto relative'>
                {/* Input */}
                <input
                  id='toggle'
                  type='checkbox'
                  className='sr-only'
                  checked={autoSave.isEnabled}
                  onChange={toggleAutosave}
                />
                {/* Line */}
                <div
                  className={`block bg-gray-400 w-14 h-6 rounded-full before:absolute before:content-['Off'] before:right-2 before:text-black after:absolute after:content-['On'] after:left-1 after:text-white after:opacity-0 ${autoSave.isEnabled ? 'bg-green-300 after:opacity-100 before:opacity-0' : ''} transition-colors`}
                ></div>
                {/* Dot */}
                <div
                  className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition-transform ${autoSave.isEnabled ? 'transform translate-x-8' : ''}`}
                ></div>
              </label>
              <span className='text-gray-900 font-medium pl-2'>Autosave</span>
            </div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default TopToolbar;
