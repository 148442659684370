import { UserDTO } from '../../../api/user/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useSetupAccount = () => {
  const { makeCall, response, isLoading, error } = useApiCall<UserDTO>();
  const { userService } = useAPIContext();

  const setupAccount = async (name: string, password: string, token: string) => {
    await makeCall(signal => {
      return userService.setupAccount(token, password, name, signal);
    });
  };

  return { setupAccount, response, isLoading, error };
};
