import React, { useEffect, useState } from 'react';
import TextBoxToolsPanel from './TextBoxToolsPanel';
import SVGToolsPanel from './SVGToolsPanel';
import {
  LineSVGData,
  QRCodeData,
  SVGData,
  TableData,
  TextBoxData,
} from '../../../types/PDFObjects';
import LayersPanel from './LayersPanel';
import TableToolsPanel from './TableToolsPanel';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import LineSVGToolsPanel from './LineSVGToolsPanel';
import { PDFObjectType } from '../../../enums/PDFObjects';
import QRCodeToolsPanel from './QRCodeToolsPanel';

const ObjectEditorPanel: React.FC = () => {
  const { getSelected } = usePDFObjectsManager();
  const selected = getSelected();
  const [isToolsPanelVisible, setIsToolsPanelVisible] = useState(false);
  const isSelectedTypeValid =
    selected?.type === 'TextBox' ||
    selected?.type === 'SVG' ||
    selected?.type === 'Table' ||
    selected?.type === 'LineSVG' ||
    selected?.type === PDFObjectType.QR_CODE;

  const toggleToolsPanel = () => {
    setIsToolsPanelVisible(!isToolsPanelVisible);
  };

  useEffect(() => {
    if (!isSelectedTypeValid) {
      setIsToolsPanelVisible(false);
    }
  }, [selected, isSelectedTypeValid]);

  return (
    <div
      data-testid='right-side-toolbar'
      className='h-full flex-col items-center py-5 border border-gray-300 flex-shrink-0'
      style={{
        width: '17rem',
        maxHeight: 'calc(100vh - 128px)',
        overflowY: 'auto',
      }}
    >
      <div className='flex flex-row justify-around items-center w-full text-sm font-semibold'>
        <h3
          className={`cursor-pointer ${!isSelectedTypeValid || !isToolsPanelVisible ? 'text-blue-500' : 'text-gray-700'}`}
          onClick={toggleToolsPanel}
        >
          Layers
        </h3>
        {isSelectedTypeValid ? (
          <h3
            className={`cursor-pointer ${isToolsPanelVisible ? 'text-blue-500' : 'text-gray-700'}`}
            onClick={toggleToolsPanel}
          >
            Object Editor
          </h3>
        ) : (
          <h3 className={'text-gray-400'}>Object Editor</h3>
        )}
      </div>
      {/* Conditional rendering based on isToolsPanelVisible and selected object type */}
      {isToolsPanelVisible ? (
        <div>
          {selected?.type === 'TextBox' && (
            <TextBoxToolsPanel textBoxData={selected as TextBoxData} />
          )}
          {selected?.type === 'SVG' && <SVGToolsPanel svgData={selected as SVGData} />}
          {selected?.type == 'Table' && <TableToolsPanel tableData={selected as TableData} />}
          {selected?.type === PDFObjectType.LINE_SVG && (
            <LineSVGToolsPanel lineSVGData={selected as LineSVGData} />
          )}
          {selected?.type === PDFObjectType.QR_CODE && (
            <QRCodeToolsPanel qrCodeData={selected as QRCodeData} />
          )}
        </div>
      ) : (
        <LayersPanel />
      )}
    </div>
  );
};

export default ObjectEditorPanel;
