export class APIException extends Error {
  public response: string;
  public status?: number;

  constructor(message: string, response: any, status?: number) {
    super(message);
    this.name = 'APIException';
    this.response = typeof response === 'string' ? response : JSON.stringify(response);
    this.status = status;

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, APIException);
    }
  }
}
