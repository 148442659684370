import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import useDraggable from '../../../hooks/pdf/useDraggable';
import usePDFStore from '../../../stores/usePDFStore';
import { PDFObjectType } from '../../../enums/PDFObjects';
import SVG from '../Objects/SVG';
import TextBox from '../Objects/TextBox';
import {
  IconSVGData,
  ImageData,
  LineSVGData,
  PDFObject,
  QRCodeData,
  RectSVGData,
  SVGData,
  TableData,
  TextBoxData,
} from '../../../types/PDFObjects';
import Table from '../Objects/Table/Table';
import PDFObjectWrapper from './PDFObjectWrapper';
import LineSVG from './LineSVG';
import RectSVG from './RectSVG';
import IconSVG from './IconSVG';
import QRCode from './QRCode';
import Image from './Image';

const calculateBoundingBox = (pdfObjects: PDFObject[]) => {
  const selectedObjects = pdfObjects
    .filter(p => p.isSelected)
    .filter(p => p.type !== PDFObjectType.LINE_SVG);
  const x = Math.min(...selectedObjects.map(p => p.position.x));
  const y = Math.min(...selectedObjects.map(p => p.position.y));
  const width = Math.max(...selectedObjects.map(p => p.position.x + p.size.width));
  const height = Math.max(...selectedObjects.map(p => p.position.y + p.size.height));

  const lineXs = pdfObjects
    .filter(p => p.isSelected)
    .filter(p => p.type === PDFObjectType.LINE_SVG)
    .map(p => (p as LineSVGData).line.x1)
    .concat(
      pdfObjects
        .filter(p => p.isSelected)
        .filter(p => p.type === PDFObjectType.LINE_SVG)
        .map(p => (p as LineSVGData).line.x2)
    );
  const lineYs = pdfObjects
    .filter(p => p.isSelected)
    .filter(p => p.type === PDFObjectType.LINE_SVG)
    .map(p => (p as LineSVGData).line.y1)
    .concat(
      pdfObjects
        .filter(p => p.isSelected)
        .filter(p => p.type === PDFObjectType.LINE_SVG)
        .map(p => (p as LineSVGData).line.y2)
    );
  const lineX = Math.min(...lineXs);
  const lineY = Math.min(...lineYs);
  const lineWidth = Math.max(...lineXs);
  const lineHeight = Math.max(...lineYs);

  return {
    x: Math.min(x, lineX),
    y: Math.min(y, lineY),
    width: Math.max(width, lineWidth),
    height: Math.max(height, lineHeight),
  };
};
/**
 *
 * TODO: Update performance of this component to only update style until drag end then update pdfObjects
 * memoize functions
 */
interface MultiSelectBoxProps {
  pdfObjectsData: PDFObject[];
}
const MultiSelectBox = ({ pdfObjectsData }: MultiSelectBoxProps) => {
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const updatePDFObjects = usePDFStore(state => state.updatePDFObjects);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const [initialPDFObjects] = useState(pdfObjects);
  const pageSize = usePDFStore(state => state.settings.page.size);

  const pdfObjectsRef = useRef(pdfObjects);
  const boundingBox = useMemo(() => calculateBoundingBox(pdfObjects), [pdfObjects]);

  const [position, setPosition] = useState({
    x: boundingBox.x,
    y: boundingBox.y,
  });
  const [width, setWidth] = useState(boundingBox.width);

  const [height, setHeight] = useState(boundingBox.height);

  const positionRef = useRef(position);
  const [objects] = useState(pdfObjectsData);
  const lastUpdatedRef = useRef(Date.now());

  useEffect(() => {
    pdfObjectsRef.current = pdfObjects;
  }, [pdfObjects]);

  const handleUpdatePosition = useCallback(
    (newPosition: { x: number; y: number }) => {
      setPosition(newPosition);

      const selectedObjects = pdfObjectsRef.current.filter(p => p.isSelected);

      const updObj = selectedObjects.map(s => {
        if (s.type === PDFObjectType.LINE_SVG) {
          const initialLine = initialPDFObjects.find(p => p.id === s.id)! as LineSVGData;
          const updateLine = {
            x1: initialLine.line.x1 - (positionRef.current.x - newPosition.x),
            y1: initialLine.line.y1 - (positionRef.current.y - newPosition.y),
            x2: initialLine.line.x2 - (positionRef.current.x - newPosition.x),
            y2: initialLine.line.y2 - (positionRef.current.y - newPosition.y),
          };
          return {
            ...s,
            line: updateLine,
            acknowledged: false,
            acknowledgementId: null,
            localLastUpdated: Date.now(),
          };
        } else {
          const updatedPosition = {
            x:
              initialPDFObjects.find(p => p.id === s.id)!.position.x -
              (positionRef.current.x - newPosition.x),
            y:
              initialPDFObjects.find(p => p.id === s.id)!.position.y -
              (positionRef.current.y - newPosition.y),
          };
          return {
            ...s,
            position: updatedPosition,
            acknowledged: false,
            acknowledgementId: null,
            localLastUpdated: Date.now(),
          };
        }
      });
      const newObjects = initialPDFObjects.map(p => {
        const found = updObj.find(u => u.id === p.id);
        return found ? found : p;
      });
      const boundingBox = calculateBoundingBox(newObjects);
      setWidth(boundingBox.width);

      setHeight(boundingBox.height);
      setOffset({
        x: newPosition.x - positionRef.current.x,
        y: newPosition.y - positionRef.current.y,
      });
      if (Date.now() - lastUpdatedRef.current > 1000) {
        lastUpdatedRef.current = Date.now();
        updatePDFObjects(newObjects);
      }
    },
    [updatePDFObjects, pdfObjectsRef, positionRef, initialPDFObjects]
  );

  const handleDragEnd = useCallback(
    (
      _id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      const selectedObjects = pdfObjectsRef.current.filter(p => p.isSelected);
      const updObj = selectedObjects.map(s => {
        if (s.type === PDFObjectType.LINE_SVG) {
          const initialLine = initialPDFObjects.find(p => p.id === s.id)! as LineSVGData;
          const updateLine = {
            x1: initialLine.line.x1 - (positionRef.current.x - position.x),
            y1: initialLine.line.y1 - (positionRef.current.y - position.y),
            x2: initialLine.line.x2 - (positionRef.current.x - position.x),
            y2: initialLine.line.y2 - (positionRef.current.y - position.y),
          };
          return {
            ...s,
            acknowledged: false,
            acknowledgementId: null,
            localLastUpdated: Date.now(),
            line: updateLine,
          };
        } else {
          const updatedPosition = {
            x:
              initialPDFObjects.find(p => p.id === s.id)!.position.x -
              (positionRef.current.x - position.x),
            y:
              initialPDFObjects.find(p => p.id === s.id)!.position.y -
              (positionRef.current.y - position.y),
          };
          return {
            ...s,
            acknowledged: false,
            acknowledgementId: null,
            localLastUpdated: Date.now(),
            position: updatedPosition,
          };
        }
      });
      const newObjects = initialPDFObjects.map(p => {
        const found = updObj.find(u => u.id === p.id);
        return found ? found : p;
      });
      setOffset({
        x: position.x - positionRef.current.x,
        y: position.y - positionRef.current.y,
      });
      setPosition(position);
      updatePDFObjects(newObjects);
    },
    [updatePDFObjects, pdfObjectsRef, positionRef, initialPDFObjects]
  );

  const { startDrag } = useDraggable({
    x: position.x,
    y: position.y,
    id: 'multi-select',
    updatePosition: handleUpdatePosition,
    onDragEnd: handleDragEnd,
  });

  const handleStartDrag = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      e.preventDefault();
      //
      startDrag(e);
    },
    [startDrag]
  );

  return (
    <>
      {' '}
      <div
        onMouseDown={handleStartDrag}
        style={{
          //relative to the parent

          position: 'absolute',
          zIndex: 9999,
          border: '2px solid rgba(0, 123, 255, 0.5)',
          backgroundColor: 'rgba(0, 123, 255, 0.2)',
          display: 'block',
          left: `${position.x}px`,
          top: `${position.y}px`,
          width: `${width - position.x}px`,
          height: `${height - position.y}px`,
          cursor: 'grab',
        }}
      />
      <div
        style={{
          position: 'absolute',
          width: pageSize.width,
          height: pageSize.height,
          overflow: 'clip',
          zIndex: 9998,
          pointerEvents: 'none',
        }}
      >
        {objects.map(pdfObject => {
          if (pdfObject.type === PDFObjectType.SVG) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <SVG key={pdfObject.id} svgData={pdfObject as SVGData} />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.TEXTBOX) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <TextBox
                  key={pdfObject.id + pdfObject.content}
                  textBoxData={pdfObject as TextBoxData}
                />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.TABLE) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <PDFObjectWrapper key={pdfObject.id} pdfObjectData={pdfObject}>
                  <Table tableData={pdfObject as TableData} updatePDFObject={updatePDFObject} />
                </PDFObjectWrapper>
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.LINE_SVG) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <LineSVG key={pdfObject.id} lineSVGData={pdfObject as LineSVGData} />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.RECT_SVG) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <RectSVG key={pdfObject.id} rectSVGData={pdfObject as RectSVGData} />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.ICON_SVG) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <IconSVG key={pdfObject.id} iconSVGData={pdfObject as IconSVGData} />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.QR_CODE) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <QRCode key={pdfObject.id} qrCodeData={pdfObject as QRCodeData} />
              </div>
            );
          } else if (pdfObject.type === PDFObjectType.IMAGE) {
            return (
              <div
                key={pdfObject.id}
                style={{
                  position: 'absolute',
                  transform: `translate(${pdfObject.isSelected ? offset.x : 0}px, ${pdfObject.isSelected ? offset.y : 0}px)`,
                  width: '100%',
                  height: '100%',
                  zIndex: pdfObject.zIndex,
                }}
              >
                <Image key={pdfObject.id} imageData={pdfObject as ImageData} />
              </div>
            );
          }
          return null;
        })}
      </div>
    </>
  );
};

export default MultiSelectBox;
