import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import * as Sentry from '@sentry/react';
import { APIProvider } from './contexts/APIContext.tsx';
import api from './api/index.ts';
import { APIErrorProvider } from './contexts/APIErrorContext.tsx';

Sentry.init({
  dsn: 'https://422008df45be60f0546b9c8080493201@o4506848961757184.ingest.sentry.io/4506848962871296',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <APIErrorProvider>
      <APIProvider apis={api}>
        <App />
      </APIProvider>
    </APIErrorProvider>
  </React.StrictMode>
);
