import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from './contexts/hooks/useUserContext';

const ProtectedRoute = ({ element }: { element: React.ReactNode }) => {
  const { user } = useUserContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  }, [user, navigate]); // Add dependencies here

  if (!user) {
    // It's generally a good idea to render null or some fallback while waiting for the redirect to happen.
    // This return null will not be reached if the user is not logged in because the useEffect hook will handle the redirect.
    return null;
  }

  return <>{element}</>;
};

export default ProtectedRoute;
