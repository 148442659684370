import { useState } from 'react';
import { PDFService } from '../api/pdfs/PDFService';

// The usePDFDownload hook definition
export const usePdfDownload = () => {
  // State for storing the Blob data
  const [pdfBlob, setPdfBlob] = useState<Blob | null>(null);
  // State for loading and error handling
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const downloadPdf = async (templateId: string, jwt: string) => {
    setError(null);
    setIsLoading(true);
    try {
      const blob = await PDFService.getInstance().downloadPdf(templateId, jwt);
      setPdfBlob(blob);
    } catch (e) {
      setError(
        'There was an error download your PDF. Please try again or contact customer support.'
      );
    } finally {
      setIsLoading(false);
    }
  };

  // Optionally, if you want to automatically start downloading PDF based on certain conditions,
  // you can include useEffect here and call downloadPdf inside it.

  return { downloadPdf, pdfBlob, isLoading, error };
};
