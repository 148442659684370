// ContextMenu.tsx
import React, { useEffect, useRef } from 'react';
import { GoCopy, GoDuplicate, GoPaste, GoTrash } from 'react-icons/go';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import usePDFStore from '../../../stores/usePDFStore';

type Props = {
  visible: boolean;
  x: number;
  y: number;
  onClose: () => void;
  scale: number;
  currentPage: number;
};

const ContextMenu: React.FC<Props> = ({ visible, x, y, onClose, scale, currentPage }) => {
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const { removePDFObjects, setCopyObjects, pasteCopiedObjects, copyObjects, duplicateObjects } =
    usePDFObjectsManager();
  const contextMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };
    if (visible) {
      window.addEventListener('mousedown', handleClick, true);
    }
    return () => {
      window.removeEventListener('mousedown', handleClick, true);
    };
  }, [visible, onClose]);

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    removePDFObjects(pdfObjects.filter(obj => obj.isSelected).map(obj => obj.id));
    onClose();
  };

  const handleCopy = (e: React.MouseEvent) => {
    e.stopPropagation();
    setCopyObjects(pdfObjects.filter(obj => obj.isSelected));
    onClose();
  };

  const handlePaste = (e: React.MouseEvent) => {
    e.stopPropagation();
    pasteCopiedObjects(currentPage);
    onClose();
  };
  const handleDuplicate = (e: React.MouseEvent) => {
    e.stopPropagation();
    duplicateObjects(pdfObjects.filter(obj => obj.isSelected));
    onClose();
  };
  const isItemsSelected = pdfObjects.filter(obj => obj.isSelected).length > 0;
  const isItemsCopied = copyObjects.length > 0;

  const actionsAvailable = isItemsCopied || isItemsSelected;
  //if there arent actions close the context menu
  useEffect(() => {
    if (!actionsAvailable) {
      onClose();
    }
  }, [actionsAvailable, onClose]);

  return (
    actionsAvailable && (
      <div
        className='bg-white shadow-2xl border-2 border-gray-200 w-60 py-2 rounded-md'
        style={{
          transform: `translate(${x}px, ${y}px ) scale(${1 / scale})`,
          transformOrigin: 'top left',
          position: 'absolute',
          zIndex: 99999,
        }}
        ref={contextMenuRef}
      >
        <ul className='text-gray-800 bg-white'>
          {isItemsSelected && (
            <>
              <li
                className='flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer'
                onMouseDown={handleCopy}
              >
                <div className='flex items-center'>
                  <GoCopy className='mr-3' size={25} />
                  Copy
                </div>
                <div className='bg-gray-200 text-gray-800 text-[14px] px-2 py-1 rounded'>
                  Ctrl+C
                </div>
              </li>
              <li
                className='flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer'
                onMouseDown={handleDelete}
              >
                <div className='flex items-center'>
                  <GoTrash className='mr-3' size={25} />
                  Delete
                </div>
                <div className='bg-gray-200 text-gray-800 text-[14px]  px-2 py-1 rounded'>
                  DELETE
                </div>
              </li>
              <li
                className='flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer'
                onMouseDown={handleDuplicate}
              >
                <div className='flex items-center'>
                  <GoDuplicate className='mr-3' size={25} />
                  Duplicate
                </div>
                <div className='bg-gray-200 text-gray-800 text-[14px] px-2 py-1 rounded'>
                  Ctrl+D
                </div>
              </li>
            </>
          )}
          {isItemsCopied && (
            <li
              className='flex items-center justify-between px-4 py-2 hover:bg-gray-100 cursor-pointer'
              onMouseDown={handlePaste}
            >
              <div className='flex items-center'>
                <GoPaste className='mr-3' size={25} />
                Paste
              </div>
              <div className='bg-gray-200 text-gray-800 text-[14px] px-2 py-1 rounded'>Ctrl+V</div>
            </li>
          )}
          {/* Add more options as needed */}
        </ul>
      </div>
    )
  );
};

export default ContextMenu;
