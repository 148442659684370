import { PermissionType } from '../../../api/templates/enums';
import { TemplateMember } from '../../../api/templates/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useAddMember = () => {
  const { makeCall, response, isLoading, error } = useApiCall<TemplateMember>();
  const { templateService } = useAPIContext();

  const addMember = async (
    templateId: string,
    userId: string,
    permissionType: PermissionType,
    jwt: string
  ) => {
    await makeCall(signal => {
      return templateService.addMember(templateId, userId, permissionType, jwt, signal);
    });
  };

  return { addMember, response, isLoading, error };
};
