import useResizable from '../../../hooks/pdf/useResizable';
import ResizeHandle from './ResizeHandle';
import { SVGData } from '../../../types/PDFObjects';
import { useCallback, useEffect, useMemo, useRef, useState, CSSProperties, memo } from 'react';
import useDraggable from '../../../hooks/pdf/useDraggable';
import usePDFStore from '../../../stores/usePDFStore';

interface SVGProps {
  svgData: SVGData;
}
const SVGComponent = memo(({ svgData }: SVGProps) => {
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const [position, setPosition] = useState({
    x: svgData.position.x,
    y: svgData.position.y,
  });
  const [size, setSize] = useState({
    width: svgData.size.width,
    height: svgData.size.height,
  });
  const objectRef = useRef<HTMLDivElement>(null);
  const handleDragEnd = useCallback(
    (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      updatePDFObject(id, { position });
    },
    [updatePDFObject]
  );

  const handleResizeEnd = useCallback(
    (size: { width: number; height: number }) => {
      updatePDFObject(svgData.id, { size });
    },
    [updatePDFObject, svgData.id]
  );

  const handleUpdatePosition = useCallback(
    (position: { x: number; y: number }) => {
      setPosition(position);
    },
    [setPosition]
  );

  useEffect(() => {
    handleUpdatePosition(svgData.position);
  }, [svgData.position, handleUpdatePosition]);

  useEffect(() => {
    setSize(svgData.size);
  }, [svgData.size]);

  const { dragging, startDrag } = useDraggable({
    x: svgData.position.x,
    y: svgData.position.y,
    id: svgData.id,
    updatePosition: (position: { x: number; y: number }) => {
      handleUpdatePosition(position);
    },
    onDragEnd: (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      handleDragEnd(id, position);
    },
  });

  const handleResize = useCallback(
    (size: { width: number; height: number }) => {
      setSize(size);
    },
    [setSize]
  );

  const { startResize } = useResizable({
    width: size.width,
    height: size.height,
    updateSize: useCallback(
      size => {
        handleResize(size);
      },
      [handleResize]
    ),
    onResizeEnd: handleResizeEnd,
    aspectRatio: size.width / size.height,
  });

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setCurrentSelections([svgData.id]);
      startDrag(e);
    },
    [setCurrentSelections, svgData.id, startDrag]
  );

  const containerStyle: CSSProperties = useMemo(
    () => ({
      transform: `translate(${position.x}px, ${position.y}px)`,
      width: `${size.width}px`,
      maxWidth: `${size.width}px`,
      height: `${size.height}px`,
      maxHeight: `${size.height}px`,
      cursor: dragging ? 'grabbing' : 'grab',
      position: 'absolute',
      display: 'inline-block',
      overflow: 'visible',
      outline: svgData.isSelected
        ? '2px solid #007AFF' // A modern blue, softer than stark blue
        : '', // No border if neither editing nor selected
      transition: 'outline 0.1s ease, box-shadow 0.2s ease', // Smooth transition for visual changes
      zIndex: `${svgData.zIndex}`,
      pointerEvents: 'auto',
    }),
    [position.x, position.y, size.width, size.height, svgData.isSelected, svgData.zIndex, dragging]
  );

  //memoize the class name
  const selectedClassNames = useMemo(
    () => (svgData.isSelected ? '' : 'pdfComponent'),
    [svgData.isSelected]
  );

  return (
    <>
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'visble',
        }}
      >
        <div onMouseDown={handleMouseDown} className={selectedClassNames} style={containerStyle}>
          {svgData.isSelected && (
            <>
              <ResizeHandle position='bottom-right' onStartResize={startResize} />
              <ResizeHandle position='bottom-left' onStartResize={startResize} />
              <ResizeHandle position='top-right' onStartResize={startResize} />
              <ResizeHandle position='top-left' onStartResize={startResize} />
            </>
          )}
        </div>
      </div>
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'hidden',
        }}
      >
        <div
          ref={objectRef}
          onMouseDown={handleMouseDown}
          className={selectedClassNames}
          style={containerStyle}
        >
          <svg
            id={svgData.id}
            xmlns='http://www.w3.org/2000/svg'
            style={{
              width: '100%',
              height: '100%',
            }}
            dangerouslySetInnerHTML={{ __html: svgData.content }}
          />
          {svgData.isSelected && (
            <>
              <ResizeHandle position='bottom-right' onStartResize={startResize} />
              <ResizeHandle position='bottom-left' onStartResize={startResize} />
              <ResizeHandle position='top-right' onStartResize={startResize} />
              <ResizeHandle position='top-left' onStartResize={startResize} />
            </>
          )}
        </div>
      </div>
    </>
  );
});

SVGComponent.displayName = 'SVGComponent';

export default SVGComponent;
