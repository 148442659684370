enum FontFamilies {
  Roboto = 'Roboto',
  JosefinSans = 'JosefinSans',
  Montserrat = 'Montserrat',
  OpenSans = 'OpenSans',
  NotoSans = 'NotoSans',
  Lato = 'Lato',
}

const FontFamilyMarginTopScale: Record<FontFamilies, number> = {
  [FontFamilies.Roboto]: 0.09,
  [FontFamilies.JosefinSans]: 0,
  [FontFamilies.Montserrat]: 0.11,
  [FontFamilies.OpenSans]: 0.18,
  [FontFamilies.NotoSans]: 0.18,
  [FontFamilies.Lato]: 0.1,
};

export { FontFamilies, FontFamilyMarginTopScale };
