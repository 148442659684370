import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthPageContainer from '../../../features/auth/components/AuthPageContainer';
import AuthContentCard from '../../../features/auth/components/AuthContentCard';
import InputText from '../../../components/ui/InputText';
import FlatButton from '../../../components/ui/FlatButton';
import PasswordInput from '../../../components/ui/PasswordInput';
import { useAPIErrorContext } from '../../../contexts/hooks/useAPIErrorContex';
import { Typography } from '@material-tailwind/react';
import { useSetupAccount } from '../../../features/auth/hooks/useSetupAccout';

const SetupAccountPage = () => {
  const [userDetails, setUserDetails] = useState({
    password: '',
    confirmPassword: '',
    name: '',
  });
  const { setupAccount, isLoading, error, response } = useSetupAccount();
  const [errors, setErrors] = useState({
    passwordMismatch: false,
    passwordShort: false,
    passwordEmpty: false, // New error state for empty password
    confirmPasswordEmpty: false, // New error state for empty confirmPassword
    nameEmpty: false, // New error state for empty name
  });
  const navigate = useNavigate();
  const [registrationSuccessful, setRegistrationSuccessful] = useState(false);
  const { setError } = useAPIErrorContext();
  const [token, setToken] = useState('');

  useEffect(() => {
    const token = new URLSearchParams(location.search).get('token');

    if (!token) {
      navigate('/login');
      return;
    }
    setToken(token);
  }, []);

  useEffect(() => {
    setError(error);
  }, [error, setError]);

  const handlePasswordChange = (value: string) => {
    setErrors({
      passwordMismatch: false,
      passwordShort: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
      nameEmpty: false,
    });
    setUserDetails({ ...userDetails, password: value });
  };

  const handleConfirmPasswordChange = (value: string) => {
    setErrors({
      passwordMismatch: false,
      passwordShort: false,
      passwordEmpty: false,
      confirmPasswordEmpty: false,
      nameEmpty: false,
    });
    setUserDetails({ ...userDetails, confirmPassword: value });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      passwordMismatch: userDetails.password !== userDetails.confirmPassword,
      passwordShort: userDetails.password.length < 8,
      passwordEmpty: !userDetails.password,
      confirmPasswordEmpty: !userDetails.confirmPassword,
      nameEmpty: !userDetails.name,
    };

    // Check if any error is true to set isValid to false
    isValid = !Object.values(newErrors).includes(true);

    setErrors(newErrors);

    return isValid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      setupAccount(userDetails.name, userDetails.password, token);
    }
  };

  useEffect(() => {
    if (response) {
      setRegistrationSuccessful(true);
    }
  }, [response, navigate]);

  const handleChangeName = (value: string) => {
    setUserDetails({ ...userDetails, name: value });
  };

  if (registrationSuccessful) {
    // Show confirmation screen if registration is successful
    return (
      <AuthPageContainer>
        <AuthContentCard>
          <AuthContentCard.Logo />
          <AuthContentCard.Title>Setup Successful</AuthContentCard.Title>
          <p className='mt-4 text-center'>
            <Typography variant='lead'>
              Your account has been successfully setup. You can now login.
            </Typography>
          </p>
          <div className='mt-6 text-center'>
            <a href='/login' className='text-indigo-600 hover:underline'>
              Go to Login
            </a>
          </div>
        </AuthContentCard>
      </AuthPageContainer>
    );
  }

  return (
    <AuthPageContainer>
      <AuthContentCard>
        <AuthContentCard.Logo />
        <AuthContentCard.Title>Welcome To ScribePDF</AuthContentCard.Title>
        <br />
        <div className='text-center mb-4'>
          <Typography variant='lead'>Let&apos;s setup your account</Typography>
        </div>
        <div className='mb-4'>
          <label htmlFor='name' className='block mb-2 text-sm text-gray-600'>
            Name
          </label>
          <InputText
            handleChange={handleChangeName}
            value={userDetails.name}
            placeholder='Enter your name'
            id='name'
          />
          {errors.nameEmpty && (
            <p className='text-red-500 text-xs mt-1'>Please fill out this field</p>
          )}
        </div>

        <div className='mb-4 relative'>
          <label htmlFor='password' className='block mb-2 text-sm text-gray-600'>
            Password
          </label>
          <PasswordInput
            value={userDetails.password}
            onChange={handlePasswordChange}
            placeholder='Enter your password'
            id='password'
          />
          {errors.passwordShort && (
            <p className='text-red-500 text-xs mt-1'>
              Password needs to be longer than 8 characters
            </p>
          )}
        </div>

        <div className='mb-4 relative'>
          <label htmlFor='confirmPassword' className='block mb-2 text-sm text-gray-600'>
            Confirm Password
          </label>
          <PasswordInput
            value={userDetails.confirmPassword}
            onChange={handleConfirmPasswordChange}
            placeholder='Confirm your password'
            id='confirmPassword'
          />
          {errors.passwordMismatch && (
            <p className='text-red-500 text-xs mt-1'>Passwords do not match</p>
          )}
        </div>
        <FlatButton text='Finish' onClick={handleSubmit} disabled={isLoading} className='my-5' />
        <p className='text-sm text-center text-gray-500'>
          Already have an account?{' '}
          <a
            href='/login'
            className='text-indigo-600 focus:outline-none focus:underline hover:underline'
          >
            Log in
          </a>
          .
        </p>
      </AuthContentCard>
    </AuthPageContainer>
  );
};

export default SetupAccountPage;
