import { useState } from 'react';
import { AuthResponse } from '../../../api/auth/types';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';

export interface UseExchangeGoogleAuthCodeReturn {
  exchangeAuthCode: (code: string) => Promise<void>;
  authResponse: AuthResponse | null;
  isLoading: boolean;
  sentRequest: boolean;
  setSentRequest: React.Dispatch<React.SetStateAction<boolean>>;
  error: string | null;
}

export const useExchangeGoogleAuthCode = (): UseExchangeGoogleAuthCodeReturn => {
  const { makeCall, response, isLoading, error } = useApiCall<AuthResponse>();
  const [sentRequest, setSentRequest] = useState(false);
  const { authService } = useAPIContext();

  const exchangeAuthCode = async (code: string) => {
    setSentRequest(true);
    await makeCall(signal => authService.exchangeGoogleAuthCodeForJwt(code, signal));
  };

  return {
    exchangeAuthCode,
    authResponse: response,
    isLoading,
    error,
    sentRequest,
    setSentRequest,
  };
};
