import ObjectAlignment from './ObjectAlignment';
import { TextBoxData } from '../../../types/PDFObjects';
import ObjectPositioning from './ObjectPosition';
import { MdInfo } from 'react-icons/md';
import React from 'react';
import usePDFObjectsManager from '../../../hooks/pdf/usePDFObjectsManager';
import ObjectSize from './ObjectSize';
import usePDFStore from '../../../stores/usePDFStore';

export interface TextBoxToolsPanelProps {
  textBoxData: TextBoxData;
}

const TextBoxToolsPanel = ({ textBoxData }: TextBoxToolsPanelProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const { alignObject } = usePDFObjectsManager();

  const handlePositionChange = (axis: 'x' | 'y', value: string) => {
    const newPosition = { ...textBoxData.position, [axis]: Math.round(parseFloat(value)) || 0 };
    updatePDFObject(textBoxData.id, { position: newPosition });
  };

  const handleSizeChange = (axis: 'width' | 'height', value: string) => {
    console.log('parsed value', parseFloat(value));
    //round the value to 2 decimal places

    const newSize = { ...textBoxData.size, [axis]: Math.round(parseFloat(value)) || 0 };
    updatePDFObject(textBoxData.id, {
      size: newSize,
    });
  };

  const handleDynamicTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    updatePDFObject<TextBoxData>(textBoxData.id, { isDynamic: e.target.checked });
  };

  return (
    <div
      data-testid='right-side-toolbar'
      className='flex flex-col items-start rounded-lg bg-white p-4 space-y-2'
      style={{ width: '16rem' }}
    >
      <ObjectAlignment pdfObject={textBoxData} alignObject={alignObject} />
      <div className='flex items-center space-x-2 mt-5'>
        <div className='flex items-center'>
          <input
            id='dynamicText'
            type='checkbox'
            className='w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500'
            onChange={handleDynamicTextChange}
            checked={textBoxData.isDynamic}
          />
          <label htmlFor='dynamicText' className='ml-2 block text-sm text-gray-900'>
            Dynamic Text
          </label>
          <div className='tooltip'>
            <MdInfo size={18} className='text-indigo-500 ml-1' />
            <span className='tooltiptext mx-2'>
              You can automatically fill this field via API using its JSON key below.
            </span>
          </div>
        </div>
      </div>

      <div className='w-full'>
        <div className='mt-0 flex rounded shadow-sm w-full mb-2'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            Key
          </span>
          <input
            type='text'
            className={`block w-full min-w-0 flex-1 rounded-r border-0 py-1 ${textBoxData.isDynamic ? 'text-gray-900 bg-white' : 'text-gray-400 bg-gray-200'} ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none`}
            value={textBoxData.jsonKey}
            onChange={e => updatePDFObject(textBoxData.id, { jsonKey: e.target.value })}
            disabled={!textBoxData.isDynamic}
          />
        </div>
        <ObjectPositioning pdfObject={textBoxData} handlePositionChange={handlePositionChange} />
        <ObjectSize pdfObject={textBoxData} handleSizeChange={handleSizeChange} />
      </div>
    </div>
  );
};

export default TextBoxToolsPanel;
