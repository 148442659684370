import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
  flexRender,
  getFilteredRowModel,
  getPaginationRowModel,
} from '@tanstack/react-table';
import { Template } from '../../api/templates/types';
import CheckBox from '../ui/CheckBox';
import {
  Chip,
  IconButton,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
  Tooltip,
  Typography,
} from '@material-tailwind/react';
import { ArrowLeftIcon, ArrowRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { useDeleteTemplate } from '../../hooks/api/templates/useDeleteTemplate';
import { useNavigate } from 'react-router-dom';
import { HiOutlinePlus, HiTrash } from 'react-icons/hi2';
import SearchBar from '../ui/SearchBar';
import AlertConfirm from '../ui/AlertConfirm';
import { useBulkDeleteTemplates } from '../../hooks/api/templates/useBulkDeleteTemplates';
import { useGetTemplates } from '../../hooks/api/templates/useGetTemplates';
import SharingModal from './Sharing';
import TableLoading from '../ui/TableLoading';

const getInitials = (user: { name?: string; email: string }) => {
  let initials = 'U'; // Default initials if user data is not available
  if (user?.name) {
    initials = user.name
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase(); // Or construct from name if available
  } else if (user?.name) {
    initials = user.email[0].toUpperCase(); // Use the first letter of the email, capitalized
  }
  return initials;
};

const columnHelper = createColumnHelper<Template>();

const Templates = () => {
  const { deleteTemplate, response: deleteTemplateResponse } = useDeleteTemplate();
  const { jwt } = useUserContext();
  const navigate = useNavigate();
  const [rowSelection, setRowSelection] = useState({});
  const [filter, setFilter] = useState('');
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [deletingTemplateIds, setDeletingTemplateIds] = useState<string[]>([]);
  const [deletingTemplateId, setDeletingTemplateId] = useState<string>('');
  const { response, getTemplates, isLoading } = useGetTemplates();
  const { deleteTemplates, response: deleteTemplatesResponse } = useBulkDeleteTemplates();
  const [templates, setTemplates] = useState<Template[]>([]);
  const [isSharingModalOpen, setIsSharingModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);

  useEffect(() => {
    getTemplates(jwt!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setTemplates(response);
    }
  }, [response]);

  useEffect(() => {
    if (deleteTemplateResponse) {
      setTemplates(prev => prev.filter(template => template.templateId !== deletingTemplateId));
    }
  }, [deleteTemplateResponse, deletingTemplateId]);

  const handleEditor = useCallback(
    (templateId: string) => {
      navigate(`/templates/${templateId}/editor`);
    },
    [navigate]
  );

  const columns = useMemo(
    () => [
      //create a column for the checkbox
      columnHelper.display({
        id: 'selection',
        cell: info => {
          return (
            <CheckBox
              checked={info.row.getIsSelected()}
              handleChange={info.row.getToggleSelectedHandler()}
            />
          );
        },
        header: info => {
          return (
            <CheckBox
              checked={info.table.getIsAllPageRowsSelected()}
              handleChange={info.table.getToggleAllPageRowsSelectedHandler()}
            />
          );
        },
      }),
      columnHelper.accessor('name', {
        cell: info => (
          <div className='flex items-center gap-3'>
            <div className='flex flex-col'>
              <Typography variant='h6' color='blue-gray' className='font-bold'>
                {info.row.original.name}
              </Typography>
              <Typography variant='small' color='blue-gray' className='font-normal opacity-70'>
                {new Date(info.row.original.createdAt).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'long',
                  year: 'numeric',
                })}
              </Typography>
            </div>
          </div>
        ),
        filterFn: 'includesString',
        header: 'Name',
      }),
      columnHelper.accessor('user.name', {
        cell: info => (
          <Tooltip placement='bottom' content={info.row.original.name}>
            <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white cursor-pointer'>
              {getInitials(info.row.original.user)}
            </div>
          </Tooltip>
        ),
        header: 'Owner',
        enableGlobalFilter: true,
        filterFn: 'includesString',
      }),
      columnHelper.accessor('status', {
        cell: info => (
          <div className='w-max'>
            <Chip
              variant='ghost'
              size='sm'
              value={info.getValue()}
              color={info.getValue() !== 'draft' ? 'green' : 'blue-gray'}
            />
          </div>
        ),
        header: 'Status',
        filterFn: 'includesString',
      }),
      columnHelper.accessor('updatedAt', {
        cell: info => (
          <Typography variant='small' color='blue-gray' className='font-normal'>
            {new Date(info.getValue()).toLocaleDateString('en-US', {
              day: 'numeric',
              month: 'long',
              year: 'numeric',
            })}
          </Typography>
        ),
        header: 'Last Updated',
        filterFn: 'includesString',
      }),
      columnHelper.display({
        id: 'actions',
        cell: info => (
          <Menu placement='left'>
            <MenuHandler>
              <IconButton variant='text'>
                <EllipsisHorizontalIcon className='h-7 w-7' color='black' />
              </IconButton>
            </MenuHandler>
            <MenuList>
              <MenuItem onClick={() => handleEditor(info.row.original.templateId)}>
                Open Editor
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setDeletingTemplateId(info.row.original.templateId);
                  deleteTemplate(info.row.original.templateId, jwt!);
                }}
              >
                Delete
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setSelectedTemplate(info.row.original);
                  setIsSharingModalOpen(true);
                }}
              >
                Share
              </MenuItem>
            </MenuList>
          </Menu>
        ),
        header: 'Actions',
      }),
    ],
    [deleteTemplate, jwt, handleEditor]
  );

  const table = useReactTable({
    data: templates,
    columns,
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection,
      globalFilter: filter,
    },
    onGlobalFilterChange: setFilter,
    globalFilterFn: 'includesString',
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    getPaginationRowModel: getPaginationRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    table.setPageSize(7);
  }, [table]);

  useEffect(() => {
    table.resetRowSelection();
  }, [templates, filter, table]);

  const handleConfirmDelete = () => {
    setIsConfirmDeleteOpen(false);
    const deletingTemplateIds = table
      .getFilteredSelectedRowModel()
      .rows.map(row => row.original.templateId);
    setDeletingTemplateIds(deletingTemplateIds);
    deleteTemplates(deletingTemplateIds, jwt!);
  };

  useEffect(() => {
    //handle bulk delete
    if (deleteTemplatesResponse) {
      setTemplates(prev =>
        prev.filter(template => !deletingTemplateIds.includes(template.templateId))
      );
    }
  }, [deleteTemplatesResponse, deletingTemplateIds]);
  return (
    <div className='h-full w-full'>
      {selectedTemplate && (
        <SharingModal
          dialogOpen={isSharingModalOpen}
          handleOpen={() => setIsSharingModalOpen(!isSharingModalOpen)}
          templateId={selectedTemplate.templateId}
        />
      )}
      <AlertConfirm
        dialogOpen={isConfirmDeleteOpen}
        handleOpen={() => setIsConfirmDeleteOpen(!isConfirmDeleteOpen)}
        handleConfirm={handleConfirmDelete}
        handleCancel={() => setIsConfirmDeleteOpen(false)}
        message={`Are you sure you want to delete ${
          table.getFilteredSelectedRowModel().rows.length
        } Template(s)?`}
        buttonLabel='Delete'
      />
      <div className='flex justify-between gap-2'>
        <div className='flex flex-row'>
          <Typography variant='h3' color='blue-gray' className='font-bold p-5 slide-in-from-left'>
            Templates
          </Typography>
          <SearchBar value={filter} handleChange={setFilter} placeholder='Find your template' />
        </div>
        <div className='flex flex-row gap-10 mx-10'>
          {Object.keys(rowSelection).length > 0 && (
            <div className='flex items-center gap-2'>
              <div className='flex flex-row items-center'>
                <Menu placement='bottom'>
                  <MenuHandler>
                    <button className='bg-gray-200 p-2 border-2 border-indigo-200 rounded hover:bg-gray-300'>
                      <Typography variant='small' color='blue-gray' className='font-normal'>
                        <strong>{Object.keys(rowSelection).length}</strong> Selected
                      </Typography>
                    </button>
                  </MenuHandler>
                  <MenuList>
                    <MenuItem
                      onClick={() => {
                        setIsConfirmDeleteOpen(true);
                      }}
                    >
                      <div className='flex justify-left items-center gap-5 w-full h-full'>
                        <HiTrash size={20} />
                        Delete
                      </div>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </div>
            </div>
          )}
          <div className='flex items-center gap-2'>
            <div className='flex flex-col'>
              {' '}
              <div className='flex flex-row gap-2 justify-center items-center'>
                <IconButton
                  variant='outlined'
                  onClick={() => table.previousPage()}
                  disabled={!table.getCanPreviousPage()}
                  className='h-8 w-8'
                >
                  <ArrowLeftIcon strokeWidth={2} className='h-4 w-4' color='black' />
                </IconButton>

                <Typography variant='small' color='blue-gray' className='font-normal p-1'>
                  <strong>
                    {table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                      1}{' '}
                    -
                    {table.getState().pagination.pageIndex * table.getState().pagination.pageSize +
                      table.getPaginationRowModel().rows.length}
                  </strong>{' '}
                  of <strong>{table.getFilteredRowModel().rows.length}</strong>
                </Typography>
                <IconButton
                  variant='outlined'
                  onClick={() => table.nextPage()}
                  disabled={!table.getCanNextPage()}
                  className='h-8 w-8'
                >
                  <ArrowRightIcon strokeWidth={2} className='h-4 w-4' color='black' />
                </IconButton>
              </div>
            </div>
          </div>
          <div className='flex items-center justify-center'>
            <button
              onClick={() => {
                navigate('/templates/create');
              }}
              className='flex items-center justify-center bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 gap-2'
            >
              <HiOutlinePlus size={25} />
              Create
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          maxHeight: 'calc(100vh - 13rem)',
          overflowY: 'auto',
        }}
        className='shadow-sm rounded-md overflow-x-auto'
      >
        <div className='p-2'>
          <table className='w-full min-w-max table-auto text-left bg-white'>
            <thead className='bg-white sticky top-0 z-10 shadow-sm'>
              {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map(header => (
                    <th key={header.id} className='p-4 text-blue-gray-700'>
                      {header.isPlaceholder
                        ? null
                        : flexRender(header.column.columnDef.header, header.getContext())}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className='pt-4 bg-gray-50'>
              {isLoading && <TableLoading />}
              {!isLoading &&
                table.getRowModel().rows.map(row => (
                  <tr
                    key={row.original.templateId}
                    className={`${row.getIsSelected() ? 'bg-blue-200' : ''}`}
                  >
                    {row.getVisibleCells().map(cell => (
                      <td key={cell.id} className='p-4 border-b border-blue-gray-50'>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </table>
          <div className='h-4' />
        </div>
      </div>
    </div>
  );
};

export default Templates;
