import { useState, useEffect, useRef } from 'react';
import { APIException } from '../../../api/exceptions/APIException';
import { AbortedException } from '../../../api/exceptions/AbortedExceptions';
import { useUserContext } from '../../../contexts/hooks/useUserContext';

// Adjust the type signature to accept a function that returns a Promise<T>, and include an AbortSignal
export const useApiCall = <T>() => {
  const [response, setResponse] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const abortControllerRef = useRef<AbortController | null>(null);
  const { clearSession } = useUserContext();
  const currentRequestId = useRef<number>(0);

  const makeCall = async (apiFunc: (signal: AbortSignal) => Promise<T>) => {
    currentRequestId.current++;
    const currentRequestIdValue = currentRequestId.current;
    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    setError(null);
    setIsLoading(true);
    setResponse(null);
    try {
      const response = await apiFunc(abortController.signal);
      setResponse(response);
    } catch (e) {
      if (e instanceof APIException) {
        if (e.status === 401) {
          clearSession();
        }

        let message = '';
        if (e.response && typeof e.response === 'string' && e.response.length > 0) {
          message = e.response;
        } else {
          message = 'An unexpected error occurred';
        }
        setError(message);
      } else if (!(e instanceof AbortedException)) {
        setError('An unexpected error occurred');
        throw e;
      }
    } finally {
      if (currentRequestIdValue === currentRequestId.current) {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  return { makeCall, response, isLoading, error };
};
