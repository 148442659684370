import { AxiosError } from 'axios';
import api from '../api';
import { AuthResponse } from './types';
import { APIException } from '../exceptions/APIException';

export interface IAuthService {
  exchangeGoogleAuthCodeForJwt(authCode: string, signal: AbortSignal): Promise<AuthResponse>;
  login(email: string, password: string, signal: AbortSignal): Promise<AuthResponse>;
}

export class AuthService implements IAuthService {
  public static instance: AuthService;

  public static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  private constructor() {}

  public async exchangeGoogleAuthCodeForJwt(
    authCode: string,
    signal: AbortSignal
  ): Promise<AuthResponse> {
    try {
      const response = await api.post(
        '/auth/google',
        {
          code: authCode,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }

  public async login(email: string, password: string, signal: AbortSignal): Promise<AuthResponse> {
    try {
      const response = await api.post(
        '/auth/login',
        {
          email,
          password,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          signal,
        }
      );
      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new APIException(error.message, error.response?.data, error.response?.status);
      }
      throw error;
    }
  }
}
