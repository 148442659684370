import { Button, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import SearchBar from '../ui/SearchBar';
import { HiBuildingOffice2, HiCheck } from 'react-icons/hi2';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetAllUsers } from '../../features/users/hooks/useGetAllUsers';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { UserDTO } from '../../api/user/types';
import { useAddMember } from '../../features/templates/hooks/addMember';
import { useUpdateMember } from '../../features/templates/hooks/updateMember';
import { PermissionType } from '../../api/templates/enums';
import { TemplateMember } from '../../api/templates/types';

interface ShareTemplateProps {
  templateId: string;
}

const getInitials = (user: { name?: string; email: string }) => {
  let initials = 'U'; // Default initials if user data is not available
  if (user?.name) {
    initials = user.name
      .split(' ')
      .map(name => name[0])
      .join('')
      .toUpperCase(); // Or construct from name if available
  } else if (user?.name) {
    initials = user.email[0].toUpperCase(); // Use the first letter of the email, capitalized
  }
  return initials;
};

const ShareTemplate = ({ templateId }: ShareTemplateProps) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [memberOpenMenu, setMemberOpenMenu] = useState<string | null>(null);
  const [openMenu2, setOpenMenu2] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [inputFocus, setInputFocus] = useState(false);
  const [users, setUsers] = useState<UserDTO[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<UserDTO[]>([]);
  const { getAllUsers, response } = useGetAllUsers();
  const [members, setMembers] = useState<TemplateMember[]>([]);
  const navigate = useNavigate();
  const { jwt, user } = useUserContext();
  const { addMember, response: addMemberResponse } = useAddMember();
  const { updateMember, response: updateMemberResponse } = useUpdateMember();

  useEffect(() => {
    if (addMemberResponse) {
      setMembers(prev => [...prev, addMemberResponse]);
    }
  }, [addMemberResponse]);

  useEffect(() => {
    if (updateMemberResponse) {
      setMembers(prev =>
        prev.map(member => {
          if (member.userId === updateMemberResponse.userId) {
            return updateMemberResponse;
          }
          return member;
        })
      );
    }
  }, [updateMemberResponse]);

  useEffect(() => {
    getAllUsers(jwt!);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (response) {
      setUsers(response);
      //set top 5 users
      setFilteredUsers(response.slice(0, 5));
    }
  }, [response]);

  useEffect(() => {
    if (updateMemberResponse) {
      setMembers(prev =>
        prev.map(member => {
          if (member.templateMemberId === updateMemberResponse.templateMemberId) {
            return updateMemberResponse;
          }
          return member;
        })
      );
    }
  }, [updateMemberResponse]);

  useEffect(() => {
    if (searchValue) {
      const filtered = users.filter(user => {
        let found = false;
        if (user.email) {
          found = user.email.toLowerCase().includes(searchValue.toLowerCase());
        }
        if (user.name) {
          found = found || user.name.toLowerCase().includes(searchValue.toLowerCase());
        }
        return found;
      });
      //set top 5 users

      setFilteredUsers(filtered.slice(0, 5));
    } else {
      setFilteredUsers(users.slice(0, 5));
    }
  }, [searchValue, users]);

  const handleSearchChange = (value: string) => {
    setSearchValue(value);
  };
  const handleFocus = () => {
    setInputFocus(true);
  };
  const handleBlur = () => {
    setInputFocus(false);
  };

  const handlePermissionChange = (templateMemberId: string, permissionType: PermissionType) => {
    updateMember(templateId, templateMemberId, permissionType, jwt!);
  };

  return (
    <div className='flex flex-col justify-center items-center w-full text-black mb-5'>
      <div className='flex flex-col justify-center w-full'>
        <div className='flex-row flex justify-between'>
          <div className='text-2xl font-bold text-black'>Invite Teammates</div>
          <Button
            color='indigo'
            variant='outlined'
            onClick={() => {
              navigate(`/templates/${templateId}/editor`);
            }}
          >
            {members.length === 0 ? 'Skip' : 'Done'}
          </Button>
        </div>
        <br />
        <div className='text-lg text-gray-700 text-center'>
          Add people to start collaborating on this PDF Template.
        </div>
        <div className='pt-5 w-full justify-center'>
          <SearchBar
            value={searchValue}
            placeholder='Invite by name or email'
            handleChange={handleSearchChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          {inputFocus && response && (
            <div className='absolute mt-1 w-full bg-white border border-gray-300 rounded shadow-lg z-10 xl:w-2/3 l:w-2/3 md:w-full sm:w-full'>
              {filteredUsers.length === 0 && (
                <div className='p-3 text-gray-700'>No users found with that name or email</div>
              )}
              {filteredUsers.map(user => (
                <div
                  key={user.userId}
                  className='p-3 hover:bg-gray-100 cursor-pointer flex flex-row justify-between items-center'
                  onMouseDown={() => {
                    addMember(templateId, user.userId, PermissionType.EDITOR, jwt!);
                  }}
                >
                  <div className='flex flex-row gap-4 items-center'>
                    <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white cursor-pointer'>
                      {getInitials(user)}
                    </div>
                    <div className='flex flex-col'>
                      <div className='text-md'>{user.name}</div>
                      <div className='text-sm text-gray-700'>{user.email}</div>
                    </div>
                  </div>
                  <div className='mr-5 text-gray-700'>{user.role.name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className='text-lg font-bold text-black py-5'>People With Access</div>
        <div className='flex flex-col gap-4'>
          {members &&
            members.map(member => (
              <div
                key={member.templateMemberId}
                className='flex flex-row gap-4 items-center justify-between'
              >
                <div className='flex gap-4 items-center'>
                  <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white cursor-pointer'>
                    {getInitials({
                      name: member.user.name,
                      email: member.user.email,
                    })}
                  </div>
                  <div className='flex flex-col'>
                    <div className='text-lg'>{member.user.name}</div>
                    <div className='text-gray-700 text-md'>{member.user.email}</div>
                  </div>
                </div>
                <div>
                  <Menu
                    placement='bottom-end'
                    open={memberOpenMenu === member.userId ? true : false}
                    handler={() =>
                      setMemberOpenMenu(prev => (prev === member.userId ? null : member.userId))
                    }
                  >
                    <MenuHandler>
                      <button className='flex flex-row bg-white p-2 border-2 border-gray-400 rounded  items-center gap-3 text-black'>
                        {member.permissionType === PermissionType.EDITOR ? 'Editor' : 'Viewer'}
                        <ChevronDownIcon
                          strokeWidth={3}
                          className={`h-3.5 w-3.5 transition-transform ${openMenu ? 'rotate-180' : ''}`}
                        />
                      </button>
                    </MenuHandler>
                    <MenuList>
                      <MenuItem
                        onClick={() =>
                          handlePermissionChange(member.templateMemberId, PermissionType.EDITOR)
                        }
                      >
                        <div className='flex justify-left items-center gap-5 w-full h-full'>
                          Editor
                          {member.permissionType === PermissionType.EDITOR && <HiCheck size={20} />}
                        </div>
                      </MenuItem>
                      <MenuItem
                        onClick={() =>
                          handlePermissionChange(member.templateMemberId, PermissionType.VIEWER)
                        }
                      >
                        <div className='flex justify-left items-center gap-5 w-full h-full'>
                          Viewer
                          {member.permissionType === PermissionType.VIEWER && <HiCheck size={20} />}
                        </div>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </div>
              </div>
            ))}
          <div className='flex flex-row gap-4 items-center justify-between'>
            <div className='flex gap-4 items-center'>
              <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white cursor-pointer'>
                {getInitials({
                  name: user!.name ?? '',
                  email: user!.email,
                })}
              </div>
              <div className='flex flex-col'>
                <div className='text-lg'>{user!.name ?? ''}</div>
                <div className='text-gray-700 text-md'>{user!.email}</div>
              </div>
            </div>
            <div>
              <Menu placement='bottom-end' open={openMenu} handler={setOpenMenu}>
                <MenuHandler>
                  <button
                    className='flex flex-row bg-gray-300 p-2 border-2 border-gray-400 rounded  items-center gap-3 text-black'
                    disabled
                  >
                    Owner
                    <ChevronDownIcon
                      strokeWidth={3}
                      className={`h-3.5 w-3.5 transition-transform ${openMenu ? 'rotate-180' : ''}`}
                    />
                  </button>
                </MenuHandler>
                <MenuList>
                  <MenuItem onClick={() => {}}>
                    <div className='flex justify-left items-center gap-5 w-full h-full'>Editor</div>
                  </MenuItem>
                  <MenuItem onClick={() => {}}>
                    <div className='flex justify-left items-center gap-5 w-full h-full'>
                      Developer
                    </div>
                  </MenuItem>
                  <MenuItem onClick={() => {}}>
                    <div className='flex justify-left items-center gap-5 w-full h-full'>
                      Owner
                      <HiCheck size={20} />
                    </div>
                  </MenuItem>
                </MenuList>
              </Menu>
            </div>
          </div>
        </div>
        <div className='text-lg font-bold text-black py-5'>General Access</div>
        <div className='flex flex-row gap-4 items-center justify-between'>
          <div className='flex gap-4 items-center'>
            <div className='flex items-center justify-center h-10 w-10 rounded-full bg-gray-400 text-white cursor-pointer'>
              <HiBuildingOffice2 size={24} />
            </div>
            <div className='flex flex-col'>
              <div className='text-lg'>Anyone In Your Organization</div>
            </div>
          </div>
          <div>
            <Menu placement='bottom-end' open={openMenu2} handler={setOpenMenu2}>
              <MenuHandler>
                <button className='flex flex-row bg-white p-2 border-2 border-gray-500 rounded hover:bg-gray-200 items-center gap-3 text-black'>
                  Can Edit
                  <ChevronDownIcon
                    strokeWidth={3}
                    className={`h-3.5 w-3.5 transition-transform ${openMenu2 ? 'rotate-180' : ''}`}
                  />
                </button>
              </MenuHandler>
              <MenuList>
                <MenuItem onClick={() => {}}>
                  <div className='flex justify-left items-center gap-5 w-full h-full'>
                    Can Edit
                    <HiCheck size={20} />
                  </div>
                </MenuItem>
                <MenuItem onClick={() => {}}>
                  <div className='flex justify-left items-center gap-5 w-full h-full'>Can View</div>
                </MenuItem>
                <MenuItem onClick={() => {}}>
                  <div className='flex justify-left items-center gap-5 w-full h-full'>
                    No Access
                  </div>
                </MenuItem>
              </MenuList>
            </Menu>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareTemplate;
