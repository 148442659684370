import { useEffect } from 'react';

const useConfirmExit = () => {
  useEffect(() => {
    const handleBeforeUnload = (event: Event) => {
      event.preventDefault();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
};

export default useConfirmExit;
