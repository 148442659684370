const ErrorPage: React.FC = () => {
  return (
    <div className='flex items-center justify-center min-h-screen'>
      <div className='p-10 md:p-20 bg-white rounded-lg shadow-xl transform -translate-y-20 animate-fadeInUp'>
        <div className='flex flex-col items-center'>
          <svg
            className='w-16 h-16 mb-4 text-red-500'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth={2}
              d='M12 9v2m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
            />
          </svg>
          <h1 className='mb-4 text-4xl font-bold text-gray-800 md:text-5xl'>
            <span className='text-red-500'>Oops!</span> Something went wrong.
          </h1>
          <p className='mb-8 text-center text-gray-600 md:text-lg'>
            We&apos;re having trouble processing your request.
          </p>
          <p className='mb-8 text-center text-gray-600 md:text-lg'>
            Our team has been notified and we&apos;re working on it. Please try again later.
          </p>
          <a
            href='/'
            className='px-6 py-3 text-lg font-semibold rounded-md text-indigo-600 bg-indigo-100 hover:bg-indigo-200 transition duration-300 ease-in-out'
          >
            Go Home
          </a>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
