import { PDFObject } from '../../../types/PDFObjects';

interface ObjectSizeProps {
  pdfObject: PDFObject;
  handleSizeChange: (dimension: 'width' | 'height', value: string) => void;
}

const ObjectSize = ({ pdfObject, handleSizeChange }: ObjectSizeProps) => {
  return (
    <>
      <div className='flex items-center space-x-2 mt-5'>
        {/* X Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            W
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(pdfObject.size.width)}
            onChange={e => handleSizeChange('width', e.target.value)}
          />
        </div>

        {/* Y Position Input */}
        <div className='mt-0 flex rounded shadow-sm w-full'>
          <span className='inline-flex items-center rounded-l border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-xs'>
            H
          </span>
          <input
            type='number'
            className='block w-full min-w-0 flex-1 rounded-r border-0 py-1 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-xs sm:leading-5 rounded-none'
            value={Math.round(pdfObject.size.height)}
            onChange={e => handleSizeChange('height', e.target.value)}
          />
        </div>
      </div>
    </>
  );
};

export default ObjectSize;
