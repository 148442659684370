import { useEffect, useRef } from 'react';
import useKeyBindingsStore, { KeyBindingAction } from '../../stores/useKeyBindingsStore';
import usePDFObjectsManager from './usePDFObjectsManager';
import usePDFStore from '../../stores/usePDFStore';

const useKeyBindings = () => {
  const keyBindings = useKeyBindingsStore(state => state.keyBindings);
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const currentPDFPage = usePDFStore(state => state.currentPage);
  const { removePDFObjects, clearCurrentSelections, setCopyObjects, pasteCopiedObjects } =
    usePDFObjectsManager();
  const pdfObjectsRef = useRef(pdfObjects);

  useEffect(() => {
    pdfObjectsRef.current = pdfObjects;
  }, [pdfObjects]);

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const activeElement = document.activeElement as HTMLElement;
      const tagName = activeElement?.tagName.toUpperCase();

      if (
        tagName === 'INPUT' ||
        tagName === 'TEXTAREA' ||
        tagName === 'SELECT' ||
        activeElement?.isContentEditable
      ) {
        return;
      }
      const keyCombination = (event.ctrlKey || event.metaKey ? 'Ctrl+' : '') + event.key;

      const action = Object.keys(keyBindings).find(
        key => keyBindings[key as keyof typeof keyBindings] === keyCombination
      );

      if (action) {
        event.preventDefault();
        event.stopPropagation();

        switch (action) {
          case KeyBindingAction.DELETE:
            const selectedObjects = pdfObjectsRef.current.filter(obj => obj.isSelected);
            removePDFObjects(selectedObjects.map(obj => obj.id));
            break;
          case KeyBindingAction.ESCAPE:
            clearCurrentSelections();
            break;
          case KeyBindingAction.COPY:
            const copyObjects = pdfObjectsRef.current.filter(obj => obj.isSelected);
            setCopyObjects(copyObjects);
            break;
          case KeyBindingAction.PASTE:
            pasteCopiedObjects(currentPDFPage);
            break;

          default:
            break;
        }
      }
    };

    window.addEventListener('keydown', handleKeyPress);
    return () => window.removeEventListener('keydown', handleKeyPress);
  }, [
    keyBindings,
    removePDFObjects,
    clearCurrentSelections,
    setCopyObjects,
    pasteCopiedObjects,
    currentPDFPage,
  ]);
};

export default useKeyBindings;
