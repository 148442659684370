import React, { useEffect, useState } from 'react';
import { Card, Typography, List, ListItem, ListItemPrefix } from '@material-tailwind/react';
import {
  HiOutlineCog8Tooth,
  HiOutlineHomeModern,
  HiOutlineKey,
  HiOutlinePhoto,
  HiOutlineRectangleStack,
  HiOutlineUsers,
} from 'react-icons/hi2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { MdOutlineLogout } from 'react-icons/md';

export function SideNav() {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, clearSession } = useUserContext();
  const [userInitials, setUserInitials] = useState('');

  useEffect(() => {
    let initials = 'U'; // Default initials if user data is not available
    if (user?.name) {
      initials = user.name
        .split(' ')
        .map(name => name[0])
        .join('')
        .toUpperCase(); // Or construct from name if available
    } else if (user?.name) {
      initials = user.email[0].toUpperCase(); // Use the first letter of the email, capitalized
    }
    setUserInitials(initials);
  }, [user]);

  //handle navigate and stop propagation

  const handleNavigate = (e: React.MouseEvent, path: string) => {
    e.preventDefault();

    navigate(path);
  };
  return (
    <Card className='h-full w-full max-w-[20rem] p-4  shadow-blue-gray-900/5'>
      <div className='mb-2 flex justify-between gap-4 px-4'>
        <div className='flex flex-row items-center'>
          <img src='/logo.svg' alt='Descriptive Alt Text' className='h-10 w-10' />
          <Typography variant='h5' color='blue-gray'>
            ScribePDF
          </Typography>
        </div>
        <div className='flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 text-white'>
          {userInitials}
        </div>
      </div>
      <div className='flex flex-row p-2'></div>
      <List>
        <ListItem
          selected={location.pathname === '/dashboard'}
          onClick={e => handleNavigate(e, '/dashboard')}
        >
          <ListItemPrefix>
            <HiOutlineHomeModern className='h-7 w-7' />
          </ListItemPrefix>
          Dashboard
        </ListItem>
        <ListItem
          selected={location.pathname === '/templates'}
          onClick={e => handleNavigate(e, '/templates')}
        >
          <ListItemPrefix>
            <HiOutlineRectangleStack className='h-7 w-7' />
          </ListItemPrefix>
          Templates
        </ListItem>
        <ListItem
          selected={location.pathname === '/users'}
          onClick={e => handleNavigate(e, '/users')}
        >
          <ListItemPrefix>
            <HiOutlineUsers className='h-7 w-7' />
          </ListItemPrefix>
          Users
        </ListItem>
        <ListItem
          selected={location.pathname === '/uploads'}
          onClick={e => handleNavigate(e, '/uploads')}
        >
          <ListItemPrefix>
            <HiOutlinePhoto className='h-7 w-7' />
          </ListItemPrefix>
          Uploads
        </ListItem>
        <ListItem
          selected={location.pathname === '/api-keys'}
          onClick={e => handleNavigate(e, '/api-keys')}
        >
          <ListItemPrefix>
            <HiOutlineKey className='h-7 w-7' />
          </ListItemPrefix>
          API Keys
        </ListItem>
        <ListItem
          selected={location.pathname === '/settings'}
          onClick={e => handleNavigate(e, '/settings')}
        >
          <ListItemPrefix>
            <HiOutlineCog8Tooth className='h-7 w-7' />
          </ListItemPrefix>
          Settings
        </ListItem>
        <ListItem onClick={clearSession}>
          <ListItemPrefix>
            <MdOutlineLogout className='h-7 w-7' />
          </ListItemPrefix>
          Logout
        </ListItem>
      </List>
    </Card>
  );
}
