import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../helpers/useApiCall';

export const useDeleteTemplate = () => {
  const { makeCall, response, isLoading, error } = useApiCall<boolean>();
  const { templateService } = useAPIContext();

  const deleteTemplate = async (templateId: string, jwt: string) => {
    await makeCall(signal => {
      return templateService.delete(templateId, jwt, signal);
    });
  };

  return { deleteTemplate, response, isLoading, error };
};
