import { UserDTO } from '../../../api/user/types';
import { useAPIContext } from '../../../contexts/hooks/useAPIContext';
import { useApiCall } from '../../../hooks/api/helpers/useApiCall';

export const useCreateUser = () => {
  const { makeCall, response, isLoading, error } = useApiCall<UserDTO>();
  const { userService } = useAPIContext();

  const createUser = async (email: string, name: string, roleName: string, token: string) => {
    await makeCall(signal => {
      return userService.createUser(email, name, roleName, token, signal);
    });
  };

  return { createUser, response, isLoading, error };
};
