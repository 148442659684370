const resumeTemplate = {
  pageSize: {
    name: 'A4',
    width: 794,
    height: 1123,
  },
  pageOrientation: 'Portrait',
  textBoxes: [
    {
      type: 'TextBox',
      displayName: 'First Name',
      fontColor: '#545454',
      id: '24e39b48-2092-48d7-9b75-ef83b8bbc3c7',
      content: 'Alex',
      position: {
        x: 295.7142857142858,
        y: 30,
      },
      size: {
        width: 154.28571428571433,
        height: 32.85714285714283,
      },
      fontFamily: 'JosefinSans',
      fontSize: 36,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138337943,
      localLastUpdated: 1715138333713,
      acknowledged: true,
      acknowledgementId: 'ebfd88fd-fab7-47ec-b116-3a124273209f',
    },
    {
      type: 'TextBox',
      displayName: 'Last Name',
      fontColor: '#6b6b6b',
      id: '4777f8b7-f245-441e-b0ff-42c3b888ccae',
      content: 'Johnson',
      position: {
        x: 375.7142857142858,
        y: 30,
      },
      size: {
        width: 154.28571428571433,
        height: 32.85714285714283,
      },
      fontFamily: 'JosefinSans',
      fontSize: 36,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138337943,
      localLastUpdated: 1715138337908,
      acknowledged: true,
      acknowledgementId: 'c7d3ad01-1785-495d-a41a-9ae3486815ed',
    },
    {
      type: 'TextBox',
      displayName: 'Role Title',
      fontColor: '#8c8c8c',
      id: 'b2b58163-dc8b-4b4a-9c97-fc3e4b3ab3b3',
      content: 'Senior Web Developer',
      position: {
        x: 300,
        y: 70,
      },
      size: {
        width: 254.28571428571433,
        height: 24.285714285714278,
      },
      fontFamily: 'JosefinSans',
      fontSize: 24,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138357492,
      localLastUpdated: 1715138357445,
      acknowledged: true,
      acknowledgementId: '2544c0b0-29cb-48f4-bb5e-83ce9e1c2410',
    },
    {
      type: 'TextBox',
      displayName: 'Profile Label',
      fontColor: '#545454',
      id: 'ec03fe80-54b0-4285-8904-e7a56ac885e9',
      content: 'Profile',
      position: {
        x: 280,
        y: 120,
      },
      size: {
        width: 74.28571428571433,
        height: 21.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 21,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138362134,
      localLastUpdated: 1715138362101,
      acknowledged: true,
      acknowledgementId: '9b70499f-c8f4-4585-affd-bf8a01423a20',
    },
    {
      type: 'TextBox',
      displayName: 'Profile Description',
      fontColor: '#6e6e6e',
      id: 'd42c200e-ec3b-4fba-a1b8-96954ec4002f',
      content:
        'Dynamic and detail-oriented Web Developer with a focus on front-end technologies and a track record of creating and maintaining interactive websites. Skilled in HTML, CSS, JavaScript, and React. Passionate about building responsive, user-centric web applications and continually striving to learn new technologies. Looking for an opportunity to bring technical skills and creative solutions to a dynamic development team.',
      position: {
        x: 280,
        y: 160,
      },
      size: {
        width: 485.7142857142859,
        height: 149.99999999999994,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138379729,
      localLastUpdated: 1715138379699,
      acknowledged: true,
      acknowledgementId: 'e4deb765-8bfa-4161-8355-b08963216e4a',
    },
    {
      type: 'TextBox',
      displayName: 'Work History Label',
      fontColor: '#545454',
      id: 'e3ece5a2-11e0-458a-8358-42331bbca5ac',
      content: 'Work History',
      position: {
        x: 275.7142857142858,
        y: 310,
      },
      size: {
        width: 138.57142857142867,
        height: 35.71428571428561,
      },
      fontFamily: 'JosefinSans',
      fontSize: 21,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138387280,
      localLastUpdated: 1715138387241,
      acknowledged: true,
      acknowledgementId: '3e982091-15ff-40a6-a56b-9d18495b9630',
    },
    {
      type: 'TextBox',
      displayName: 'Job Title #1',
      fontColor: '#545454',
      id: '70b95283-44e0-4712-8321-657ac68c9b00',
      content: 'Senior Web Developer',
      position: {
        x: 300,
        y: 370,
      },
      size: {
        width: 230,
        height: 21.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 19,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138396973,
      localLastUpdated: 1715138396945,
      acknowledged: true,
      acknowledgementId: '89d3204f-8182-4132-b5bd-db586e74cfa6',
    },
    {
      type: 'TextBox',
      displayName: 'Job Location #1',
      fontColor: '#6e6e6e',
      id: 'aa035539-f2ba-45d3-933f-7b3939fc5ce0',
      content: 'ABC Company, Los Angeles, CA',
      position: {
        x: 300,
        y: 390,
      },
      size: {
        width: 237.14285714285745,
        height: 15.714285714285722,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138402755,
      localLastUpdated: 1715138402714,
      acknowledged: true,
      acknowledgementId: '8aae9846-f753-4391-a7dd-715bde4d666a',
    },
    {
      type: 'TextBox',
      displayName: 'Job Duties #1',
      fontColor: '#595959',
      id: 'b5bd0df3-f6f9-440f-979d-0d6a8b31fce8',
      content:
        '- Developed and maintained high-traffic web applications using JavaScript, React, and Node.js, leading to a 30% increase in user engagement.\n- Collaborated with designers and back-end developers to create seamless user experiences.\n- Implemented responsive web design principles to ensure that websites functioned correctly on all devices.',
      position: {
        x: 290,
        y: 420,
      },
      size: {
        width: 485.7142857142859,
        height: 149.99999999999994,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138407562,
      localLastUpdated: 1715138407519,
      acknowledged: true,
      acknowledgementId: '14f49104-3afd-4dbb-8d1d-a498cdf67772',
    },
    {
      type: 'TextBox',
      displayName: 'Job Title #2',
      fontColor: '#545454',
      id: '8943c032-93d6-467e-a27e-cabf462706cf',
      content: 'Junior Web Developer',
      position: {
        x: 300,
        y: 580,
      },
      size: {
        width: 230,
        height: 21.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 19,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138412890,
      localLastUpdated: 1715138412852,
      acknowledged: true,
      acknowledgementId: '56ba4618-19e4-4180-b4b1-c04dea2741e1',
    },
    {
      type: 'TextBox',
      displayName: 'Job Location #2',
      fontColor: '#6e6e6e',
      id: 'd45be788-caab-40b9-a666-b24848f16e12',
      content: 'ABC Company, Los Angeles, CA',
      position: {
        x: 300,
        y: 600,
      },
      size: {
        width: 237.14285714285745,
        height: 15.714285714285722,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138426342,
      localLastUpdated: 1715138426306,
      acknowledged: true,
      acknowledgementId: 'f27fb279-0d84-4423-9d6d-09bafa1e9c0d',
    },
    {
      type: 'TextBox',
      displayName: 'Job Duties #2',
      fontColor: '#595959',
      id: '3dbcc2dd-df11-4ce7-af6d-ba3e84838b85',
      content:
        '- Assisted in the development of client websites, focusing on cross-browser compatibility and accessibility.\n- Participated in rigorous testing and debugging processes to enhance site functionality and speed.\n- Supported senior developers with project documentation and code reviews.',
      position: {
        x: 290,
        y: 630,
      },
      size: {
        width: 485.7142857142859,
        height: 149.99999999999994,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138431130,
      localLastUpdated: 1715138431086,
      acknowledged: true,
      acknowledgementId: '6bb96aef-6506-4615-a894-720aee8e166b',
    },
    {
      type: 'TextBox',
      displayName: 'Job Dates #1',
      fontColor: '#545454',
      id: '4b68858d-590a-493e-a0e5-23d3b38bed00',
      content: 'May 2020 - Present',
      position: {
        x: 610,
        y: 370,
      },
      size: {
        width: 156.25,
        height: 25.178571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138436467,
      localLastUpdated: 1715138436440,
      acknowledged: true,
      acknowledgementId: '9ce7128d-134c-4ca6-98c1-76fb547b7178',
    },
    {
      type: 'TextBox',
      displayName: 'Job Dates #2',
      fontColor: '#545454',
      id: 'db5f0498-597b-4659-bb39-afe6f332abd2',
      content: 'June 2015 - May 2020',
      position: {
        x: 610,
        y: 580,
      },
      size: {
        width: 165.25,
        height: 21.178571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138440967,
      localLastUpdated: 1715138440933,
      acknowledged: true,
      acknowledgementId: 'd27afbc2-6e08-46ed-b600-d52541dbeeac',
    },
    {
      type: 'TextBox',
      displayName: 'Job Title #3',
      fontColor: '#545454',
      id: 'ec85e421-0b69-40a9-ad0a-aead30315f31',
      content: 'Software Engineer Intern',
      position: {
        x: 300,
        y: 770,
      },
      size: {
        width: 230,
        height: 21.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 19,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138446260,
      localLastUpdated: 1715138446232,
      acknowledged: true,
      acknowledgementId: 'e7d3a76a-0096-42e3-9bd6-2dfe78ca2a18',
    },
    {
      type: 'TextBox',
      displayName: 'Job Location #3',
      fontColor: '#6e6e6e',
      id: '5eb6c934-6c6d-462d-89cb-b36154c80f0c',
      content: 'ABC Company, Los Angeles, CA',
      position: {
        x: 300,
        y: 790,
      },
      size: {
        width: 237.14285714285745,
        height: 15.714285714285722,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138451641,
      localLastUpdated: 1715138451612,
      acknowledged: true,
      acknowledgementId: '262c66f2-1c67-4483-b075-5595d2132704',
    },
    {
      type: 'TextBox',
      displayName: 'Job Duties #3',
      fontColor: '#595959',
      id: 'd29cb9c5-3e38-4d26-865d-81e3916b0ef3',
      content:
        '- Assisted in the development of client websites, focusing on cross-browser compatibility and accessibility.\n- Participated in rigorous testing and debugging processes to enhance site functionality and speed.\n- Supported senior developers with project documentation and code reviews.',
      position: {
        x: 290,
        y: 820,
      },
      size: {
        width: 485.7142857142859,
        height: 149.99999999999994,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 1,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138456214,
      localLastUpdated: 1715138456173,
      acknowledged: true,
      acknowledgementId: '650aeb2b-328d-47c9-a568-1be2126c9482',
    },
    {
      type: 'TextBox',
      displayName: 'Job Dates #3',
      fontColor: '#545454',
      id: '4e90f80a-2587-4ac8-98be-dc4c57ea667d',
      content: 'July 2014 - Sept 2014',
      position: {
        x: 610,
        y: 770,
      },
      size: {
        width: 165.25,
        height: 21.178571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 16,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 6,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138484510,
      localLastUpdated: 1715138484483,
      acknowledged: true,
      acknowledgementId: '5667d116-f2a9-4983-b0a8-e8898d43eeba',
    },
    {
      type: 'TextBox',
      displayName: 'Phone Number',
      fontColor: '#fffafa',
      id: 'dcf15a35-3ede-4863-8557-133accc0f706',
      content: '(100) 123-4567',
      position: {
        x: 50,
        y: 90,
      },
      size: {
        width: 140,
        height: 18.75,
      },
      fontFamily: 'JosefinSans',
      fontSize: 18,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 25,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138517267,
      localLastUpdated: 1715138517237,
      acknowledged: true,
      acknowledgementId: '0c205a9b-1d6a-4600-af4b-864ed431a3e3',
    },
    {
      type: 'TextBox',
      displayName: 'Email',
      fontColor: '#fffafa',
      id: '7f7a0265-041a-4b13-8a42-24a534840a79',
      content: 'support@scribepdf.com',
      position: {
        x: 50,
        y: 140,
      },
      size: {
        width: 193.75,
        height: 32.5,
      },
      fontFamily: 'JosefinSans',
      fontSize: 18,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 25,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138520697,
      localLastUpdated: 1715138520668,
      acknowledged: true,
      acknowledgementId: '3534653b-1359-464b-889e-c3c20aed0976',
    },
    {
      type: 'TextBox',
      displayName: 'Location',
      fontColor: '#fffafa',
      id: '4d6edf81-2b6c-4a79-8113-d55e7c459a52',
      content: 'Los Angeles, CA',
      position: {
        x: 50,
        y: 200,
      },
      size: {
        width: 193.75,
        height: 32.5,
      },
      fontFamily: 'JosefinSans',
      fontSize: 18,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 25,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138524560,
      localLastUpdated: 1715138524533,
      acknowledged: true,
      acknowledgementId: 'd84b3bba-f700-4f4c-b0b5-379d2ae9ed2a',
    },
    {
      type: 'TextBox',
      displayName: 'Contact Label',
      fontColor: '#ffffff',
      id: '486b484d-277f-4117-b3d5-913a91c285da',
      content: 'Contact',
      position: {
        x: 20,
        y: 40,
      },
      size: {
        width: 105.53571428571433,
        height: 16.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 23,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 23,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138503716,
      localLastUpdated: 1715138503676,
      acknowledged: true,
      acknowledgementId: '20ef76f3-a81b-43de-9606-7b8406adeb9b',
    },
    {
      type: 'TextBox',
      displayName: 'Skills Label',
      fontColor: '#ffffff',
      id: '4597d666-61b1-433e-acc4-c415fce38ca3',
      content: 'Skills',
      position: {
        x: 20,
        y: 280,
      },
      size: {
        width: 105.53571428571433,
        height: 16.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 23,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 7,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138487778,
      localLastUpdated: 1715138487738,
      acknowledged: true,
      acknowledgementId: '61629b49-1fc7-4121-b229-82ca6690cfcc',
    },
    {
      type: 'TextBox',
      displayName: 'Skills',
      fontColor: '#fffafa',
      id: 'b969f264-c614-4588-a4ba-c36f3f2dc27d',
      content: '- HTML\n- CSS\n- JavaScript\n- Node\n- Git\n- Docker\n- React\n- TypeScript',
      position: {
        x: 20,
        y: 320,
      },
      size: {
        width: 136.25,
        height: 183.75,
      },
      fontFamily: 'JosefinSans',
      fontSize: 18,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 25,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138531668,
      localLastUpdated: 1715138531613,
      acknowledged: true,
      acknowledgementId: 'dfa43382-aefc-49be-bd61-2bde41a744a8',
    },
    {
      type: 'TextBox',
      displayName: 'Certifications Label',
      fontColor: '#ffffff',
      id: 'dfec5490-cf16-45b5-b4d9-3199ec68be16',
      content: 'Certifications',
      position: {
        x: 20,
        y: 540,
      },
      size: {
        width: 151.78571428571433,
        height: 26.428571428571388,
      },
      fontFamily: 'JosefinSans',
      fontSize: 23,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 22,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138498937,
      localLastUpdated: 1715138498890,
      acknowledged: true,
      acknowledgementId: '82c403d4-4c5c-49fb-a35a-aad73f8314e2',
    },
    {
      type: 'TextBox',
      displayName: 'Certifications',
      fontColor: '#fffafa',
      id: '1a8aacd2-ff95-444d-9f86-2c299a713c74',
      content: '- HTML\n- CSS\n- JavaScript\n- Node\n- Git\n- Docker\n- React\n- TypeScript',
      position: {
        x: 20,
        y: 580,
      },
      size: {
        width: 136.25,
        height: 183.75,
      },
      fontFamily: 'JosefinSans',
      fontSize: 18,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 25,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138534681,
      localLastUpdated: 1715138534642,
      acknowledged: true,
      acknowledgementId: 'df60f957-70bc-4db1-a3f8-be5e4cca1742',
    },
  ],
  tables: [],
  svgs: [],
  lineSVGs: [
    {
      type: 'LineSVG',
      displayName: 'Divider',
      caps: 'NONE',
      id: '7ff3d609-a7ad-40c1-9708-41073e8d8447',
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 1,
      lastUpdated: 1715138459552,
      localLastUpdated: 1715138459516,
      isSelected: false,
      line: {
        x1: 771.4285714285713,
        y1: 148.57142857142856,
        x2: 270,
        y2: 148.57142857142844,
      },
      stroke: {
        color: '#787878',
        width: 2,
      },
      rotation: 0,
      page: 1,
      acknowledged: true,
      acknowledgementId: '6b693d87-4487-4ad6-b4d0-b51b183561f0',
    },
    {
      type: 'LineSVG',
      displayName: 'Divider',
      caps: 'NONE',
      id: '18eb6a87-ac67-4fa4-9340-789284b39e96',
      position: {
        x: 10,
        y: 10,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 1,
      lastUpdated: 1715138462179,
      localLastUpdated: 1715138462157,
      isSelected: false,
      line: {
        x1: 767.1428571428571,
        y1: 337.1428571428572,
        x2: 270,
        y2: 338.57142857142844,
      },
      stroke: {
        color: '#787878',
        width: 2,
      },
      rotation: 0,
      page: 1,
      acknowledged: true,
      acknowledgementId: '5650f34d-a9b3-4add-b941-ec3d7e04cf1c',
    },
    {
      type: 'LineSVG',
      displayName: 'Timeline Line',
      caps: 'CIRCLE_OUTLINE',
      id: 'adda51cc-ddd4-4cbd-bd0d-ca7f2bab9de0',
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 5,
      lastUpdated: 1715138476507,
      localLastUpdated: 1715138476478,
      isSelected: false,
      line: {
        x1: 279.04761904761904,
        y1: 376.1904761904762,
        x2: 278.57142857142867,
        y2: 580.0000000000001,
      },
      stroke: {
        color: '#b1afaf',
        width: 4,
      },
      rotation: 0,
      page: 1,
      acknowledged: true,
      acknowledgementId: 'ea3b8ae8-2ae5-47a5-a9c0-b651c55158bb',
    },
    {
      type: 'LineSVG',
      displayName: 'Timeline Line',
      caps: 'CIRCLE_OUTLINE',
      id: 'b8d437f1-ce67-4b35-bed1-6919e2791054',
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 21,
      lastUpdated: 1715138491746,
      localLastUpdated: 1715138491718,
      isSelected: false,
      line: {
        x1: 278.57142857142856,
        y1: 590,
        x2: 279.95238095238096,
        y2: 780.4285714285714,
      },
      stroke: {
        color: '#b1afaf',
        width: 4,
      },
      rotation: 0,
      page: 1,
      acknowledged: true,
      acknowledgementId: '0ea39acc-500d-4b8a-af59-1aee579be825',
    },
  ],
  rectSVGs: [
    {
      type: 'RectSVG',
      displayName: 'Side Rectangle',
      id: '8b9e64b4-0555-4bdc-842c-1a4754d231be',
      position: {
        x: -30,
        y: -100,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 1,
      isSelected: false,
      rect: {
        x: 2,
        y: 0,
        width: 290.0000000000001,
        height: 1337.1428571428573,
      },
      fill: '#787878',
      stroke: {
        color: '#000000',
        width: 1,
      },
      rotation: 0,
      page: 1,
      lastUpdated: 1715138469039,
      localLastUpdated: 1715138469009,
      acknowledged: true,
      acknowledgementId: '38a28b4a-2291-4910-b103-548eb644a042',
    },
  ],
  iconSVGs: [
    {
      type: 'IconSVG',
      displayName: 'Phone Icon',
      id: '8ae550c8-1025-45ac-a9ac-9d7c6b01b0b5',
      content:
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g id="Communication / Phone"> <path id="Vector" d="M9.50246 4.25722C9.19873 3.4979 8.46332 3 7.64551 3H4.89474C3.8483 3 3 3.8481 3 4.89453C3 13.7892 10.2108 21 19.1055 21C20.1519 21 21 20.1516 21 19.1052L21.0005 16.354C21.0005 15.5361 20.5027 14.8009 19.7434 14.4971L17.1069 13.4429C16.4249 13.1701 15.6483 13.2929 15.0839 13.7632L14.4035 14.3307C13.6089 14.9929 12.4396 14.9402 11.7082 14.2088L9.79222 12.2911C9.06079 11.5596 9.00673 10.3913 9.66895 9.59668L10.2363 8.9163C10.7066 8.35195 10.8305 7.57516 10.5577 6.89309L9.50246 4.25722Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g> </g></svg>',
      position: {
        x: 10,
        y: 80,
      },
      size: {
        width: 30.595238095238102,
        height: 30.595238095238102,
      },
      aspectRatio: 1,
      zIndex: 24,
      isSelected: false,
      svgIcon: {
        svgIconId: 'phone',
        name: 'a00f8a98-c8bc-4bcd-861f-d9523870b21b',
      },
      color: '#ffffff',
      page: 1,
      lastUpdated: 1715138509161,
      localLastUpdated: 1715138509133,
      acknowledged: true,
      acknowledgementId: '561fd5b8-36ca-45d1-ab16-49156ad5d5d6',
    },
    {
      type: 'IconSVG',
      displayName: 'Email Icon',
      id: '0a08a5b2-0a29-4637-b520-3d25deefa0fd',
      content:
        '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M4 9.00005L10.2 13.65C11.2667 14.45 12.7333 14.45 13.8 13.65L20 9" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M3 9.17681C3 8.45047 3.39378 7.78123 4.02871 7.42849L11.0287 3.5396C11.6328 3.20402 12.3672 3.20402 12.9713 3.5396L19.9713 7.42849C20.6062 7.78123 21 8.45047 21 9.17681V17C21 18.1046 20.1046 19 19 19H5C3.89543 19 3 18.1046 3 17V9.17681Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round"></path> </g></svg>',
      position: {
        x: 10,
        y: 130,
      },
      size: {
        width: 32.5,
        height: 32.5,
      },
      aspectRatio: 1,
      zIndex: 27,
      isSelected: false,
      svgIcon: {
        svgIconId: 'email',
        name: '71dd2b36-802b-4593-811e-3dce666964ea',
      },
      color: '#ffffff',
      page: 1,
      lastUpdated: 1715138539959,
      localLastUpdated: 1715138539919,
      acknowledged: true,
      acknowledgementId: 'c76694eb-f4af-46d9-9ff0-1a417ac216d3',
    },
    {
      type: 'IconSVG',
      displayName: 'Location Icon',
      id: 'e2c55a67-e29a-4cf6-8d9f-85e6646aea62',
      content:
        '<svg viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>',
      position: {
        x: 0,
        y: 180,
      },
      size: {
        width: 50,
        height: 50,
      },
      aspectRatio: 1,
      zIndex: 29,
      isSelected: false,
      svgIcon: {
        svgIconId: 'location-pin',
        name: '691f4780-5f50-4f62-9523-53726daebd32',
      },
      color: '#ffffff',
      page: 1,
      lastUpdated: 1715138544825,
      localLastUpdated: 1715138544787,
      acknowledged: true,
      acknowledgementId: '8141f9d9-5cc8-4721-aa7f-349461e25a99',
    },
  ],
  qrCodes: [],
  images: [],
};

const ticketTemplate = {
  pageSize: {
    name: 'A4',
    width: 794,
    height: 1123,
  },
  pageOrientation: 'Portrait',
  textBoxes: [
    {
      type: 'TextBox',
      displayName: 'Event Title',
      fontColor: '#000000',
      id: 'd9f4c9c5-201c-4f55-bdf0-3f0b195b58fe',
      content: 'Beta Invitation',
      position: {
        x: 30,
        y: 240,
      },
      size: {
        width: 191.42857142857133,
        height: 31.428571428571445,
      },
      fontFamily: 'Lato',
      fontSize: 24,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715137267628,
      localLastUpdated: 1715137267609,
      acknowledged: true,
      acknowledgementId: '3505bf1a-4fa8-448d-bd22-dc0bcb538f29',
    },
    {
      type: 'TextBox',
      displayName: 'Reference Label',
      fontColor: '#000000',
      id: '2e024ecd-7d72-497b-ba65-93c671004771',
      content: 'Reference Numer',
      position: {
        x: 590,
        y: 560,
      },
      size: {
        width: 111.42857142857144,
        height: 22.85714285714289,
      },
      fontFamily: 'Lato',
      fontSize: 12,
      fontWeight: 'bold',
      align: 'center',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 5,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715137559284,
      localLastUpdated: 1715137559264,
      acknowledged: true,
      acknowledgementId: 'f8f2d29e-55f0-4cff-badb-ac1a3677454c',
    },
    {
      type: 'TextBox',
      displayName: 'Ticket Reference #',
      fontColor: '#000000',
      id: '20f90271-6ca6-40ff-9724-d8bbe0de3617',
      content: '12345678910',
      position: {
        x: 590,
        y: 580,
      },
      size: {
        width: 111.42857142857144,
        height: 22.85714285714289,
      },
      fontFamily: 'Lato',
      fontSize: 12,
      fontWeight: 'normal',
      align: 'center',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: 'referenceNumber',
      zIndex: 5,
      isDynamic: true,
      isSelected: false,
      page: 1,
      lastUpdated: 1715137560931,
      localLastUpdated: 1715137560912,
      acknowledged: true,
      acknowledgementId: 'a06b2109-218c-40ed-bbde-40a0dd7da0a2',
    },
    {
      type: 'TextBox',
      displayName: 'Who Label',
      fontColor: '#000000',
      id: '4dc58ffe-b894-456a-8d13-602a91223e4d',
      content: 'Who',
      position: {
        x: 30,
        y: 320,
      },
      size: {
        width: 49.99999999999994,
        height: 28.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138164639,
      localLastUpdated: 1715138160059,
      acknowledged: true,
      acknowledgementId: '48e68f36-4146-4a28-9730-6f26402fe559',
    },
    {
      type: 'TextBox',
      displayName: 'When Label',
      fontColor: '#000000',
      id: 'e0de8959-b44c-44d4-b811-2407a94dbb17',
      content: 'When',
      position: {
        x: 30,
        y: 420,
      },
      size: {
        width: 49.99999999999994,
        height: 28.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138164639,
      localLastUpdated: 1715138164601,
      acknowledged: true,
      acknowledgementId: '6cb638b0-6287-4aba-b462-951a315bfc94',
    },
    {
      type: 'TextBox',
      displayName: 'Where Label',
      fontColor: '#000000',
      id: '261bfda4-fd79-430b-849c-1a187bddfd36',
      content: 'Where',
      position: {
        x: 30,
        y: 530,
      },
      size: {
        width: 67.14285714285711,
        height: 24.285714285714334,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138169049,
      localLastUpdated: 1715138169007,
      acknowledged: true,
      acknowledgementId: '89b9d34c-82ea-42b4-b888-a7846736268d',
    },
    {
      type: 'TextBox',
      displayName: 'Company Name',
      fontColor: '#000000',
      id: '9a12c44a-e91a-49b4-9085-5894ca73b413',
      content: 'ScribePDF',
      position: {
        x: 244.8571428571429,
        y: 30,
      },
      size: {
        width: 304.2857142857142,
        height: 62.85714285714283,
      },
      fontFamily: 'Lato',
      fontSize: 33,
      fontWeight: 'bold',
      align: 'center',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138180907,
      localLastUpdated: 1715138180865,
      acknowledged: true,
      acknowledgementId: '76171a25-7416-45d3-b07a-4fdb3e324f56',
    },
    {
      type: 'TextBox',
      displayName: 'Customer Name',
      fontColor: '#000000',
      id: '11843fc4-a47f-43f6-afb5-0124de5ab304',
      content: 'John Doe',
      position: {
        x: 90,
        y: 350,
      },
      size: {
        width: 237.14285714285705,
        height: 48.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: 'customerName',
      zIndex: 3,
      isDynamic: true,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138185933,
      localLastUpdated: 1715138185899,
      acknowledged: true,
      acknowledgementId: '13cfea50-67ff-4ca7-b6b6-792b54fbd669',
    },
    {
      type: 'TextBox',
      displayName: 'Event Date',
      fontColor: '#000000',
      id: '9f818d55-b35a-431a-9a3d-2fad451d6576',
      content: 'May 30, 2024 - TBD',
      position: {
        x: 90,
        y: 460,
      },
      size: {
        width: 291.4285714285714,
        height: 51.428571428571445,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: 'eventDate',
      zIndex: 3,
      isDynamic: true,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138190408,
      localLastUpdated: 1715138190384,
      acknowledged: true,
      acknowledgementId: 'bc91e592-e833-4b46-bba2-f408c4ebe4c9',
    },
    {
      type: 'TextBox',
      displayName: 'Event Location',
      fontColor: '#000000',
      id: '11466d80-0ff6-48d0-afbc-9c5dfcc19746',
      content: '1234 Los Angeles St, Los Angeles, CA 90299',
      position: {
        x: 90,
        y: 570,
      },
      size: {
        width: 348.5714285714286,
        height: 38.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: 'eventLocation',
      zIndex: 3,
      isDynamic: true,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138196860,
      localLastUpdated: 1715138196819,
      acknowledged: true,
      acknowledgementId: '88b4eef7-3147-4495-a334-56cb94dc1d94',
    },
    {
      type: 'TextBox',
      displayName: 'Description Label',
      fontColor: '#000000',
      id: 'ce197947-1ffe-4191-8692-04c5902ec359',
      content: 'Description',
      position: {
        x: 30,
        y: 630,
      },
      size: {
        width: 98.57142857142856,
        height: 25.71428571428578,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'bold',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138201924,
      localLastUpdated: 1715138201894,
      acknowledged: true,
      acknowledgementId: '84bbaa1f-2b86-47b7-b9d7-ac52ca87d29a',
    },
    {
      type: 'TextBox',
      displayName: 'Event Description',
      fontColor: '#000000',
      id: '3aed4fe3-b257-4b00-90c0-d93529b45b9b',
      content:
        'You have been invited to the ScribePDF Beta! During the beta all of our services will be completely free. We appreciate you trying out our service and providing any feedback possible. Thank you for your time and we hope to create a product that takes away the issues with maintaining dynamic pdfs.',
      position: {
        x: 30,
        y: 670,
      },
      size: {
        width: 395.71428571428584,
        height: 204.28571428571422,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'left',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138211175,
      localLastUpdated: 1715138211132,
      acknowledged: true,
      acknowledgementId: 'f049ae9a-3278-42bd-9a4f-f0e19bc71ee5',
    },
    {
      type: 'TextBox',
      displayName: 'Have Questions?',
      fontColor: '#000000',
      id: '282d1516-4aa3-4c47-807c-516ae2f5c6a7',
      content: 'Have Questions?',
      position: {
        x: 222.7142857142857,
        y: 1000,
      },
      size: {
        width: 348.5714285714286,
        height: 38.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'center',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138219554,
      localLastUpdated: 1715138219521,
      acknowledged: true,
      acknowledgementId: 'd7e2b70a-b6dd-4075-9a8b-f06d3cab7153',
    },
    {
      type: 'TextBox',
      displayName: 'Support Number',
      fontColor: '#000000',
      id: 'b7492d99-b29e-4ef5-8048-d01ffbc87b08',
      content: '(123) 123 456 78',
      position: {
        x: 222.7142857142857,
        y: 1040,
      },
      size: {
        width: 348.5714285714286,
        height: 38.571428571428555,
      },
      fontFamily: 'Lato',
      fontSize: 17,
      fontWeight: 'normal',
      align: 'center',
      fontStyle: 'normal',
      underline: false,
      lineSpacing: 1.2,
      jsonKey: '',
      zIndex: 3,
      isDynamic: false,
      isSelected: false,
      page: 1,
      lastUpdated: 1715138229904,
      localLastUpdated: 1715138229866,
      acknowledged: true,
      acknowledgementId: '6148fd12-7693-4281-a54d-08cbf9f435bf',
    },
  ],
  tables: [],
  svgs: [],
  lineSVGs: [
    {
      type: 'LineSVG',
      displayName: 'Divider',
      caps: 'NONE',
      id: 'c7c47eae-9824-4e20-a033-d507f51f8ed0',
      position: {
        x: 0,
        y: 0,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 4,
      lastUpdated: 1715137166159,
      localLastUpdated: 1715137166133,
      isSelected: false,
      line: {
        x1: 480,
        y1: 200,
        x2: 480,
        y2: 920.0000000000001,
      },
      stroke: {
        color: '#8c8c8c',
        width: 1,
      },
      rotation: 0,
      page: 1,
      acknowledged: true,
      acknowledgementId: '501e467f-beb1-4f4a-bb6b-4a3a501eeba4',
    },
  ],
  rectSVGs: [
    {
      type: 'RectSVG',
      displayName: 'Background Rectangle',
      id: '43b75e0f-c336-4cd7-b2f1-f3a8fec86b5e',
      position: {
        x: -90,
        y: 180,
      },
      size: {
        width: 900,
        height: 900,
      },
      zIndex: 1,
      isSelected: true,
      rect: {
        x: 2,
        y: 0,
        width: 1068.5714285714287,
        height: 750,
      },
      fill: '#ebecf4',
      stroke: {
        color: '#000000',
        width: 1,
      },
      rotation: 0,
      page: 1,
      lastUpdated: 1715138248108,
      localLastUpdated: 1715138248070,
      acknowledged: true,
      acknowledgementId: '6b474d43-10fa-4dda-be9c-79c520301b5a',
    },
  ],
  iconSVGs: [
    {
      type: 'IconSVG',
      displayName: 'Location Icon',
      id: '560c83c6-e37e-4208-a351-2ce910213b5a',
      content:
        '<svg viewBox="-2.4 -2.4 28.80 28.80" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M12 21C15.5 17.4 19 14.1764 19 10.2C19 6.22355 15.866 3 12 3C8.13401 3 5 6.22355 5 10.2C5 14.1764 8.5 17.4 12 21Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> <path d="M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z" stroke="$$$$$" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path> </g></svg>',
      position: {
        x: 30,
        y: 550,
      },
      size: {
        width: 51.428571428571445,
        height: 51.428571428571445,
      },
      aspectRatio: 1,
      zIndex: 16,
      isSelected: false,
      svgIcon: {
        svgIconId: 'location-pin',
        name: '691f4780-5f50-4f62-9523-53726daebd32',
      },
      color: '#000000',
      page: 1,
      lastUpdated: 1715138237152,
      localLastUpdated: 1715138237119,
      acknowledged: true,
      acknowledgementId: '559508f1-840b-4a4f-96d0-46b8dbfcbe2b',
    },
    {
      type: 'IconSVG',
      displayName: 'Phone Icon',
      id: '3324b46d-1188-45aa-b7d6-1d19b69ba556',
      content:
        '<svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="none"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"><path stroke="$$$$$" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.5 4.842C15.976 4.337 14.146 4 12 4c-2.145 0-3.976.337-5.5.842m11 0c3.021 1 4.835 2.66 5.5 3.658L20.5 11l-3-2V4.842zm-11 0c-3.021 1-4.835 2.66-5.5 3.658L3.5 11l3-2V4.842zM10 7v3m0 0-5.414 5.414A2 2 0 0 0 4 16.828V18a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-1.172a2 2 0 0 0-.586-1.414L14 10m-4 0h4m0 0V7"></path><circle cx="12" cy="15" r="2" stroke="$$$$$" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></circle></g></svg>',
      position: {
        x: 260,
        y: 1020,
      },
      size: {
        width: 58.571428571428555,
        height: 58.571428571428555,
      },
      aspectRatio: 1,
      zIndex: 17,
      isSelected: false,
      svgIcon: {
        svgIconId: 'phone',
        name: '2e621567-6dd5-4c7f-9447-3d6db85c6abb',
      },
      color: '#000000',
      page: 1,
      lastUpdated: 1715138241092,
      localLastUpdated: 1715138241063,
      acknowledged: true,
      acknowledgementId: '18a4ea88-a9f0-4c07-8e0e-401d24fbb99a',
    },
  ],
  qrCodes: [
    {
      type: 'QRCode',
      displayName: 'QRCode',
      value: 'https://scribepdf.com',
      jsonKey: 'qrCode',
      isDynamic: true,
      content:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" \n  shape-rendering="crispEdges"><path stroke="#000000" d="M4 4.5h7m2 0h1m3 0h1m4 \n  0h7M4 5.5h1m5 0h1m2 0h1m1 0h5m2 0h1m5 0h1M4 6.5h1m1 0h3m1 0h1m1 0h4m4 0h1m1 0h1m1 0h3m1\n   0h1M4 7.5h1m1 0h3m1 0h1m1 0h3m1 0h4m2 0h1m1 0h3m1 0h1M4 8.5h1m1 0h3m1 0h1m1 0h3m5 0h1m1 \n   0h1m1 0h3m1 0h1M4 9.5h1m5 0h1m1 0h1m1 0h1m1 0h2m1 0h1m2 0h1m5 0h1M4 10.5h7m1 0h1m1 0h1m1 \n   0h1m1 0h1m1 0h1m1 0h7M12 11.5h2m1 0h1m2 0h3M4 12.5h1m1 0h5m4 0h5m2 0h5M4 13.5h1m2 0h2m2 \n   0h1m3 0h3m2 0h1m2 0h1m3 0h1M5 14.5h1m2 0h5m3 0h4m2 0h4m1 0h2M5 15.5h5m1 0h2m2 0h2m1 0h2m1 \n   0h1m6 0h1M4 16.5h4m2 0h1m2 0h12m1 0h3M4 17.5h1m2 0h1m1 0h1m1 0h2m4 0h1m2 0h2m1 0h1m1 0h1m1 \n   0h1M4 18.5h1m1 0h2m1 0h4m2 0h2m1 0h3m1 0h4m1 0h2M4 19.5h1m3 0h2m3 0h1m1 0h1m2 0h3m2 0h2m3 0h1M4 \n   20.5h1m1 0h1m3 0h4m1 0h1m3 0h6m1 0h1M12 21.5h3m1 0h2m1 0h2m3 0h2M4 22.5h7m2 0h1m3 0h2m1 0h1m1 0h1m1 \n   0h1m1 0h3M4 23.5h1m5 0h1m1 0h1m3 0h2m2 0h1m3 0h2m1 0h2M4 24.5h1m1 0h3m1 0h1m1 0h1m1 0h1m1 0h1m1 0h7m1 \n   0h3M4 25.5h1m1 0h3m1 0h1m1 0h1m6 0h4m1 0h5M4 26.5h1m1 0h3m1 0h1m1 0h1m1 0h3m2 0h1m5 0h2m1 0h1M4 27.5h1m5 \n   0h1m2 0h1m1 0h1m2 0h3m2 0h3m2 0h1M4 28.5h7m1 0h4m5 0h8"/></svg>',
      id: 'a8a1708b-4764-4a5b-a6b1-042ad233d2b4',
      position: {
        x: 520,
        y: 310,
      },
      size: {
        width: 242.85714285714312,
        height: 242.85714285714312,
      },
      zIndex: 2,
      isSelected: false,
      page: 1,
      lastUpdated: 1715137556949,
      localLastUpdated: 1715137556923,
      acknowledged: true,
      acknowledgementId: '1cc84e45-70ac-4ffc-890e-48f1334e1955',
    },
  ],
  images: [],
};
export { resumeTemplate, ticketTemplate };
