import { createBrowserRouter, RouterProvider, Navigate } from 'react-router-dom';
import PdfEditor from './components/Editor/PDFEditor';
import LoginPage from './pages/Auth/LoginPage/LoginPage';
import ProtectedRoute from './ProtectedRoute'; // Import the ProtectedRoute component
import RegistrationConfirmation from './pages/Auth/RegistrationPage/RegistrationConfirmation';
import ResetPasswordRequest from './pages/Auth/ResetPasswordRequestPage/ResetPasswordRequest';
import RegistrationPage from './pages/Auth/RegistrationPage/RegistrationPage';
import ResetPasswordPage from './pages/Auth/ResetPasswordPage/ResetPasswordPage';
import { DashboardPage } from './components/DashboardPage/DashboardPage';
import TemplatesPage from './components/TemplatesPage/TemplatesPage';
import CreateTemplatePage from './components/TemplatesPage/CreateTemplatePage';
import UserManagementPage from './pages/UserManagement/UserManagementPage';
import SetupAccountPage from './pages/Auth/SetupAccountPage/SetupAccountPage';
import ApiKeyManagement from './pages/ApiKeyManagement/ApiKeyManagement';

// Assuming ProtectedRoute and other components are adapted as necessary
const router = createBrowserRouter([
  { path: '/login', element: <LoginPage /> },
  {
    path: '/templates/:templateId/editor',
    element: <ProtectedRoute element={<PdfEditor />} />,
  },
  { path: '/templates', element: <ProtectedRoute element={<TemplatesPage />} /> },
  { path: '/templates/create', element: <ProtectedRoute element={<CreateTemplatePage />} /> },
  { path: '/dashboard', element: <ProtectedRoute element={<DashboardPage />} /> },
  { path: '/users', element: <ProtectedRoute element={<UserManagementPage />} /> },
  { path: '/api-keys', element: <ProtectedRoute element={<ApiKeyManagement />} /> },
  { path: '/register', element: <RegistrationPage /> },
  { path: '/registration-confirmation', element: <RegistrationConfirmation /> },
  { path: '/reset-password-request', element: <ResetPasswordRequest /> },
  { path: '/reset-password', element: <ResetPasswordPage /> },
  { path: '/setup-account', element: <SetupAccountPage /> },
  { path: '*', element: <Navigate to='/dashboard' replace /> },
]);

function Router() {
  return <RouterProvider router={router} />;
}

export default Router;
