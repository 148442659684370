interface SearchBarProps {
  value: string;
  placeholder: string;
  handleChange: (value: string) => void;
}

const InputTextArea = ({ value, handleChange, placeholder }: SearchBarProps) => {
  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={e => handleChange(e.target.value)}
      className='w-full border border-gray-400 rounded h-10 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 text-sm h-full'
      style={{ resize: 'none' }}
    />
  );
};

export default InputTextArea;
