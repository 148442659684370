import { useEffect, useState } from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import { Chip } from '@material-tailwind/react';
import {
  MdOutlineCheck,
  MdOutlineFileCopy,
  MdOutlineVisibility,
  MdOutlineVisibilityOff,
} from 'react-icons/md';
import usePDFStore from '../../stores/usePDFStore';
import { QRCodeData, TextBoxData } from '../../types/PDFObjects';
import { PDFObjectType } from '../../enums/PDFObjects';
import { useGetAllApiKeys } from '../../features/apiKeys/hooks/useGetAllApiKeys';
import { ApiKeyDTO } from '../../api/apiKey/types';
import { useUserContext } from '../../contexts/hooks/useUserContext';
import { useCreateApiKey } from '../../features/apiKeys/hooks/useCreateApiKey';

interface GenerateDocumentAPIProps {
  templateId: string;
}
const GenerateDocumentAPI = ({ templateId }: GenerateDocumentAPIProps) => {
  const pdfObjects = usePDFStore(state => state.pdfObjects);
  const { getAllApiKeys, isLoading, response } = useGetAllApiKeys();
  const { createApiKey, response: createApiKeyResponse } = useCreateApiKey();
  const { jwt } = useUserContext();
  const [apiKeys, setApiKeys] = useState<ApiKeyDTO[]>([]);
  const [activeTab, setActiveTab] = useState('REST');
  const [copied, setCopied] = useState(false);
  const [copiedKey, setCopiedKey] = useState(false);
  const [showAPIKey, setShowAPIKey] = useState(false);
  const [apiKey, setApiKey] = useState('generate-a-key-on-the-right');
  const hiddenKey = '************';

  const [jsonFields, setJsonFields] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    getAllApiKeys(jwt!);
  }, []);

  useEffect(() => {
    if (response) {
      setApiKeys(response);
      // Set the first active key as the default key
      const activeKey = response.find(key => key.isActive);
      if (activeKey) {
        setApiKey(activeKey.key);
      }
    }
  }, [response]);

  useEffect(() => {
    if (createApiKeyResponse) {
      //add the newly created key to the list of keys
      setApiKeys(prevKeys => [...prevKeys, createApiKeyResponse]);
      setApiKey(createApiKeyResponse.key);
    }
  }, [createApiKeyResponse]);
  useEffect(() => {
    const textBoxes = pdfObjects.filter(obj => obj.type === PDFObjectType.TEXTBOX) as TextBoxData[];

    const fields = textBoxes.filter(textBox => textBox.isDynamic).map(textBox => textBox.jsonKey);

    const qrCodes = pdfObjects.filter(
      obj => obj.type === PDFObjectType.QR_CODE && obj.isDynamic
    ) as QRCodeData[];

    const qrFields = qrCodes.map(qrCode => qrCode.jsonKey);

    const jsonFields = fields.reduce(
      (acc, field) => {
        acc[field] = 'string';
        return acc;
      },
      {} as { [key: string]: string }
    );

    qrFields.forEach(field => {
      jsonFields[field] = 'string';
    });

    setJsonFields(jsonFields);
  }, [pdfObjects]);

  const tabs = [
    { id: 'REST', title: 'REST API' },
    { id: 'NODE', title: 'Node' },
    { id: 'PHP', title: 'PHP' },
  ];

  const apiUrl = import.meta.env.VITE_API_URL + `/templates/${templateId}/generate`;

  // Utility function to handle copying text
  const handleCopyUrl = () => {
    //add the URL to the clipboard
    navigator.clipboard.writeText(apiUrl);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  const handleCopyKey = () => {
    //add the URL to the clipboard
    navigator.clipboard.writeText(apiKey);
    setCopiedKey(true);
    setTimeout(() => {
      setCopiedKey(false);
    }, 2000);
  };

  const nodeRequest = `const axios = require('axios');

const apiUrl = 
'${apiUrl}';
const apiKey = '${showAPIKey ? apiKey : hiddenKey}';
  
const headers = {
x-api-key: apiKey,
'Content-Type': 'application/json'
};

const data = ${JSON.stringify(jsonFields, null, 4)};

axios.post(apiUrl, data, { headers })
.then(response => {
    console.log('Response:', response.data);
})
.catch(error => {
    console.error('Error:', error.response.data);
});
  `;

  const phpRequest = `<?php
// PHP cURL
$ch = curl_init();

curl_setopt($ch, CURLOPT_URL, 
    '${apiUrl}'
);
curl_setopt($ch, CURLOPT_RETURNTRANSFER, 1);
curl_setopt($ch, CURLOPT_POST, 1);
curl_setopt($ch, CURLOPT_POSTFIELDS, json_encode(${JSON.stringify(jsonFields, null, 4)}));

$headers =
array(
    'x-api-key: ${showAPIKey ? apiKey : hiddenKey}',
    'Content-Type: application/json'
);

curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

$result = curl_exec($ch);

if (curl_errno($ch)) {
    echo 'Error:' . curl_error($ch);
}

curl_close($ch);
?>`;

  return (
    <div
      className='p-5'
      style={{
        minHeight: 'calc(100vh - 300px)',
        overflow: 'auto',
        maxHeight: 'calc(100vh - 300px)',
      }}
    >
      <div className='border-b border-gray-200'>
        <ul className='flex flex-wrap -mb-px'>
          {tabs.map(tab => (
            <li className='mr-2' key={tab.id}>
              <button
                className={`inline-block p-4 rounded-t-lg border-b-2 text-sm font-medium 
                            ${activeTab === tab.id ? 'text-blue-600 border-blue-600' : 'text-gray-800 border-transparent'}`}
                onClick={() => setActiveTab(tab.id)}
              >
                {tab.title}
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className='mt-4'>
        <div className='pb-2'>
          <div className='py-5 font-semibold'>
            Don&apos;t forget to save your most recent template changes
          </div>
          <div className='flex items-center space-x-5'>
            <Chip value='POST' color='green' />
            <div className='flex items-center space-x-2 bg-gray-200 p-2 rounded'>
              <p className='text-sm text-gray-900 font-bold'>{apiUrl}</p>
              {copied ? (
                <MdOutlineCheck size={20} className='text-green-500' />
              ) : (
                <MdOutlineFileCopy
                  size={20}
                  fontSize='small'
                  style={{ cursor: 'pointer' }}
                  onClick={handleCopyUrl}
                  className='hover:text-gray-900'
                />
              )}
            </div>
          </div>
        </div>
        <div className='p-2 font-bold text-black'>Headers:</div>
        <div className='p-2 mb-2'>
          <div className='flex items-center space-x-5'>
            <div className='font-bold'>x-api-key</div>
            <div className='flex items-center space-x-2 bg-gray-200 p-2 rounded'>
              <p className='text-sm text-gray-900 font-bold'>{showAPIKey ? apiKey : hiddenKey}</p>
              {showAPIKey ? (
                <MdOutlineVisibility
                  size={20}
                  fontSize='small'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowAPIKey(false)}
                  className='hover:text-gray-900'
                />
              ) : (
                <MdOutlineVisibilityOff
                  size={20}
                  fontSize='small'
                  style={{ cursor: 'pointer' }}
                  onClick={() => setShowAPIKey(true)}
                  className='hover:text-gray-900'
                />
              )}
              {copiedKey ? (
                <MdOutlineCheck size={20} className='text-green-500' />
              ) : (
                <MdOutlineFileCopy
                  size={20}
                  fontSize='small'
                  style={{ cursor: 'pointer' }}
                  onClick={handleCopyKey}
                  className='hover:text-gray-900'
                />
              )}
            </div>
            <div>
              {!isLoading && apiKeys.length > 0 && (
                <select
                  className='border border-gray-300 rounded-md p-2 font-normal w-40'
                  onChange={e => setApiKey(e.target.value)}
                >
                  {apiKeys.map((key, index) => (
                    <option key={index} value={key.key}>
                      {key.name}
                    </option>
                  ))}
                </select>
              )}
              {!isLoading && apiKeys.filter(key => key.isActive).length === 0 && (
                <div className='flex flex-row gap-2 items-center'>
                  <div className='text-gray-800 font-normal'>No API keys available</div>
                  <button
                    className='bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50'
                    onClick={() => createApiKey(jwt!)}
                  >
                    Generate API Key
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='p-2 mb-2'>
          <div className='flex items-center space-x-5'>
            <div className='font-bold'>Content-Type</div>
            <div className='flex items-center space-x-2 bg-gray-200 p-2 rounded'>
              <p className='text-sm text-gray-900 font-bold'>application/json</p>
            </div>
          </div>
        </div>
        {activeTab === 'REST' && (
          <>
            <div className='p-4 font-bold'>Payload</div>
            <CopyBlock
              text={JSON.stringify(jsonFields, null, 4)}
              language='json'
              showLineNumbers
              theme={dracula}
              customStyle={{
                overflow: 'auto',
              }}
            />
          </>
        )}
        {activeTab === 'NODE' && (
          <CopyBlock
            text={nodeRequest}
            language='javascript'
            showLineNumbers
            theme={dracula}
            customStyle={{
              overflow: 'auto',
            }}
          />
        )}
        {activeTab === 'PHP' && (
          <CopyBlock
            text={phpRequest}
            language='php'
            showLineNumbers
            theme={dracula}
            customStyle={{
              overflow: 'auto',
            }}
          />
        )}
      </div>
    </div>
  );
};

export default GenerateDocumentAPI;
