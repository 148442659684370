import { useEffect, useState } from 'react';
import { useGetAllApiKeys } from '../hooks/useGetAllApiKeys';
import { useUserContext } from '../../../contexts/hooks/useUserContext';
import { ApiKeyDTO } from '../../../api/apiKey/types';
import { MdOutlineContentCopy, MdOutlineVisibility, MdOutlineVisibilityOff } from 'react-icons/md';
import { HiOutlinePlus } from 'react-icons/hi2';
import { IconButton, Menu, MenuHandler, MenuItem, MenuList } from '@material-tailwind/react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/outline';
import { useCreateApiKey } from '../hooks/useCreateApiKey';
import TableLoading from '../../../components/ui/TableLoading';
import { useDeactivateApiKey } from '../hooks/useDeactivateApiKey';
import { useDeleteApiKey } from '../hooks/useDeleteApiKey';
import { useReactivateApiKey } from '../hooks/useActivateApiKey';

interface ApiKey extends ApiKeyDTO {
  showSecretKey: boolean;
}

const ApiKeyList = () => {
  const { getAllApiKeys, isLoading, response } = useGetAllApiKeys();
  const { createApiKey, response: createApiKeyResponse } = useCreateApiKey();
  const { deactivateApiKey, response: deactivateApiKeyResponse } = useDeactivateApiKey();
  const { deleteApiKey, response: deleteApiKeyResponse } = useDeleteApiKey();
  const { reactivateApiKey, response: reactivateApiKeyResponse } = useReactivateApiKey();
  const [deletingApiKey, setDeletingApiKey] = useState<string | null>(null);
  const { jwt } = useUserContext();
  const [apiKeys, setApiKeys] = useState<ApiKey[]>([]);
  const [copiedApiKey, setCopiedApiKey] = useState<string | null>(null);

  useEffect(() => {
    if (response) {
      setApiKeys(response.map(apiKey => ({ ...apiKey, showSecretKey: false })));
    }
  }, [response]);

  useEffect(() => {
    getAllApiKeys(jwt!);
  }, []);

  useEffect(() => {
    if (reactivateApiKeyResponse) {
      setApiKeys(prevApiKeys => {
        return prevApiKeys.map(apiKey => {
          if (apiKey.apiKeyId === reactivateApiKeyResponse.apiKeyId) {
            return {
              ...apiKey,
              isActive: reactivateApiKeyResponse.isActive,
            };
          }
          return apiKey;
        });
      });
    }
  }, [reactivateApiKeyResponse]);

  useEffect(() => {
    if (deleteApiKeyResponse && deletingApiKey) {
      setApiKeys(prevApiKeys => {
        return prevApiKeys.filter(apiKey => apiKey.apiKeyId !== deletingApiKey);
      });
      setDeletingApiKey(null);
    }
  }, [deleteApiKeyResponse, deletingApiKey]);

  useEffect(() => {
    if (deactivateApiKeyResponse) {
      setApiKeys(prevApiKeys => {
        return prevApiKeys.map(apiKey => {
          if (apiKey.apiKeyId === deactivateApiKeyResponse.apiKeyId) {
            return {
              ...apiKey,
              isActive: deactivateApiKeyResponse.isActive,
            };
          }
          return apiKey;
        });
      });
    }
  }, [deactivateApiKeyResponse]);

  useEffect(() => {
    if (createApiKeyResponse) {
      setApiKeys(prevApiKeys => [
        ...prevApiKeys,
        { ...createApiKeyResponse, showSecretKey: false },
      ]);
    }
  }, [createApiKeyResponse]);

  const handleToggleSecretKey = (apiKeyId: string) => {
    setApiKeys(prevApiKeys => {
      return prevApiKeys.map(apiKey => {
        if (apiKey.apiKeyId === apiKeyId) {
          return { ...apiKey, showSecretKey: !apiKey.showSecretKey };
        }
        return apiKey;
      });
    });
  };

  const handleCopySecretKey = (apiKeyId: string) => {
    const apiKey = apiKeys.find(apiKey => apiKey.apiKeyId === apiKeyId);
    if (apiKey) {
      navigator.clipboard.writeText(apiKey.key).then(() => {
        setCopiedApiKey(apiKeyId);
        setTimeout(() => setCopiedApiKey(null), 2000); // Message will disappear after 2 seconds
      });
    }
  };

  return (
    <div className='p-10'>
      <div className='flex justify-between items-center mb-10'>
        <h1 className='text-2xl font-semibold text-blue-gray-800'>API Keys</h1>
        <button
          aria-label='Add User'
          role='button'
          onClick={() => {
            createApiKey(jwt!);
          }}
          className='flex items-center justify-center bg-indigo-500 text-white px-4 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-opacity-50 gap-2'
        >
          <HiOutlinePlus size={25} />
          Add
        </button>
      </div>
      <table className='w-full min-w-max table-auto text-left bg-white'>
        <thead className='bg-white sticky top-0 z-10 shadow-sm'>
          <tr>
            <th className='p-4 text-blue-gray-700'>Name</th>
            <th className='p-4 text-blue-gray-700'>Secret Key</th>
            <th className='p-4 text-blue-gray-700'>Created</th>
            <th className='p-4 text-blue-gray-700'>Active</th>
            <th className='p-4 text-blue-gray-700'>Actions</th>
          </tr>
        </thead>
        <tbody className='pt-4 bg-gray-50 font-normal'>
          {isLoading && <TableLoading />}
          {!isLoading &&
            apiKeys.map((apiKey, index) => (
              <tr key={index}>
                <td className='p-4 border-b border-blue-gray-50'>{apiKey.name}</td>
                <td className='p-4 border-b border-blue-gray-50 flex flex-row gap-2 items-center'>
                  {apiKey.showSecretKey ? apiKey.key : 'spdf-*******' + apiKey.key.slice(-4)}
                  {apiKey.showSecretKey ? (
                    <MdOutlineVisibility
                      className='cursor-pointer'
                      onClick={() => handleToggleSecretKey(apiKey.apiKeyId)}
                    />
                  ) : (
                    <MdOutlineVisibilityOff
                      className='cursor-pointer'
                      onClick={() => handleToggleSecretKey(apiKey.apiKeyId)}
                    />
                  )}
                  <MdOutlineContentCopy
                    className='cursor-pointer'
                    onClick={() => handleCopySecretKey(apiKey.apiKeyId)}
                  />
                  {copiedApiKey === apiKey.apiKeyId && (
                    <span className='text-green-600'>Copied!</span>
                  )}
                </td>
                <td className='p-4 border-b border-blue-gray-50'>
                  {new Date(apiKey.createdAt).toLocaleDateString('en-US', {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric',
                  })}
                </td>
                <td className='p-4 border-b border-blue-gray-50'>
                  {apiKey.isActive ? 'Yes' : 'No'}
                </td>
                <td className='p-4 border-b border-blue-gray-50'>
                  <Menu>
                    <MenuHandler>
                      <IconButton variant='text'>
                        <EllipsisHorizontalIcon className='h-7 w-7' color='black' />
                      </IconButton>
                    </MenuHandler>
                    <MenuList>
                      {apiKey.isActive ? (
                        <MenuItem
                          onClick={() => {
                            deactivateApiKey(jwt!, apiKey.apiKeyId);
                          }}
                        >
                          Deactivate
                        </MenuItem>
                      ) : (
                        <>
                          <MenuItem
                            onClick={() => {
                              setDeletingApiKey(apiKey.apiKeyId);
                              deleteApiKey(jwt!, apiKey.apiKeyId);
                            }}
                          >
                            Delete
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              reactivateApiKey(jwt!, apiKey.apiKeyId);
                            }}
                          >
                            Activate
                          </MenuItem>
                        </>
                      )}
                    </MenuList>
                  </Menu>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default ApiKeyList;
