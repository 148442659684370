import useResizable from '../../../hooks/pdf/useResizable';
import ResizeHandle from './ResizeHandle';
import { TextBoxData } from '../../../types/PDFObjects';
import { useCallback, useEffect, useMemo, useRef, useState, CSSProperties, memo } from 'react';
import EditableTextDiv from './core/EditableTextDiv';
import useDraggable from '../../../hooks/pdf/useDraggable';
import usePDFStore from '../../../stores/usePDFStore';

const convertToText = (str = '') => {
  // Ensure string.
  let value = String(str);

  // Convert encoding.
  value = value.replace(/&nbsp;/gi, ' ');
  value = value.replace(/&amp;/gi, '&');
  //first replace any <div><br></div> with \n

  value = value.replace(/<div><br><\/div>/gi, '\n');

  // Replace `<br>`.
  value = value.replace(/<br>/gi, '\n');

  // Replace `<div>` (from Chrome).
  //add new line after div end tag for firefox but not for chrome
  // value = value.replace(/<\/div>/gi, '\n');
  const isFirefox = () => /firefox/i.test(navigator.userAgent);

  if (isFirefox()) {
    value = value.replace(/<\/div>/gi, '\n');
  } else {
    value = value.replace(/<div>/gi, '\n');
  }
  // Remove extra tags.
  value = value.replace(/<(.*?)>/g, '');

  // Expose string.
  return value;
};

interface TextBoxProps {
  textBoxData: TextBoxData;
}
const TextBox = memo(({ textBoxData }: TextBoxProps) => {
  const updatePDFObject = usePDFStore(state => state.updatePDFObject);
  const setCurrentSelections = usePDFStore(state => state.setCurrentSelections);
  const [position, setPosition] = useState({
    x: textBoxData.position.x,
    y: textBoxData.position.y,
  });
  const [isEditing, setIsEditing] = useState(false);

  const [size, setSize] = useState({
    width: textBoxData.size.width,
    height: textBoxData.size.height,
  });

  const objectRef = useRef<HTMLDivElement>(null);
  const handleDragEnd = useCallback(
    (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      updatePDFObject(id, {
        position,
        acknowledged: false,
        acknowledgementId: null,
        localLastUpdated: Date.now(),
      });
    },
    [updatePDFObject]
  );

  const handleResizeEnd = useCallback(
    (size: { width: number; height: number }) => {
      updatePDFObject(textBoxData.id, {
        size,
        acknowledged: false,
        acknowledgementId: null,
        localLastUpdated: Date.now(),
      });
    },
    [updatePDFObject, textBoxData.id]
  );

  const handleUpdatePosition = useCallback(
    (position: { x: number; y: number }) => {
      setPosition(position);
    },
    [setPosition]
  );

  useEffect(() => {
    handleUpdatePosition(textBoxData.position);
  }, [textBoxData.position, handleUpdatePosition]);

  useEffect(() => {
    setSize(textBoxData.size);
  }, [textBoxData.size]);

  const { dragging, startDrag } = useDraggable({
    x: textBoxData.position.x,
    y: textBoxData.position.y,
    id: textBoxData.id,
    updatePosition: (position: { x: number; y: number }) => {
      handleUpdatePosition(position);
    },
    onDragEnd: (
      id: string,
      position: {
        x: number;
        y: number;
      }
    ) => {
      handleDragEnd(id, position);
    },
  });

  const textBoxRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(
    (size: { width: number; height: number }) => {
      setSize(size);
    },
    [setSize]
  );

  const { startResize } = useResizable({
    width: size.width,
    height: size.height,
    updateSize: useCallback(
      size => {
        handleResize(size);
      },
      [handleResize]
    ),
    onResizeEnd: handleResizeEnd,
  });

  const value = useRef<string>(textBoxData.content);

  const handleContentChange = useCallback(
    (e: React.FormEvent<HTMLDivElement>) => {
      const updatedContent = e.currentTarget.innerHTML;

      const finalContent = convertToText(updatedContent);

      //lets

      updatePDFObject<TextBoxData>(textBoxData.id, {
        content: finalContent,
        acknowledged: false,
        acknowledgementId: null,
        localLastUpdated: Date.now(),
      });
    },
    [textBoxData.id, updatePDFObject]
  );

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setCurrentSelections([textBoxData.id]);
      startDrag(e);
    },
    [setCurrentSelections, textBoxData.id, startDrag]
  );

  const containerStyle: CSSProperties = useMemo(
    () => ({
      transform: `translate(${position.x}px, ${position.y}px)`,
      width: `${size.width}px`,
      maxWidth: `${size.width}px`,
      height: `${size.height}px`,
      maxHeight: `${size.height}px`,
      cursor: isEditing ? 'default' : dragging ? 'grabbing' : 'grab',
      position: 'absolute',
      display: 'inline-block',
      overflow: 'visible',
      outline: isEditing
        ? '2px solid #007AFF' // A modern green, consider a solid border for editing
        : textBoxData.isSelected
          ? '2px solid #007AFF' // A modern blue, softer than stark blue
          : '', // No border if neither editing nor selected
      boxShadow: isEditing
        ? '0px 0px 8px rgba(0, 122, 255, 0.5)' // Soft blue glow when selected
        : '', // No shadow if neither
      transition: 'outline 0.1s ease, box-shadow 0.2s ease', // Smooth transition for visual changes
      zIndex: `${textBoxData.zIndex}`,
      pointerEvents: 'auto',
    }),
    [
      position.x,
      position.y,
      size.width,
      size.height,
      textBoxData.isSelected,
      textBoxData.zIndex,
      dragging,
      isEditing,
    ]
  );

  const textBoxStyle: CSSProperties = useMemo(
    () => ({
      height: `${size.height}px`,
      overflow: 'visible',
      padding: '0px',
      width: '100%',
      boxSizing: 'border-box', // Ensure padding does not affect the size
      position: 'relative', // Ensure child absolute positioning is relative to this div
      fontSize: `${textBoxData.fontSize}px`,
      color: textBoxData.fontColor,
      fontFamily: textBoxData.fontFamily,
      fontWeight: textBoxData.fontWeight,
      textAlign: textBoxData.align,
      fontStyle: textBoxData.fontStyle,
      textDecoration: textBoxData.underline ? 'underline' : 'none',
      lineHeight: `${Math.round(textBoxData.fontSize * textBoxData.lineSpacing)}px`,
    }),
    [
      size.height,
      textBoxData.fontSize,
      textBoxData.fontColor,
      textBoxData.fontFamily,
      textBoxData.fontWeight,
      textBoxData.align,
      textBoxData.fontStyle,
      textBoxData.underline,
      textBoxData.lineSpacing,
    ]
  );

  //memoize the class name
  const selectedClassNames = useMemo(
    () => (textBoxData.isSelected ? '' : 'pdfComponent'),
    [textBoxData.isSelected]
  );

  return (
    <>
      <div className='pdf-component-overlay' style={{ overflow: 'visible' }}>
        <div onMouseDown={handleMouseDown} className={selectedClassNames} style={containerStyle}>
          {textBoxData.isSelected && (
            <>
              <ResizeHandle position='bottom-right' onStartResize={startResize} />
              <ResizeHandle position='bottom-left' onStartResize={startResize} />
              <ResizeHandle position='top-right' onStartResize={startResize} />
              <ResizeHandle position='top-left' onStartResize={startResize} />
            </>
          )}
        </div>
      </div>
      <div
        className='pdf-component-overlay'
        style={{
          overflow: 'clip',
        }}
      >
        <div
          ref={objectRef}
          onMouseDown={handleMouseDown}
          className={selectedClassNames}
          style={containerStyle}
          id={textBoxData.id}
        >
          <div ref={textBoxRef} style={textBoxStyle}>
            <EditableTextDiv
              isEditing={isEditing}
              setIsEditing={setIsEditing}
              value={value.current}
              onContentChange={handleContentChange}
              fontSize={textBoxData.fontSize}
              lineSpacing={textBoxData.lineSpacing}
              fontFamily={textBoxData.fontFamily}
              content={textBoxData.content}
            />
          </div>
          {textBoxData.isSelected && (
            <>
              <ResizeHandle position='bottom-right' onStartResize={startResize} />
              <ResizeHandle position='bottom-left' onStartResize={startResize} />
              <ResizeHandle position='top-right' onStartResize={startResize} />
              <ResizeHandle position='top-left' onStartResize={startResize} />
            </>
          )}
        </div>
      </div>
    </>
  );
});

TextBox.displayName = 'TextBox';

export default TextBox;
